<template>
  <div ref="topbox">
    <!-- sosTypeList -->
    <!-- 报警 -->
    <audio id="alarmA" :src="require('@/assets/alarm.mp3')" loop="true" />
    <div class="sosCall">
      <template v-for="(item, index) in sosTypeList">
        <div class="sosCallBox" :key="index">
          <div v-if="item.cmd == 'push_sos'" class="sosBox">
            <h4 style="margin: 0;text-align: center; font-size: 18px;">{{ item.sosName }}</h4>
            <div class="sosMsgBottom">
              <div class="sosMsg">电梯名称：{{ item.name }} </div>
              <div class="sosMsg">报警时间：{{ item.time }}</div>
              <div class="sosMsg">电梯位置：{{ item.address }} </div>
              <div style="margin-top: 15px; text-align: center">
                <template v-if="item.c_id">
                  <el-button type="info" size="small" @click="closeSOS(item)">关闭({{ item.closetime }})
                  </el-button>
                </template>
                <template v-else>
                  <template v-if="
											item.type === 95 ||
											item.type === 0 ||
											item.type === 10 ||
											item.type === 7 ||
											item.type === 3 ||
											item.type === 4 ||
											item.type === 5 ||
											item.type === 11 ||
											item.type === 12 ||
											item.type === 80
										">
                    <el-button type="primary" size="small" @click="answerSOS(item)">接听({{ item.closetime }})
                    </el-button>
                    <el-button type="info" size="small" @click="closeSOS(item)">拒接</el-button>
                  </template>
                  <template v-else>
                    <el-button type="info" size="small" @click="closeSOS(item)">关闭</el-button>
                  </template>
                </template>
              </div>
            </div>
          </div>
          <div v-else class="sosCapsBox">
            <!-- 安全帽 -->
            <h4 style="margin: 0;text-align: center;">
              <el-image style="width: 30px; height: 30px" :src="image" fit="cover" />
              {{ item.sosName }}
            </h4>
            <div style="margin: 10px 0 15px; min-width: 280px;">
              <div class="sosMsg">报警设备：安全帽</div>
              <div class="sosMsg">人员名称：{{ item.userName }}</div>
              <div class="sosMsg">报警时间：{{ item.sosTime }}</div>
              <div class="sosMsg">报警位置：{{ item.address }}</div>
              <div style="margin-top: 15px; text-align: center">
                <template v-if="item.sosType == 1 || item.sosType == 4 || item.sosType == 8">
                  <el-button type="primary" size="small" @click="answerSOSCaps(item)">接听({{ item.closetime }})
                  </el-button>
                  <el-button type="info" size="small" @click="closeSOS(item)">拒接</el-button>
                </template>
                <template v-else>
                  <el-button type="info" size="small" @click="closeSOS(item)">关闭</el-button>
                </template>
              </div>
            </div>
          </div>
        </div>
      </template>
      <el-dialog v-if="visible" :visible.sync="visible" :close-on-click-modal="false" :show-close="showClose"
        width="950px" top="8vh" :modal="false" @closed="bclose">
        <div style="padding: 0 20px" class="scrollElement">
          <EditionC1 v-if="!isOldJk" :jkData="jkData" :sosId="sosId" :alarmTime="alarmTime" @bclose="bclose" />
          <EditionC2 v-else :jkData="jkData" :sosId="sosId" :alarmTime="alarmTime" @bclose="bclose" :id="device_id"
            :percentage="percentage" @changePercentage="changePercentage" />
        </div>
        <div slot="footer" class="dialog-footer"></div>
      </el-dialog>
      <el-dialog v-if="visibleCaps" :visible.sync="visibleCaps" :close-on-click-modal="false" :show-close="showClose"
        width="950px" top="8vh" :modal="false" @bcloseCaps="bcloseCaps">
        <div style="padding: 0 20px" class="scrollElement">
          <CapszlvScheme v-if="capsData" :jkData="capsData" @bcloseCaps="bcloseCaps" />
        </div>
        <div slot="footer" class="dialog-footer"></div>
      </el-dialog>
    </div>

    <!-- <el-dialog
			v-if="isPwdDio"
			:visible.sync="isPwdDio"
			width="500px"
			top="20vh"
			:close-on-press-escape="!tipPwdShow"
			:close-on-click-modal="!tipPwdShow"
			:show-close="!tipPwdShow"> -->
    <el-dialog v-if="isPwdDio" :visible.sync="isPwdDio" width="500px" top="20vh">
      <p v-if="tipPwdShow" class="tipPwd">检测到您的密码过于简单，请重新设置新密码</p>
      <div style="height: 240px;padding-right: 80px;">
        <el-form label-position="right" label-width="120px" ref="contracts" :model="contractForm" :rules="formRules">
          <el-row style="margin: 10px 0">
            <el-col :span="24">
              <el-form-item label="旧密码:" prop="password">
                <el-input placeholder="请输入" v-model="contractForm.password" class="wid90">
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row style="margin: 10px 0">
            <el-col :span="24">
              <el-form-item label="新密码:" prop="newPassword">
                <el-input placeholder="请输入" v-model="contractForm.newPassword" class="wid90">
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row style="margin: 10px 0">
            <el-col :span="24">
              <el-form-item label="确认密码:" prop="obNewPassword">
                <el-input placeholder="请输入" v-model="contractForm.obNewPassword" class="wid90">
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" type="primary" @click="setPwd">确 定</el-button>
        <el-button v-if="!tipPwdShow" size="small" @click="isPwdDio = false">取 消</el-button>
      </div>
    </el-dialog>

    <el-container>
      <!-- 侧边栏 -->
      <el-aside width="auto">
        <div @click="isCartes.i = false" style="width: 100%; height: 100%">
          <!-- 导航菜单 -->
          <el-menu :default-active="this.$route.matched[1].meta.lab" class="el-menu-vertical-demo"
            :collapse="isCollapse" text-color="#bfcbd9" unique-opened>
            <router-link v-if="bigScreenType == 1" to="">
              <el-menu-item @click="openNewPage('/newBigmianban')" index="0" style="text-align: left; width: 100%">
                <i class="el-icon-s-home"></i>
                <span slot="title">数据监控</span>
              </el-menu-item>
            </router-link>
            <router-link v-if="bigScreenType == 3" to="">
              <el-menu-item @click="openNewPage('/maintenanceBigmianban')" index="0"
                style="text-align: left; width: 100%">
                <i class="el-icon-s-home"></i>
                <span slot="title">数据监控</span>
              </el-menu-item>
            </router-link>
            <!-- @click="pushList('home', '/operate', '0')" -->
            <router-link v-if="bigScreenType == 2" to="">
              <el-menu-item @click="openNewPage('/operate')" index="0" style="text-align: left; width: 100%">
                <i class="el-icon-s-home"></i>
                <span slot="title">数据监控</span>
              </el-menu-item>
            </router-link>
            <!-- <router-link to="">
							<el-menu-item
								@click="toAD"
								index="-1"
								style="text-align: left; width: 100%"
							>
								<i class="el-icon-s-marketing"></i>
								<span slot="title">广告平台</span>
							</el-menu-item>
						</router-link> -->
            <el-submenu :index="item.id" style="text-align: left; width: 100%" v-for="(item, index) in elList">
              <template slot="title">
                <div style="width: 100%;height: 100%;" @click="openPage(item)">
                  <i class="el-icon-s-marketing"></i>
                  <span>{{ item.name }}</span>
                </div>
              </template>
              <router-link v-for="(items, indexs) in elList[index].children" :to="items.routePath">
                <el-menu-item @click="pushList(items.name, items.routePath, items.id)"
                  style="text-align: left; width: 100%" :index="items.id">
                  <span>{{ items.name }}</span>
                </el-menu-item>
              </router-link>
            </el-submenu>
          </el-menu>
        </div>
      </el-aside>
      <el-container>
        <!-- 头部 -->
        <el-header>
          <div class="headerRec" @click="isCartes.i = false">
            <div class="headerRecTop">
              <div class="hTop01">
                <span style="color: white; margin-left: 20px">
                  <span v-if="platformName&&!$store.state.isZx && windowHost">
                    <span>{{platformName}}</span>
                  </span>
                  <span v-else>
                    <!-- <span v-if="$store.state.isZx">中信数字化电梯物联网监管系统</span> -->
                    <span>锦地梯控物联网监管系统</span>
                  </span>
                </span>
                <!-- <div>
									<i @click="setimg" class="el-icon-s-fold" style="font-size: 20px;"></i>
								</div> -->
                <!-- 面包屑 -->
                <!-- <div>
									<el-breadcrumb separator-class="el-icon-arrow-right"
										style="margin-left: 25px;height: 14px;overflow: hidden;">
										<el-breadcrumb-item :to="{ path: '/dashboard' }">
											<font @click="pushList('home','/dashboard','1')">{{$t('homes.home')}}</font>
										</el-breadcrumb-item>
										<el-breadcrumb-item v-for="(item,index) in this.$route.matched"
											v-if='item.name!="home" && item.name!=undefined'>
											{{$t('homes.'+item.name)}}
										</el-breadcrumb-item>
									</el-breadcrumb>
								</div> -->
              </div>
              <div class="hTop02">
                <!-- 全屏 -->
                <!-- <el-tooltip class="item" effect="dark" :content='isfull?"取消全屏":"全屏"' placement="bottom">
									<img @click="full()" style="width: 30.4px;height: 30.4px;margin-right: 10px;"
										src="../../assets/full.jpg" />
								</el-tooltip> -->

                <!-- 颜色选择器 -->
                <!-- <el-tooltip class="item" effect="dark" content="换肤" placement="bottom">
									<ThemeCom :color="color" @color-update="colorChange" />
								</el-tooltip> -->

                <!-- 中英文切换 -->
                <!-- <el-row class="block-col-2">
									<el-col :span="12">
										<el-dropdown trigger="click">
											<span class="el-dropdown-link">

												<el-tooltip class="item" effect="dark" content="中英文切换"
													placement="bottom">
													<img style="width: 30.4px;height: 30.4px;margin-left: 10px;"
														src="../../assets/ec.jpg" />
												</el-tooltip>

											</span>
											<el-dropdown-menu slot="dropdown">
												<div style="width: 100%;height: 100%;" @click="chin(0)">
													<el-dropdown-item>
														中文
													</el-dropdown-item>
												</div>
												<div style="width: 100%;height: 100%;" @click="chin(1)">
													<el-dropdown-item>
														English
													</el-dropdown-item>
												</div>
											</el-dropdown-menu>
										</el-dropdown>
									</el-col>
								</el-row> -->

                <el-button @click="gotoHome" type="text">返回首页</el-button>
                <!-- 头像 -->
                <el-dropdown style="margin-right: 50px; margin-left: 20px">
                  <span class="el-ds">
                    <el-avatar size="medium" src="https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png">
                    </el-avatar>
                    <span>{{ adminInfo.name }}</span>
                    <i class="el-icon-caret-bottom"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <div style="width: 100%; height: 100%" @click="updPwd()">
                      <el-dropdown-item> 修改密码 </el-dropdown-item>
                    </div>
                    <div style="width: 100%; height: 100%" @click="quit()">
                      <el-dropdown-item> 退出登录 </el-dropdown-item>
                    </div>
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
            </div>
            <div class="headerRecBottom">
              <!-- 自定义标签页 -->
              <tabControl :isCarte="isCartes" :routeDate="rdata" @goTag="goTag"></tabControl>
            </div>
          </div>
        </el-header>

        <el-main class="rtgg2">
          <div @click="isCartes.i = false" id="das" ref="pageBox">
            <router-view @click="isCartes.i = false" />

            <el-backtop target=".el-main" :visibility-height="20" :bottom="100" :right="40" style="z-index: 99">
              <div class="el-icon-s-home eecon"></div>
            </el-backtop>
          </div>
        </el-main>
      </el-container>
    </el-container>
    <!-- {{route.path}} -->

    <div v-show="showParticle" id="particles-js" class="particles"></div>
  </div>
</template>

<script>
  import screenfull from 'screenfull' //引入控制全屏的插件
  import tabControl from '../../components/tabControl.vue'
  import EditionC1 from '../../components/editionC1'
  import EditionC2 from '../../components/editionC2'
  import CapszlvScheme from '../../components/cap/zlvSchemeCaps.vue'
  import {
    formatDate
  } from "@/util";
  // import ThemeCom from '../../components/ThemeCom'



  let sosTime
  export default {
    components: {
      tabControl,
      // ThemeCom,
      EditionC1,
      EditionC2,
      CapszlvScheme
    },
    watch: {
      $route: function(to, from) {
        console.log(to, from, 'watchval');
      }
    },
    data() {
      var validateNewPwd = (rule, value, callback) => {
        let operation =
          /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[~!@#$%&^*()_——\-+=<>;？?:""{}|「」,.\\/'\[\]·！￥（）《》？：“”【】、；‘’，。]).{8,16}$/
        inpYz(rule, value, callback, true, operation, '密码长度为：8-16位，且必须包含大小写字母，数字与特殊符号')
      }
      var validateObPwd = (rule, value, callback) => {
        console.log(this.contractForm, 'this.contractForm');
        if (!value) {
          callback(new Error('确认密码不能为空'))
        } else {
          if (value !== this.contractForm.newPassword) {
            callback(new Error('再次输入的密码与新密码不同'))
          }
        }
        callback()
      }
      return {
        isOldJk: '',
        //
        adminInfo: null,
        isCartes: {
          i: false,
        },
        isCollapse: false,
        color: '#00F6FF ',
        isfull: false,
        elList: [],
        tpList: [],
        boxTpList: [],
        tpCapsList: [],

        rdata: {
          i: 1,
          tags: [],
        },
        sosTypeList: [],
        jkData: null, // 球机推拉流
        capsData: null, // 安全帽信息
        visible: false, // 平台监控弹框
        visibleCaps: false, // 安全帽监控弹框
        sosId: '',
        alarmTime: '',
        showClose: false,
        bigScreenType: '',
        // 修改密码
        isPwdDio: false,
        formRules: {
          password: [{
            required: true,
            message: '请输入旧密码',
            trigger: 'blur'
          }],
          newPassword: [{
            required: true,
            validator: validateNewPwd,
            trigger: 'blur',
          }],
          obNewPassword: [{
            required: true,
            validator: validateObPwd,
            trigger: 'blur'
          }]
        },
        contractForm: {},
        image: require('@/assets/sos.png'),

        percentage: 0,
        device_id: '',

        platformName: JSON.parse(sessionStorage.getItem('adminInfo')).platformName,
        logoUrl: JSON.parse(sessionStorage.getItem('adminInfo')).logoUrl,
        windowHost: window.location.hostname != 'zxtlw.zyzntech.com',
        showParticle: this.$route.path == '/dashboard', // 粒子效果
        tipPwdShow: false
      }
    },
    methods: {
      //跳转广告平台
      toAD() {
        let adminInfo = JSON.parse(sessionStorage.getItem('adminInfo'))
        // console.log(window.location.hostname == ('zxtlw.zyzntech.com' || 'tzb.runde.pro'))
        let httpUrl = window.location.hostname == ('zxtlw.zyzntech.com' || 'tzb.runde.pro') ?
          "https://ads.runde.pro/R3Login" : "http://ads-test.runde.pro:30002/R3Login"
        let username = adminInfo.name
        let time = Math.floor(new Date().getTime() / 1000 + 10000000)
        httpUrl = `${httpUrl}?username=${username}&time=${time}`
        window.open(httpUrl, "_blank");
      },
      setPwd() {
        this.$refs.contracts.validate(async (valid) => {
          if (valid) {
            if (this.contractForm.newPassword == this.contractForm.obNewPassword) {
              let data = {
                id: this.adminInfo.id,
                password: this.contractForm.password,
                newPassword: this.contractForm.newPassword
              }
              this.axios.post('/api/system/web/user/modifyPassword', data).then((res) => {
                if (res.data.success) {
                  this.isPwdDio = false
                  this.$message.success(res.data.msg)
                  this.loginOut()
                } else {
                  this.$message.error(res.data.msg)
                }
              })
            } else {
              this.$message.error('两次密码输入不一致，请确认新密码！')
            }
          } else {
            this.$message.error('请将表单填写完整！')
          }
        })
      },
      updPwd() {
        this.isPwdDio = true
        this.contractForm = {}
      },
      gotoHome() {
        this.pushList('首页', '/dashboard', 1)
        this.$router.push('/dashboard')
      },

      openPage(data) {
        if (data.name == '数据统计' && this.$route.path != "/sjtjPage") {
          this.pushList('数据统计', '/sjtjPage', 99)
          this.$router.push('/sjtjPage')
        }
      },


      //当选择颜色主题时触发
      colorChange(color) {
        this.color = color
      },
      isCartesN() {
        this.isCartes = false
      },

      goTag(data) {
        console.log(this.$route, 'data');
        console.log(data.name == '首页');
        this.showParticle = data.name == '首页'
      },
      pushList(lab, rou, ind) {
        this.showParticle = rou == '/dashboard'
        // // 首页
        // if (lab == '首页') {
        // 	this.showParticle = true
        // 	console.log('openPage首页');
        // }
        let isPush = true
        for (let i = 0; i < this.rdata.tags.length; i++) {
          console.log('iii', this.rdata.tags[i].i);
          if (this.rdata.tags[i].i == ind) {
            isPush = false
          }
        }
        console.log('isPush', isPush);
        this.rdata.i = ind
        if (isPush) {
          let listArr = {
            name: lab,
            i: ind,
            rot: rou,
          }
          this.rdata.tags.push(listArr)

        }
      },
      openNewPage(path) {
        window.open(`${window.location.origin}/#${path}`, "_blank");
      },
      setimg() {
        // 旋转加动画效果
        this.isCollapse = !this.isCollapse
        event.srcElement.style.transition = '0.5s'
        if (this.isCollapse) {
          event.srcElement.style.transform = 'rotate(180deg)'
        } else {
          event.srcElement.style.transform = 'rotate(0deg)'
        }
      },
      full() {
        screenfull.toggle()
        if (screenfull.toggle()) {
          this.isfull = !this.isfull
        } else {
          this.$message({
            message: '浏览器不能全屏',
            type: 'warning',
          })
        }
      },

      quit() {
        this.$confirm('是否退出?', '提示', {
            confirmButtonText: '退出',
            cancelButtonText: '取消',
            type: 'warning',
          })
          .then(() => {
            this.loginOut()
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消退出',

            })
          })
      },

      loginOut() {
        this.axios.post('/api/system/web/user/loginOut').then((res) => {
          if (res.data.success) {
            clearInterval(sosTime)
            // this.socketApi.onlineDevice = []
            this.socketApi.stopHeartBeat()
            this.$message({
              type: 'success',
              message: '退出成功!',
            })
            // document.cookie = "AdminToken=; expires=Thu, 01 Jan 1970 00:00:00 GMT";
            sessionStorage.clear()
            this.$router.push('/login')
          } else {
            this.$message({
              type: 'error',
              message: res.data.msg,
            })
          }
        })
      },
      getType(type) {
        let obj = '未知报警'
        this.tpList.forEach((qs) => {
          if (qs.value == type) {
            obj = qs.label
            return obj
          }
        })
        return obj
      },
      getBoxType(type) {
        let obj = '未知报警'
        this.boxTpList.forEach((qs) => {
          if (qs.value == type) {
            obj = qs.label
            return obj
          }
        })
        return obj
      },
      getCapsType(type) {
        let obj = '未知报警'
        this.tpCapsList.forEach((qs) => {
          if (qs.value == type) {
            obj = qs.label
            return obj
          }
        })
        return obj
      },

      changePercentage(data) {
        console.log(data, 'data');
        this.percentage = data
      },
      getStreamStatus(data) {
        console.log(data, 'jkData');
        this.device_id = data.device_id
        this.$http
          .get(`api/ele/web/getStreamStatus?id=${this.device_id}`)
          .then((res) => {
            if (res.data.success) {
              this.percentage = res.data.data ? 75 : 50
            } else {
              this.$message.error(res.data.msg)
            }
          })
      },

      // 接听
      answerSOS(data) {
        this.sosTypeList = this.sosTypeList.filter(
          (item) => item.sosId !== data.sosId
        )
        document.getElementById('alarmA').pause()
        console.log(data, 'data1111111111');
        this.sosId = data.sosId
        this.alarmTime = data.alarmTime
        this.visible = true
        setTimeout(() => {
          this.showClose = true
        }, 2000)
        const agentData = {
          act: 'ca_start_rtsp',
          device_id: data.code,
        }
        this.socketApi.sendSock(agentData, (e) => {
          if (e.status) {
            if (e.hb) {
              this.percentage = 25
            }
            if (e.cmd === 'ca_start_rtsp') {
              console.log(e, 'e11111111111111');
              if (data.appVer.indexOf('C2') > -1 || data.appVer.indexOf('C3') > -1) {
                this.percentage = 50
                this.getStreamStatus(JSON.parse(e.data))
                this.isOldJk = true
              } else {
                this.isOldJk = false
              }
              const data1 = JSON.parse(e.data)
              this.jkData = data1
            }
          } else {
            this.$message.error(e.msg)
          }
        })
      },
      bclose() {
        this.visible = false
      },


      // 安全帽接听
      answerSOSCaps(data) {
        this.sosTypeList = this.sosTypeList.filter(
          (item) => item.sosId !== data.sosId
        )
        document.getElementById('alarmA').pause()
        this.sosId = data.sosId
        this.alarmTime = data.time
        this.visibleCaps = true
        setTimeout(() => {
          this.showClose = true
        }, 2000)
        const agentData = {
          act: 'ma_start_rtsp',
          code: data.code
        }
        this.socketCapsApi.sendCapsSock(agentData, (e) => {
          if (e.status) {
            if (e.cmd == 'ma_start_rtsp' && e.data) {
              let data1 = JSON.parse(e.data)
              this.capsData = data1
            }
          } else {
            this.$message.error(e.msg)
          }
        })
      },
      bcloseCaps() {
        this.visibleCaps = false
      },
      // 拒接
      closeSOS(data) {
        this.sosTypeList = this.sosTypeList.filter(
          (item) => item.sosId !== data.sosId
        )
        if (this.sosTypeList.length === 0) {
          document.getElementById('alarmA').pause()
        }
      },
      getSosConfigObj(data) {
        const sosConfig = JSON.parse(sessionStorage.getItem("sosConfig"))
        let arr = sosConfig.filter(item => Number(item.sosType) == Number(data.type))
        return arr[0]
      }
    },
    mounted() {
      this.$nextTick(() => {
        openLz()
        let ht = this.$refs.topbox.offsetHeight
        if (ht > 1000) {
          this.$store.state.tabHeight = this.$refs.topbox.offsetHeight * 0.7
        } else if (ht > 900) {
          this.$store.state.tabHeight = this.$refs.topbox.offsetHeight * 0.65
        } else if (ht > 700) {
          this.$store.state.tabHeight = this.$refs.topbox.offsetHeight * 0.45
        } else {
          this.$store.state.tabHeight = 30
        }

        if (this.$store.state.isZx) {
          document.title = this.$store.state.isZx ? '锦地梯控物联网监管系统' : this.platformName
          var link = document.querySelector("link[rel*='icon']") || document.createElement("link");
          link.type = "image/png";
          link.rel = "shortcut icon";
          link.href = require('@/assets/zhongxin.png')
          document.getElementsByTagName("head")[0].appendChild(link);
        }
        if (!JSON.parse(sessionStorage.getItem('adminInfo')).weakPassword) {
          this.tipPwdShow = true
          this.updPwd()
        }
      })
    },
    created() {
      const screenData = JSON.parse(sessionStorage.getItem('adminInfo'))
      this.bigScreenType = screenData.bigScreenType

      this.$http
        .post('/api/system/web/dict/dictionary?codes=BUTTON_TYPE')
        .then((res) => {
          if (res.data.success) {
            sessionStorage.setItem(
              'BUTTON_TYPE',
              JSON.stringify(res.data.data.BUTTON_TYPE)
            )
          }
        })

      this.$http
        .post("/api/system/web/dict/dictionary?codes=SOS_TYPE,CAP_SOS_TYPE,HIGH_SOS_PARAM,LOW_SOS_PARAM")
        .then((res) => {
          if (res.data.success) {
            this.tpList = res.data.data.SOS_TYPE
            this.boxTpList = [...res.data.data.HIGH_SOS_PARAM, ...res.data.data.LOW_SOS_PARAM]
            this.tpCapsList = res.data.data.CAP_SOS_TYPE
            sosTime = setInterval(() => {
              // this.sosTypeList
              this.sosTypeList.forEach((qs, ind) => {
                qs.closetime -= 1
                if (qs.closetime <= 0) {
                  this.sosTypeList.splice(ind, 1)
                }
              })

              this.socketApi.sosPrompt.forEach((qs) => {
                if (this.sosTypeList.length > 9) {
                  this.sosTypeList.splice(0, 1)
                }
                qs.closetime = 30
                qs.time = formatDate(qs.time, "yyyy-MM-dd HH:mm:ss")
                qs.alarmTime = qs.time
                qs.typeShow = true
                console.log(qs, 'qs');
                if (qs.type === -1) {
                  qs.sosName = `${qs.terminalSosName}报警`
                } else {
                  if (qs.sosDeviceSign && qs.sosDeviceSign == 1) {
                    qs.sosName = this.getBoxType(qs.type)
                  } else {
                    qs.sosName = this.getType(qs.type)
                  }
                }
                this.sosTypeList.push(qs)
              })
              this.socketCapsApi.sosCapsPrompt.forEach((qs) => {
                if (this.sosTypeList.length > 9) {
                  this.sosTypeList.splice(0, 1)
                }
                this.sosTypeList.splice(0, 1)
                qs.closetime = 30
                qs.typeShow = true
                qs.sosName = this.getCapsType(qs.sosType)

                this.sosTypeList.push(qs)
              })
              this.sosTypeList = this.sosTypeList.filter(item => {
                if (item.cmd.indexOf('cap') > -1) {
                  return item
                } else {
                  let data = this.getSosConfigObj(item)
                  return data && data.push == 1
                }
              })
              let voiceS = this.sosTypeList.filter(item => {
                let data = this.getSosConfigObj(item)
                return data && data.voice == 1
              })
              if (
                this.sosTypeList.length > 0 &&
                !this.visible &&
                !this.$global.openJK &&
                voiceS.length > 0
              ) {
                setTimeout(() => {
                  document.getElementById('alarmA').loop = true
                  document.getElementById('alarmA').play()
                }, 100)
              } else {
                document.getElementById('alarmA').pause()
              }

              this.socketApi.clearSos()
              this.socketCapsApi.clearCapsSos()
            }, 1000)
          }
        })

      this.adminInfo = JSON.parse(sessionStorage.getItem('adminInfo'))
      this.elList = this.adminInfo.resource
      let obj = {
        name: this.$route.matched[1].name,
        i: this.$route.matched[1].meta.lab,
        rot: this.$route.matched[1].path,
      }
      this.rdata.tags.push(obj)
      this.rdata.i = obj.i
    },
    destroyed() {
      clearInterval(sosTime)
    },
  }
</script>

<style>
  .eecon {
    height: 100%;
    width: 100%;
    background-color: #f2f5f6;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.12);
    text-align: center;
    line-height: 40px;
    color: #1989fa;
    font-size: 30px;
  }

  /* 侧边栏样式 */
  .el-menu {
    border: 0 !important;
    background-color: #034771 !important;
  }

  .el-submenu__title:focus,
  .el-submenu__title:hover {
    background-color: rgb(40, 52, 70) !important;
  }

  .el-menu-item {
    color: #bfcbd9 !important;
    background-color: #034771 !important;
  }

  .el-menu-item:hover {
    background-color: rgb(40, 52, 70) !important;
  }

  .is-active {
    color: #00f6ff !important;
  }

  .headerRec {
    width: 100%;
    height: 100px;
    background-color: #2a3d62;
  }
</style>

<style lang="scss" scoped>
  .tipPwd {
    margin: 0 auto 30px;
    text-align: center;
    color: #F00;
    font-size: 16px;
  }

  .particles {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }

  .sosCallBox {
    width: 320px;
    height: 300px;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 10px 10px 0;
    background-color: #f0ffff;
  }

  .sosCallBox span {
    font-size: 18px;
    font-weight: bold;
  }

  .sosBox {
    width: 100%;

    .sosMsgBottom {
      margin: 10px 0px 15px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      min-height: 200px;

      .sosMsg {
        font-size: 16px;
        margin-top: 15px;
        display: flex;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        word-wrap: break-word;
      }
    }
  }

  .sosCapsBox {
    .sosMsgBottom {
      margin: 10px 0px 15px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      min-height: 200px;

      .sosMsg {
        font-size: 15px;
        margin-top: 10px;
        display: flex;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        word-wrap: break-word;
      }
    }
  }

  .sosCall {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999999;
    /* width: 100%; */
    /* overflow: auto; */
    display: flex;
    flex-wrap: wrap;
  }

  .el-ds {
    /* min-width: 150px; */
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .el-ds span {
    margin: 0 5px;
    color: navajowhite;
  }

  .rtgg2 {
    height: calc(100vh - 100px);
    /* overflow-y: scroll; */
    /* overflow-x: hidden; */
    /* overflow: hidden; */
  }

  .el-main {
    padding: 20px 20px 15px;
  }

  /* 全屏样式 */
  a {
    text-decoration: none;
  }

  /* 容器 */
  .hTop01 {
    width: 50%;
    height: 100%;
    float: left;
    display: flex;
    align-items: center;
  }

  .hTop02 {
    width: 50%;
    height: 100%;
    float: right;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    overflow: hidden;
  }

  .headerRecTop {
    width: 100%;
    height: 50px;
    background-color: #2a3d62;
  }

  .headerRecBottom {
    width: 100%;
    height: 50px;
  }

  /* 颜色选择器样式 */
  .el-color-dropdown__link-btn {
    display: none !important;
    color: red !important;
  }

  /* 导航菜单样式 */
  .el-menu-vertical-demo:not(.el-menu--collapse) {
    width: 200px;
    height: 200px;
    background-color: #324157;
    z-index: 1000;
    opacity: 0.9;
  }

  /* 布局容器样式 */
  .el-header {
    opacity: 0.9;
    height: 100px !important;
    padding: 0 0 !important;
  }

  .el-aside {
    background-color: #034771;
    color: #333;
    height: 100vh;
    opacity: 0.9;
  }
</style>

<style scoped>
  ::-webkit-scrollbar {
    width: 0 !important;
  }

  ::-webkit-scrollbar {
    width: 0 !important;
    height: 0;
  }

  .item>>>.el-input__inner {
    width: 160px !important;
    height: 28px !important;
    float: left;
    border: 1px solid black !important;
  }
</style>