<template>
	<div>
		<div v-show="percentage != 100"
			style="height: 450px; display: flex; justify-content: center; align-items: center;background: #000;">
			<div style="position: relative;">
				<span class="span-width">{{percentage}}%</span>
				<img :src="require(`@/assets/img/${percentage}.png`)"  style="width: 75px; height: 75px;">
			</div>
		</div>
		<div v-show="percentage == 100" style="width: 88%;height: 100%; margin: 0 auto">
			<video style="width: 100%;height: 450px;" controls autoplay :muted='isMuted' id="onPlayJkSos" />
			<template v-for="item in meData">
				<video v-show="false" style="width: 100px;height: 100px;" controls autoplay :id="item.session_id"
					:key="item.session_id" />
			</template>
		</div>
		<div class="conBox">
			<!-- <el-button :disabled='isDial' @click="dialZlv" title="拨打" type="success" icon="el-icon-microphone">拨打</el-button> -->
			<el-button size="mini" @click="dropped" type="danger">
				<i class="el-icon-turn-off-microphone"></i>
				<p style="margin: 10px 0 0 0;">挂断</p>
			</el-button>

			<el-button :disabled='sosStatus' size="mini" type="primary" @click="sosClick(1)" :loading="btnLoading">
				<span>人为触发(无故障)</span>
				<p style="margin: 10px 0 0 0;">确认关闭</p>
			</el-button>
			<el-button :disabled='sosStatus' size="mini" type="info" @click="sosClick(2)" :loading="btnLoading">
				<span>电梯故障(无困人)</span>
				<p style="margin: 10px 0 0 0;">派发维修工单</p>
			</el-button>
			<el-button :disabled='sosStatus' size="mini" type="warning" @click="sosClick(3)" :loading="btnLoading">
				<span>困人</span>
				<p style="margin: 10px 0 0 0;">立即派发救援工单</p>
			</el-button>
			<el-button
				size="mini"
				class="errorSos"
				@click="sosClick(5)"
				:loading="btnLoading"
				:disabled="sosStatus">
				<span>误报</span>
				<p style="margin: 10px 0 0 0;">设备产生错误报警</p>
			</el-button>
			<!-- <el-button  @click="dialZlv" title="关闭" type="info" icon="el-icon-microphone">关闭</el-button> -->
		</div>
	</div>
</template>

<script>
	let one = true
	export default {
		props: ['jkData', 'sosId', 'alarmTime', 'percentage'],
		data() {
			return {
				// 监控
				player: null,
				loading: true,
				timeWebRtc: null,
				// 通话
				isDial: false,
				isMuted: true,
				meData: [],
				meObj: null,
				sosStatus: false,

				playTime: 0, // 通话时间
				playWebrtc: '',
				btnLoading: false,
				meplayer: null,
			}
		},
		mounted() {
			setTimeout(() => {
				this.init()
				this.dialZlv()
			}, 1000)
		},
		beforeDestroy() {
			let agentData = {
				act: 'ma_stop_webrtc_call',
				sos_room_id: this.jkData.device_id,
			}
			this.socketApi.sendSock(agentData, (e) => {})
			one = true
			if (this.meplayer) {
				this.meplayer.close()
				this.meplayer = null
				var local = document.getElementById(this.meObj)
				if (local) {
					local.srcObject = null
					local.load()
				}
			}
			this.stop()
		},

		methods: {
			// 详情操作按钮
			sosClick(type) {
				if (type != 4) {
					this.btnLoading = true
					this.sosStatus = true
				}
				this.$http
					.post('/api/ele/app/sos/submit', {
						id: this.sosId,
						submitType: type,
						sosTime: this.alarmTime
					})
					.then((res) => {
						if (type != 4) {
							if (res.data.success) {
								this.$message.success(res.data.msg)
							} else {
								this.$message.error(res.data.msg)
							}
						}
						this.btnLoading = false
					})
			},
			// 拨打
			meInit(data) {
				if (this.meplayer) {
					this.meplayer.close()
					this.meplayer = null
				}
				var local = document.getElementById(data.session_id)
				if (local) {
					local.load()
					local.srcObject = null
				}
				const jswebrtc = document.getElementById(data.session_id)
				const that = this
				that.meplayer = new ZLMRTCClient.Endpoint({
					element: jswebrtc, // video 标签
					debug: true, // 是否打印日志
					zlmsdpUrl: data.url, //流地址
					simulcast: false,
					useCamera: false,
					audioEnable: true,
					videoEnable: false,
					recvOnly: data.recvType, //拉流为true 推流为false
					resolution: {
						w: 640,
						h: 480,
					},
				})

				if (one) {
					one = false
					that.sosClick(4)
				}
				that.meplayer.on(
					ZLMRTCClient.Events.WEBRTC_ICE_CANDIDATE_ERROR,
					function(e) {
						// ICE 协商出错
						console.log('ICE 协商出错')
					}
				)
				that.meplayer.on(
					ZLMRTCClient.Events.WEBRTC_ON_REMOTE_STREAMS,
					function(e) {
						//获取到了远端流，可以播放
						console.log('播放成功', e.streams)
						that.loading = false
					}
				)
				that.meplayer.on(
					ZLMRTCClient.Events.WEBRTC_OFFER_ANWSER_EXCHANGE_FAILED,
					function(e) {
						// offer anwser 交换失败
						console.log('offer anwser 交换失败', e)
					}
				)
				that.meplayer.on(
					ZLMRTCClient.Events.WEBRTC_ON_LOCAL_STREAM,
					function(s) {
						// 获取到了本地流
						jswebrtc.srcObject = s
						jswebrtc.muted = true
						console.log('获取本地流成功')
						that.playWebrtc = setInterval(() => {
							that.playTime = that.playTime + 1
							if (that.playTime > 600) {
								that.dropped()
							}
						}, 1000)
					}
				)
				that.meplayer.on(ZLMRTCClient.Events.CAPTURE_STREAM_FAILED, function(s) {
					// 获取本地流失败
					console.log('获取本地流失败')
				})

			},
			dialZlv() {
				let agentData = {
					type: 2,
					act: 'ma_webrtc_call',
					sos_room_id: this.jkData.device_id,
					token: sessionStorage.getItem('AdminToken'),
				}
				if (sessionStorage.getItem('me_id')) {
					let se_id = JSON.parse(sessionStorage.getItem('me_id')).sessionId
					this.socketApi.sendSock(agentData, (e) => {
						// one 只执行一次
						if (e.cmd == 'ma_webrtc_call' && e.room_info != undefined && one) {
							this.isMuted = false
							e.room_info.members.forEach((qs, ind) => {
								if (ind != 0) {
									//recvType 拉流为true 推流为false
									let obj = {
										url: qs.play,
										recvType: true,
										session_id: qs.session_id,
									}
									if (qs.session_id == se_id) {
										obj.url = qs.push
										obj.recvType = false
										this.meData.push(obj)
										this.meObj = qs.session_id
									}
									// this.meData.push(obj)
								}
							})
							this.isDial = true
							setTimeout(() => {
								this.meData.forEach((qs) => {
									this.meInit(qs)
								})
							}, 1000)
						} else {
							clearInterval(this.timeWebRtc)
						}
					})
				
				} else {
					this.$message.error('您现在的网络情况不好，请刷新页面再尝试')
					return
				}
			},
			// 挂断
			dropped() {
				clearInterval(this.playWebrtc)
				this.playTime = 0
				this.isMuted = true
				this.isDial = false

				let agentData = {
					act: 'ma_stop_webrtc_call',
					sos_room_id: this.jkData.device_id,
				}
				this.socketApi.sendSock(agentData, (e) => {})

				this.$emit('bclose')
				one = true
				if (this.meplayer) {
					this.meplayer.close()
					this.meplayer = null
					var local = document.getElementById(this.meObj)
					if (local) {
						local.srcObject = null
						local.load()
					}
				}
			},
			// 阻梯
			ladder() {},
			// 重连
			opJk() {
				this.stop()
				let agentData = {
					act: 'ca_start_rtsp',
					device_id: this.jkData.device_id,
				}
				this.socketApi.sendSock(agentData, (e) => {
					if (e.status) {
						if (e.cmd == 'ca_start_rtsp') {
							if (e.hb) {
								this.$emit('changePercentage', 25)
							}
							if (e.cmd == 'ca_start_rtsp') {
								this.$emit('changePercentage', 50)
								this.getStreamStatus()
							}
							// this.init()
						}
					} else {
						this.$message.error(e.msg)
					}
				})
			},

			getStreamStatus() {
				this.$http
					.get(`api/ele/web/getStreamStatus?id=${this.jkData.device_id}`)
					.then((res) => {
						if (res.data.success) {
							this.$emit('changePercentage', res.data.data ? 75 : 50)
							this.init()
						} else {
							this.$message.error(res.data.msg)
						}
					})
			},
			//关闭监控
			stop() {
				clearInterval(this.timeWebRtc)
				if (this.player) {
					this.player.close()
					this.player = null
					var local = document.getElementById('onPlayJkSos')
					if (local) {
						local.srcObject = null
						local.load()
					}
				}
			},
			init() {
				const that = this
				const jswebrtc = document.getElementById('onPlayJkSos')
				const openUrl = this.jkData.play_url
				const playUrl = openUrl[openUrl.length - 2]

				this.player = new ZLMRTCClient.Endpoint({
					element: jswebrtc, // video 标签
					debug: true, // 是否打印日志
					zlmsdpUrl: playUrl, //流地址
					simulcast: false,
					useCamera: false,
					audioEnable: false,
					videoEnable: false,
					recvOnly: true, //拉流为true 推流为false
					resolution: {
						w: 640,
						h: 480,
					},
				})
				this.player.on(
					ZLMRTCClient.Events.WEBRTC_ICE_CANDIDATE_ERROR,
					function(e) {
						// ICE 协商出错
						console.log('ICE 协商出错')
					}
				)
				this.player.on(
					ZLMRTCClient.Events.WEBRTC_ON_REMOTE_STREAMS,
					function(e) {
						//获取到了远端流，可以播放
						console.log('播放成功', e.streams)
						clearInterval(that.timeWebRtc)
						that.$emit('changePercentage', 100)
						that.loading = false
					}
				)
				this.player.on(
					ZLMRTCClient.Events.WEBRTC_OFFER_ANWSER_EXCHANGE_FAILED,
					function(e) {
						// offer anwser 交换失败
						console.log('offer anwser 交换失败', e)
					}
				)
				this.player.on(ZLMRTCClient.Events.WEBRTC_ON_LOCAL_STREAM, function(s) {
					// 获取到了本地流
					jswebrtc.srcObject = s
					jswebrtc.muted = false
					console.log('获取本地流成功')
				})
				this.player.on(ZLMRTCClient.Events.CAPTURE_STREAM_FAILED, function(s) {
					// 获取本地流失败
					console.log('获取本地流失败')
				})
			},
		},
	}
</script>

<style scoped>
	.errorSos{
		background: #00dcff;
		border-color: #00dcff;
		color: #fff
	}
	.conBox {
		display: flex;
		align-items: center;
		justify-content: center;
		/* flex-direction: column; */
		/* width: 6%; */
		height: 100%;
		margin-top: 15px;
	}
	.span-width{
		position: absolute;
		top: calc(50% - 16px);
		left: calc(50% - 50px);
		font-size: 26px;
		font-weight: 600;
		color:#052e54;
		width: 100px;
		line-height: 32px;
		text-align: center;
	}
	
	/deep/.el-progress-bar__innerText{
		color: #fff !important;
	}
</style>