<template>
	<div>
		<div style="width: 76%;height: 100%;margin:auto" v-loading="loading">
			<video style="width: 100%;height: 450px;" controls autoplay :muted='isMuted' id="onPlayJkSos" />
			<video v-show="false" style="width: 100px;height: 100px;" controls autoplay id="mePlayJkSos" />
			<template v-for="item in meData">
				<video v-show="false" style="width: 100px;height: 100px;" controls autoplay :id="item.session_id"
					:key="item.session_id" />
			</template>
		</div>
		<div class="conBox">
			<!-- <el-button @click="dialZlv" title="拨打" type="success" icon="el-icon-microphone">拨打</el-button> -->
			<el-button size="mini" @click="dropped" type="danger">
				<i class="el-icon-turn-off-microphone"></i>
				<p style="margin: 10px 0 0 0;">挂断</p>
			</el-button>

			<el-button :disabled='sosStatus' size="mini" type="primary" @click="sosClick(1)" :loading="btnLoading">
				<span>人为触发(无故障)</span>
				<p style="margin: 10px 0 0 0;">确认关闭</p>
			</el-button>
			<el-button :disabled='sosStatus' size="mini" type="info" @click="sosClick(2)" :loading="btnLoading">
				<span>电梯故障(无困人)</span>
				<p style="margin: 10px 0 0 0;">派发维修工单</p>
			</el-button>
			<el-button :disabled='sosStatus' size="mini" type="warning" @click="sosClick(3)" :loading="btnLoading">
				<span>困人</span>
				<p style="margin: 10px 0 0 0;">立即派发救援工单</p>
			</el-button>
			<el-button
				size="mini"
				class="errorSos"
				@click="sosClick(5)"
				:loading="btnLoading"
				:disabled="sosStatus">
				<span>误报</span>
				<p style="margin: 10px 0 0 0;">设备产生错误报警</p>
			</el-button>
			<!-- <el-button  @click="dialZlv" title="关闭" type="info" icon="el-icon-microphone">关闭</el-button> -->
		</div>
	</div>
</template>

<script>
	let obj = []
	let one = true
	var userAgent = null
	export default {
		props: ['jkData', 'sosId', 'alarmTime'],
		data() {
			return {
				// 监控
				player: null,
				loading: true,
				timeWebRtc: null,
				// 通话
				isMuted: true,
				meData: [],
				meObj: null,
				sosStatus: false,

				playTime: 0, // 通话时间
				playWebrtc: '',
				btnLoading: false,
				
				type: 1,
			}
		},
		mounted() {
			obj = JSON.parse(sessionStorage.getItem('me_id')).sip_info
			setTimeout(() => {
				this.init()
				this.dialZlv()
			}, 1000)
		},
		beforeDestroy() {
			if (this.player) {
				this.player.destroy()
				this.player = null
				console.log('卸载jk')
			}
		},

		methods: {
			// 详情操作按钮
			sosClick(type) {
				if (type != 4) {
					this.btnLoading = true
					this.sosStatus = true
				}
				this.$http
					.post('/api/ele/app/sos/submit', {
						id: this.sosId,
						submitType: type,
						sosTime: this.alarmTime
					})
					.then((res) => {
						if (type != 4) {
							if (res.data.success) {
								this.$message.success(res.data.msg)
							} else {
								this.$message.error(res.data.msg)
							}
						}
						this.btnLoading = false
					})
			},
			dialZlv() {
				let agentData = {
					v_type: 0,
					act: 'ma_set_sip_info',
					deviceId: this.jkData.device_id
				}
				this.socketApi.sendSock(agentData, (e) => {
					console.log('123123123123213', obj)
					// 下列参数写死
					var host = obj.sip_host; // 域名
					var user = obj.sip_id; // 注册人id
					var pwd = obj.sip_pwd; // 密码
					var wss = obj.wss_url; // 长连接地址
					var iceHost = obj.stun_host; // stun地址
					var turnUser = obj.turn_user; // turn用户名
					var turnPwd = obj.turn_pwd; // turn密码

					var config = {
						uri: user + '@' + host,
						transportOptions: {
							wsServers: [wss]
						},
						authorizationUser: user,
						password: pwd,
						sessionDescriptionHandlerFactoryOptions: {
							peerConnectionOptions: {
								rtcConfiguration: {
									iceServers: [{
											urls: 'stun:' + iceHost
										},
										{
											urls: 'turn:' + iceHost,
											username: turnUser,
											credential: turnPwd
										}
									]
								}
							}
						}
					};
					userAgent = new SIP.UA(config);
					userAgent.on('registered', function() {
						console.log('registered')
					});
					userAgent.on('invite', function(session) {
						var url = session.remoteIdentity.uri.toString() + "--->call";
						//接受来电
						session.accept({
							sessionDescriptionHandlerOptions: {
								constraints: {
									audio: true,
									video: {
										width: 1280,
										height: 720
									}
								}
							}
						});
						this.sipsession = session;
					});

					setTimeout(() => {
						var hostc = obj.sip_host;
						var toc = this.jkData.sip_id;
						console.log('我等了两秒', this.jkData)
						this.sipsession = userAgent.invite(toc + '@' + hostc, {
							sessionDescriptionHandlerOptions: {
								constraints: {
									audio: true,
									video: {
										faceMode: 'user', //facemode是一个选择摄像头的约束，在移动设备上效果很好，有user（用户，前置摄像头）、environment（环境，后置摄像头）、left、right四种选项
										width: 160,
										height: 120,
										frameRate: 16,
									}
								}
							}
						});
						let _this = this
						this.sipsession.on('accepted', function() {
							console.log('接通了')
							// 按钮禁点2.5秒防止对象未操作就挂断
							var pc = _this.sipsession.sessionDescriptionHandler.peerConnection;
							var remoteStream = new MediaStream();
							pc.getReceivers().forEach(function(receiver) {
								remoteStream.addTrack(receiver.track);
							});
							var mePlayJk = document.getElementById('mePlayJkSos');
							mePlayJk.srcObject = remoteStream;
							mePlayJk.play();
							// document.getElementById('onPlayJkSos').muted = false
						});
					}, 2000)
				})
			},
			dropped() {
				let agentData = {
					act: 'ma_stop_webrtc_call',
					sos_room_id: this.jkData.device_id,
				}
				this.socketApi.sendSock(agentData, (e) => {})
				this.$emit('bclose')
				if (this.sipsession) this.sipsession.terminate();
			},
			// 阻梯
			ladder() {
					console.log('阻梯');
					let agentData = {
						act: 'ma_change_ele_status',
						deviceId: this.jkData.device_id,
						type: this.type
					}
					console.log('agentData', agentData)
					this.socketApi.sendSock(agentData, (e) => {
						if (e.cmd == 'ma_change_ele_status' && e.status) {
							this.$message.success(e.msg)
							this.type = this.type == 1 ? 2 : 1
						} else {
							this.$message.error(e.msg)
						}
					})
				
			},
			// 重连
			opJk() {
				this.stop()
				let agentData = {
					act: 'ca_start_rtsp',
					device_id: this.jkData.device_id,
				}
				this.socketApi.sendSock(agentData, (e) => {
					if (e.status) {
						if (e.cmd == 'ca_start_rtsp') {
							this.init()
						}
					} else {
						this.$message.error(e.msg)
					}
				})
			},
			//关闭监控
			stop() {
				clearInterval(this.timeWebRtc)
				if (this.player) {
					this.player.close()
					this.player = null
					var local = document.getElementById('onPlayJkSos')
					if (local) {
						local.srcObject = null
						local.load()
					}
				}
			},
			// 监控播放400, 查看监控播放状态
			onFail() {
				setTimeout(() => {
					if (this.player) {
						this.player.destroy()
						this.player = null
					}
					let agentData = {
						act: 'ca_start_rtsp',
						device_id: this.jkData.device_id
					}
					this.socketApi.sendSock(agentData, (e) => {
						if (e.status) {
							if (e.cmd == 'ca_start_rtsp') {
								this.init()
							}
						} else {
							this.$message.error('流文件信息或长连接错误！')
						}
					})
				}, 4 * 1000)
			},
			// 监控开始播放
			startPlay() {
				this.loading = false
			},
			init() {
				console.log('this.jkData', this.jkData)
				const jswebrtc = document.getElementById('onPlayJkSos')
				const openUrl = this.jkData.play_url

				const playUrl = openUrl[openUrl.length - 1]
				const apiUrl = 'https://tlw-test.runde.pro/rtc/v1/play/'

				let curIP = window.location.host
				if (curIP.indexOf('localhost') == -1 && curIP.indexOf('192.168.') == -1) {
					apiUrl = 'https://' + curIP + '/rtc/v1/play/'
				}
				console.log(23, playUrl, apiUrl)
				this.player = new JSWebrtc.Player(apiUrl, playUrl, {
					video: jswebrtc,
					autoplay: true,
					onPlay: this.startPlay,
					onFail: this.onFail
				})
			},
		},
	}
</script>

<style scoped>
	.errorSos{
		background: #00dcff;
		border-color: #00dcff;
		color: #fff
	}
	.conBox {
		display: flex;
		align-items: center;
		justify-content: center;
		/* flex-direction: column; */
		/* width: 6%; */
		height: 100%;
		margin-top: 15px;
	}
</style>
