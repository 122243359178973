<template>
  <div class="main">
    <el-row>
      <el-col :span="18">
        <div class="left">
          <div class="leftTop">
            <el-row class="leftTopRow">
              <el-col :span="8" class="dataBoxRow">
                <div class="dataBox" @click="openTable('DayCount')">
                  <div class="dataBoxLeft">
                    <el-image
                      style="width: 90px; height: 90px"
                      :src="require('@/assets/calendar/1.png')"
                      :fit="'cover'"
                    />
                  </div>
                  <div class="dataBoxRight">
                    <h2>{{ topData.DayCount }}</h2>
                    <p>今日需维保电梯(台)</p>
                  </div>
                </div>
              </el-col>
              <el-col :span="8" class="dataBoxRow">
                <div class="dataBox" @click="openTable('DayFinishedCount')">
                  <div class="dataBoxLeft">
                    <el-image
                      style="width: 90px; height: 90px"
                      :src="require('@/assets/calendar/1.png')"
                      :fit="'cover'"
                    />
                  </div>
                  <div class="dataBoxRight">
                    <h2>{{ topData.DayFinishedCount }}</h2>
                    <p>今日已维保电梯(台)</p>
                  </div>
                </div>
              </el-col>
              <el-col :span="8" class="dataBoxRow">
                <div class="dataBox" @click="openTable('DayUnfinishedCount')">
                  <div class="dataBoxLeft">
                    <el-image
                      style="width: 90px; height: 90px"
                      :src="require('@/assets/calendar/1.png')"
                      :fit="'cover'"
                    />
                  </div>
                  <div class="dataBoxRight">
                    <h2>{{ topData.DayUnfinishedCount }}</h2>
                    <p>今日未维保电梯(台)</p>
                  </div>
                </div>
              </el-col>
            </el-row>
            <el-row class="leftTopRow">
              <el-col :span="8" class="dataBoxRow">
                <div class="dataBox" @click="openTable('overhaulCount')">
                  <div class="dataBoxLeft">
                    <el-image
                      style="width: 90px; height: 90px"
                      :src="require('@/assets/calendar/1.png')"
                      :fit="'cover'"
                    />
                  </div>
                  <div class="dataBoxRight">
                    <h2>{{ topData.overhaulCount }}</h2>
                    <p>即将大修/改造电梯(台)</p>
                  </div>
                </div>
              </el-col>
              <el-col :span="8" class="dataBoxRow" >
                <div class="dataBox" @click="openTable('failureNum')">
                  <div class="dataBoxLeft">
                    <el-image
                      style="width: 90px; height: 90px"
                      :src="require('@/assets/calendar/1.png')"
                      :fit="'cover'"
                    />
                  </div>
                  <div class="dataBoxRight">
                    <h2>{{topData.failureNum}}</h2>
                    <p>维修中电梯(条)</p>
                  </div>
                </div>
              </el-col>
              <el-col :span="8" class="dataBoxRow" >
                <div class="dataBox" @click="openTable('contractCount')">
                  <div class="dataBoxLeft">
                    <el-image
                      style="width: 90px; height: 90px"
                      :src="require('@/assets/calendar/1.png')"
                      :fit="'cover'"
                    />
                  </div>
                  <div class="dataBoxRight">
                    <h2>{{topData.contractCount}}</h2>
                    <p>保险即将过期电梯(台)</p>
                  </div>
                </div>
              </el-col>
            </el-row>
          </div>
          <div class="leftCenter">
            <el-row>
              <el-col :span="12" class="charsLeft">
                <h4>电梯实时状态</h4>
                <StatusChart
                  v-if="eleStatusType"
                  :eleStatus="eleStatus"
                  :height="'240px'"
                />
              </el-col>
              <el-col :span="12">
                <div class="charsRight">
                  <template v-if="Object.keys('notice').lenght !== 0">
                    <h3>{{ notice.title }}</h3>
                    <h5>{{ notice.updateTime }}</h5>
                    <div class="noticeBox">
                      <div class="noticeDetail scrollElement">
                        <quill-editor
                          @focus="focus($event)"
                          @change="onChange($event)"
                          v-model="notice.detail"
                          :options="editorOption"
                          class="quillClass"
                        />
                      </div>
                    </div>
                  </template>

                  <template v-else>
                    <h3>暂无公告</h3>
                  </template>
                </div>
              </el-col>
            </el-row>
          </div>
          <div class="leftBottom dataBox">
            <h4 style="margin-bottom: 0; margin-left: 15px">电梯报警趋势</h4>
            <SosChart
              v-if="sosLineDataType"
              :sosLineData="sosLineData"
              :height="'170px'"
            />
          </div>
        </div>
      </el-col>
      <el-col :span="6">
        <div class="right">
          <!-- <h4>电梯报警</h4> -->
          <div
            style="height: 84vh; overflow-y: scroll; padding-right: 8px"
            class="scrollElement"
          >
            <div v-if="sosList.length > 0">
              <template v-for="item in sosList">
                <div
                  v-if="sosList.length > 0"
                  :key="item.id"
                  class="sosBox scrollElement"
                  @click="openDialog(item)"
                >
                  <div class="sosBoxTop">
                    <div class="sosBoxTopLeft">{{ item.sosTypeName }}</div>
                    <div class="sosBoxTopRight" :title="item.eleName">{{ item.eleName }}</div>
                  </div>
                  <div class="sosBoxCenter">
                    <div>{{ item.sosTime }}</div>
                    <div>{{ item.sosStatusName }}</div>
                  </div>
                  <div class="sosBoxBottom">{{ item.eleAddress }}</div>
                </div>
              </template>
            </div>
            <div
              v-else
              class="sosBox scrollElement"
              style="height: 80vh; text-align: center"
            >
              <h3>暂无报警记录</h3>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
    <EleTableDialog v-if="eleTabledisabled" ref="eleTableDialog" @closeEleDialog="closeEleDialog"/>
    <SosDialog v-if="disabled" ref="sosDialog" @closeSosDialog="closeSosDialog"/>
  </div>
</template>

<script>
import SosChart from "./components/sosChart";
import StatusChart from "./components/statusChart";


import SosDialog from './components/sosDialog';
import EleTableDialog from './components/eleDialog';
import { formatDate } from "@/util";

let current_time = formatDate(new Date(), "yyyy-MM-dd") + " 00:00:00";
let current_time1 = new Date(current_time).getTime();
let sosStarTime = new Date(current_time1 - 2 * 24 * 60 * 60 * 1000).getTime();
let sosEndTime = new Date(current_time1 + 24 * 60 * 60 * 1000 - 1000).getTime();
export default {
  components: {
    SosChart,
    StatusChart,
    SosDialog,
    EleTableDialog
  },
  data() {
    return {
      show: true,
      showBtn: window.location.host.indexOf("zxtlw.zyzntech.com") === -1,
      num: 0,
      order: 0,
      nums: "",
      orders: "",
      searchForm: {
        current: 1,
        size: 10,
        start: sosStarTime,
        end: sosEndTime,
        selectType: 2
      },
      notice: {},
      topData: {
        DayCount: 0, // 需维保
        DayFinishedCount: 0, // 未维保
        DayUnfinishedCount: 0, // 已维保
        inspectionCount: 0, // 已超期
      },
      sosList: [],
      typeDate: [], // 字典表
      stateList: [
        {
          value: 1,
          label: "待处理",
        },
        {
          value: 2,
          label: "已处理",
        },
        {
          value: 3,
          label: "进行中",
        },
        {
          value: 4,
          label: "已完成",
        },
      ],
      dayTime: {
        startTime: new Date(
          new Date(new Date().toLocaleDateString()).getTime() -
            7 * 24 * 3600 * 1000
        ).getTime(),
        endTime: new Date(
          new Date(new Date().toLocaleDateString()).getTime() - 1000
        ).getTime(),
      },
      eleStatus: {
        xAxis: ["在线", "维保中", "故障", "困人", "离线"], // x轴
        seriesData: [0, 0, 0, 0, 0], // 数据
      },
      eleStatusType: false,
      sosLineData: {
        activeAlarm: [], // 主动报警数量
        voiceAlarm: [], // 语音报警数据
        peopleAlarm: [], // 困人报警数据
        date: [], // 日期
        dateArr: [], // 排序
      },
      sosLineDataType: false,

      // 富文本编辑器
      editorOption: {
        modules: {
          toolbar: [
            ["bold"], // toggled buttons

            [{ size: ["small", false, "large", "huge"] }], // custom dropdown
          ],
        },
      },
      
      disabled: false,
      eleTabledisabled: false
    };
  },
  created() {
    this.$http
      .post("/api/system/web/dict/dictionary?codes=SOS_TYPE")
      .then((res) => {
        if (res.data.success) {
          this.typeDate = res.data.data;
          this.getTopData();
          this.getNotice();
          this.getSosList();
        }
      });
    this.getSosWeek();
  },
  mounted() {
  },
  methods: {
    
    //获取焦点事件
    focus(event) {
      event.enable(false) //设置富文本编辑器不可编辑
    },
    onChange(event) {
      event.quill.enable(false) //设置富文本编辑器不可编辑
    },
    getSosWeek() {
      this.$http
        .post("/api/ele/web/elevatorInfo/sosCountWeek", this.dayTime)
        .then((res) => {
          if (res.data.success) {
            const {
              data: { data },
            } = res;
            this.sosLineDataType = true;

            for (let value of Object.keys(data)) {
              this.sosLineData.dateArr.push(value);
            }
            this.sosLineData.dateArr = this.sosLineData.dateArr.sort((a, b) => {
              return a.split("-")[0] - b.split("-")[0];
            });
            this.sosLineData.dateArr = this.sosLineData.dateArr.sort((a, b) => {
              if (a.split("-")[0] == b.split("-")[0]) {
                return a.split("-")[1] - b.split("-")[1];
              } else {
                return a.split("-")[0] - b.split("-")[0];
              }
            });

            let arr = [];
            this.sosLineData.dateArr.map((item) => {
              arr.push(data[item]);
              const dateTime = item.split("-");
              this.sosLineData.date.push(`${dateTime[0]}-${dateTime[1]}`);
            });

            for (let value in arr) {
              // 主动报警数量
              this.sosLineData.activeAlarm.push(
                arr[value].sos0 ? arr[value].sos0 : 0
              );
              // 语音报警数据
              this.sosLineData.voiceAlarm.push(
                arr[value].sos3 ? arr[value].sos3 : 0
              );
              // 困人报警数据
              this.sosLineData.peopleAlarm.push(
                arr[value].sos5 ? arr[value].sos5 : 0
              );
            }
          } else {
            this.$message.error(res.data.msg);
          }
        });
    },
    getTopData() {
      this.$http.get("/api/ele/web/maintainTask/loginTopData").then((res) => {
        if (res.data.success) {
          const {
            data: { data },
          } = res;

          this.eleStatusType = true;
          if (data.topData) {
            this.topData = data.topData;
            for (const key in this.topData) {
              this.topData[key] = data.topData[key] ?? 0;
            }
          }
          if (data.eleData) {
            this.eleStatus.seriesData = [
              data.eleData.onlineNum,
              data.eleData.maintainCount,
              data.eleData.failureNum,
              data.eleData.rescueNum,
              data.eleData.unOlineNum,
            ];
          }
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    getNotice() {
      this.$http
        .get("/api/system/web/announcement/loginGetAnnouncement")
        .then((res) => {
          if (res.data.success) {
            const {
              data: { data },
            } = res;
            this.notice = data;
          } else {
            this.$message.error(res.data.msg);
          }
        });
    },
    getSosList() {
      this.$http
        .post("/api/ele/web/sos/getList", this.searchForm)
        .then((res) => {
          if (res.data.success) {
            res.data.data.records.forEach((qs) => {
              qs.sosTypeName = this.getType(qs.sosType, 1);
              qs.sosStatusName = this.getType(qs.sosStatus, 2);
            });
            this.sosList = res.data.data.records ?? [];
          } else {
            this.$message.error(res.data.msg);
          }
        });
    }, // 获取类型
    getType(type, sta) {
      let obj;
      let arr = [];
      if (sta == 1) {
        arr = this.typeDate.SOS_TYPE;
      } else {
        arr = this.stateList;
      }
      arr.forEach((qs) => {
        if (qs.value == type) {
          obj = qs.label;
        }
      });
      return obj;
    },

    openTable(type) {
      console.log(type, 'openTable');
      this.eleTabledisabled = true
      this.$nextTick(() => {
        this.$refs.eleTableDialog.init(type, () => {});
      })
    },
    // 关闭报警弹框
    closeEleDialog() {
      this.eleTabledisabled = false
    },
    // 打开报警弹框
    openDialog(data){
      console.log(data, '111111');
      this.disabled = true
      this.$nextTick(() => {
        this.$refs.sosDialog.init(data, () => {});
      })
    },
    // 关闭报警弹框
    closeSosDialog() {
      this.disabled = false
    }
  },
};
</script>

<style lang="scss" scoped="scoped">
.left {
  height: 80vh;
  .leftTop {
    .leftTopRow {
      margin: 0 0 0 -8px;
      .dataBoxRow {
        margin: 8px 0;
        .dataBox {
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0 10px;
          border-radius: 8px;
          color: #fff;
          overflow: hidden;
          border: 1px solid #00f6ff;
          background: #23649387;
          cursor: pointer;
          box-shadow: 0 0 0 1px #2e4378, inset 0 0 10px #00f6ff;
          .dataBoxLeft {
            width: 45%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 10px 0;
          }
          .dataBoxRight {
            width: 55%;
            h2 {
              margin-top: 10px;
              margin-bottom: 10px;
            }
          }
        }
      }
    }
  }

  .leftCenter {
    margin: 10px 0 15px;
    // display: flex;
    // justify-content: center;
    // align-items: center;
    .charsLeft {
      // width: 45%;
      height: 100%;
      min-height: 240px;
      color: #fff;
      padding: 0 15px;
      border-radius: 10px;
      color: #fff;
      border: 1px solid #00f6ff;
      background: #23649387;
      box-shadow: 0 0 0 1px #2e4378, inset 0 0 10px #00f6ff;
      h4 {
        margin: 10px 0;
      }
    }
    .charsRight {
      // width: 55%;
      height: 100%;
      padding: 0 15px;
      margin: 0 10px;
      border-radius: 10px;
      color: #fff;
      border: 1px solid #00f6ff;
      background: #23649387;
      box-shadow: 0 0 0 1px #2e4378, inset 0 0 10px #00f6ff;
      h3 {
        width: 80%;
        overflow: hidden;
        white-space: nowrap;
        /*文字超出宽度则显示ellipsis省略号*/
        text-overflow: ellipsis;
      }
      h5 {
        margin: 5px 0;
      }
      .noticeBox {
        min-height: 200px;
        .noticeDetail {
          height: 180px;
          line-height: 30px;
          font-size: 16px;
          overflow-y: scroll;
          /deep/.ql-toolbar {
            display: none;
          }
          /deep/.quillClass {
            background: unset;
            .ql-container {
              background: unset;
              border-radius: 15px;
              overflow: hidden;
              border: none;
              padding: 15px 0;
              .ql-snow {
                border: none;
              }
            }
          }
        }
      }
    }
  }
  .leftBottom {
    height: 30%;
    margin-right: 10px;
    color: #fff;
    border-radius: 10px;
    border: 1px solid #00f6ff;
    background: #23649387;
    box-shadow: 0 0 0 1px #2e4378, inset 0 0 10px #00f6ff;
  }
}
.right {
  height: 100%;
  color: #fff;
  h4 {
    margin: 8px 0;
  }
  .sosBox {
    height: 120px;
    margin: 15px 0px;
    padding: 15px;
    border-radius: 10px;
    color: #fff;
    border: 1px solid #00f6ff;
    background: #23649387;
    box-shadow: 0 0 0 1px #2e4378, inset 0 0 10px #00f6ff;
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    cursor: pointer;
    .sosBoxTop,
    .sosBoxCenter {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 5px 0;
      .sosBoxTopLeft{
        width: 45%;
      }
      .sosBoxTopRight{
        width: 55%;
        word-break: break-all;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
      }
    }
    .sosBoxCenter,
    .sosBoxBottom {
      font-size: 14px;
    }
  }
  .scrollElement:first-child {
    margin-top: 4px;
  }
  .scrollElement:last-child {
    margin-bottom: 0px;
  }
}

.quillClass {
  width: 96%;
  background: #1282a5;
  border-radius: 10px;
  color: #fff;
  /deep/.ql-toolbar {
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
  }
  /deep/.ql-container {
    background: #1282a5;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  /deep/.ql-blank {
    height: 160px !important;
    color: #fff !important;
  }
  /deep/.ql-blank::placeholder {
    color: #fff !important;
  }
  /deep/.ql-snow .ql-stroke {
    stroke: #fff;
  }
  /deep/.ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-stroke {
    stroke: #fff;
  }
  /deep/.ql-snow .ql-picker-label {
    color: #fff !important;
  }
  /deep/.ql-snow .ql-picker-label::before {
    top: -8px;
    position: relative;
  }
}

.box2 .sz {
  top: 3px;
  color: #000000;
}

.box2 h3 {
  color: #5c5c5c;
  position: relative;
  top: 12px;
  left: 10px;
}

.icons {
  background: #f0f1f1;
  transition: all 0.1s linear;
  height: 60px;
  padding: 10px;
  margin: 10px;
  float: left;
  border-radius: 10px;
  margin-right: 20px;
}

.boxs:hover .icons {
  background: #40c9c6;
}

.boxs:hover .card-panel-icon {
  color: #f0f1f1;
}

.boxs2:hover .icons {
  background: #36a3f7;
}

.boxs2:hover .card-panel-icon2 {
  color: #f0f1f1;
}

.boxs {
  cursor: pointer;
  width: 225px;
  /* margin-left: 60px; */
  height: 101px;
  background: #f0f1f1;
}

.boxs2 {
  cursor: pointer;
  width: 225px;
  /* margin-left: 60px; */
  height: 101px;
  background: #f0f1f1;
}

.card-panel-icon2 {
  font-family: element-icons !important;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  vertical-align: baseline;
  display: inline-block;
  -webkit-font-smoothing: antialiased;
  font-size: 58px;
  color: #36a3f7;
}

.card-panel-icon2:before {
  content: "\e72e";
}

.card-panel-icon {
  font-family: element-icons !important;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  vertical-align: baseline;
  display: inline-block;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #40c9c6;
  font-size: 58px;
}

.card-panel-icon:before {
  content: "\e7a5";
}

.views {
  float: left;
  /* margin-left: 30px; */
}

.view1 {
  float: left;
  width: 500px;
  height: 400px;
}
</style>
