//用户类型
export const typeOptions = [
  {
    value: "xls",
    label: 'application/vnd.ms-excel',
  },
  {
    value: "xlsx",
    label: 'application/vnd.ms-excel',
  },
  {
    value: "doc",
    label: 'application/msword',
  },
  {
    value: "docx",
    label: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  },
  {
    value: "pdf",
    label: 'application/pdf',
  },
  {
    value: "ppt",
    label: 'application/vnd.ms-powerpoint',
  },
  {
    value: "pptx",
    label: 'application/vnd.ms-powerpoint',
  },
  {
    value: "png",
    label: 'image/png',
  },
  {
    value: "gif",
    label: 'image/gif',
  },
  {
    value: "jpeg",
    label: 'image/jpeg',
  },
  {
    value: "jpg",
    label: 'image/jpeg',
  },
  {
    value: "mp3",
    label: 'audio/mpeg',
  },
  {
    value: "rtf",
    label: 'application/rtf',
  },
  {
    value: "txt",
    label: 'text/plain',
  },
  {
    value: "zip",
    label: 'application/zip',
  }
]

export const getTypeOptions = (value) => {
  return getLabelValue(value, typeOptions);
};


const getLabelValue = (value, options) => {
  const arr = options.filter((item) => item.value == value);
  if (arr.length > 0) {
    return arr[0].label;
  } else {
    return "未知";
  }
};
