<template>
  <div class="admin-project-myproject-container">
    <div v-if="listTotal != 0">
      <el-table
        ref="loginCensusTable"
        :data="loginListData"
        :height="$store.state.tabHeight-100"
        @sort-change="handleSort"
        style="width: 100%;margin-bottom: 20px;"
      >
        <el-table-column
          type="index"
          align='center'
          label="序号"
        />
        <el-table-column
          align='center'
          prop="name"
          label="电梯名称"
          width="200px"
        />
        <el-table-column
          align='center'
          prop="deviceCode"
          label="AI摄像头ID"
          width="200px"
        />
        <el-table-column
          align='center'
          prop="code"
          label="电梯注册代码"
          width="100px"
        />
        <el-table-column
          align='center'
          prop="mergerName"
          label="所属区域"
          width="200px"
        />
        <el-table-column
          align='center'
          label="电量"
          width="100px"
        >
          <template slot-scope="scope">
            {{scope.row.batL ? scope.row.batL : '-'}}
          </template>
        </el-table-column>
        <el-table-column
          align='center'
          label="信号"
          width="100px"
        >
          <template slot-scope="scope">
            {{scope.row.netStrenth ? scope.row.netStrenth : '-'}}
          </template>
        </el-table-column>
        <el-table-column
          align='center'
          prop="eleAddress"
          label="安装位置"
          width="200px"
        />
        <el-table-column
          align='center'
          prop="createUserName"
          label="创建者账号"
        />
        <el-table-column
          align='center'
          prop="createTime"
          sortable
          label="上下线时间"
          width="200px"
        />
        <el-table-column
          align='center'
          prop="type"
          label="类型"
        />
      </el-table>
      <Pagination
        :total="listTotal"
        :page.sync="loginCensus.current"
        :limit.sync="loginCensus.size"
        @pagination="dictionarPage"
      />
    </div>
    <div class="nullDate" v-else >
      <img
        src="@/assets/nullDate.png"
        style="width: 260px;"
      />
    </div>

    <el-dialog
      v-if='dioDetails'
      :close-on-click-modal='false'
      :append-to-body="true"
      title="维保详情"
      :visible.sync="dioDetails"
      width="1200px"
      top='20vh'
    >
      <div
        style="height: 50vh;overflow-y: scroll;"
        class="scrollElement"
      >
        <dioDetails :dioData="dioDetailsItem"></dioDetails>
      </div>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          size="small"
          type="primary"
          @click="dioDetails = false"
        >关 闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Pagination from '@/components/Pagination'
import { formatDate } from "@/util";
let current_time = formatDate(new Date(), "yyyy-MM-dd") + " 00:00:00";
let current_time1 = new Date(current_time).getTime();
let starTime = current_time1 - 30 * 24 * 60 * 60 * 1000;
let endTime = current_time1 + 24 * 60 * 60 * 1000 - 1000;
export default {
  components: { Pagination },

  props: {
    deviceCode: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      // 维修详情
      dioDetails: false,
      dioDetailsItem: null,
      // 表格参数
      loginCensus: {
        current: 1,
        size: 10,
        startTime: starTime,
        endTime: endTime
      },
      loginListData: [],
      // 分页参数
      listTotal: 0,
    }
  },
  methods: {
    // 分页
    dictionarPage(data) {
      this.loginCensus.current = data.page
      this.loginCensus.size = data.limit
      this.onRefer()
    },
    //排序
    handleSort(column,prop,order){
      console.log('column',column)
      console.log('prop',prop)
      console.log('order',order)
      if(column.prop == 'createTime'){
        // this.loginCensus.createTimeSortSign = column.prop = 'createTime' ? '' : ''
        this.loginCensus.createTimeSortSign = column.order === 'descending' ? 'DESC' : 'ASC'
      }
      console.log('this.loginCensus',this.loginCensus)
      this.onRefer();
    },
    // 表格方法
    onRefer() {
      this.loginCensus.deviceCode = this.deviceCode
      this.loginCensus.name = ''
      this.$http
        .post(
          '/api/ele/web/loginLog/getLoginLogSubPage',
          this.loginCensus
        )
        // .post(
        //   `/api/ele/web/loginLog/getLoginLogPage?current=${this.loginCensus.current}&size=${this.loginCensus.size}`,
        //   this.loginCensus
        // )
        .then((res) => {
          if (res.data.success) {
            const { data: { data } } = res
            this.loginListData = data.records
            this.listTotal = data.total
            this.$nextTick(() => {
              if (this.total > 0 && this.$refs.loginCensusTable) {
                this.$refs.loginCensusTable.bodyWrapper.scrollTop = 0;
              }
            })
          } else {
            this.$message.error(res.data.msg)
          }
        })
    },
  },
}
</script>
<style lang="scss" scoped>
.nullDate {
  width: 100%;
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
