import Vue from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import VueAxios from 'vue-axios'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
// 引入富文本编辑器
import quillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import * as echarts from 'echarts' //引入echarts
import Cookie from 'js-cookie'
import qs from 'qs'
import {
	fixedRouter
} from '@/router'
// axios配置
import instance from './util/http.js'
import GLOBAL from "./util/global";
import VueAnimateNumber from 'vue-animate-number'
import { getStatusName,formatDate } from './util'

import JsonExcel from 'vue-json-excel'
Vue.component('downloadExcel', JsonExcel)

Vue.use(VueAnimateNumber)

Vue.prototype.$qs = qs

Vue.prototype.$global = GLOBAL;
Vue.prototype.$http = instance
Vue.prototype.$getStatusName = getStatusName
Vue.prototype.$formatDate = formatDate

// 长连接
import * as socketApi from '@/util/websocket.js'
Vue.prototype.socketApi = socketApi

// 安全帽长连接
import * as socketCapsApi from '@/util/websocketCaps.js'
Vue.prototype.socketCapsApi = socketCapsApi

// 中英文切换
import VueI18n from 'vue-i18n'
Vue.use(VueI18n)

// vuex
import Vuex from 'vuex';
Vue.use(Vuex)

Vue.prototype.$mapCode = require('@/assets/pcas-code.json')

Vue.prototype.$echarts = echarts //引入组件
Vue.use(ElementUI);
Vue.use(VueAxios, axios);
Vue.use(quillEditor)
Vue.config.productionTip = false

import VueRouter from 'vue-router'
const routerPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
	return routerPush.call(this, location).catch(error => error)
}

Vue.use(VueRouter)


// 中英文切换
const i18n = new VueI18n({
	locale: 'zh',
	messages: {
		'zh': require('@/lang/zh.js'),
		'en': require('@/lang/en.js')
	}
})

// 注册自定义指令
let onRot;
let butList = [];
let butObj = null;

Vue.directive('focus', {

	// 当被绑定的元素插入到 DOM 中时……
	bind: function(el, binding, vnode) {
		let bKey = '***'
		let obj = butObj.find(item => item.label == el.innerText.replace(/\s*/g, ""))
		if (obj != undefined) {
			bKey = onRot + obj.value
		}
		// console.log('12111', el.innerText, bKey, butList.indexOf(bKey) == -1)

		if (butList.indexOf(bKey) == -1) {
			el.style = "display:none"
		}
	}
})

let isT = false
// 路由守卫
router.beforeEach((to, from, next) => {
	// 获取路由去设置匹配id
	let rArr = to.path.split('/')
	onRot = rArr[rArr.length - 1]

	let token = sessionStorage.getItem('AdminToken')

	// 动态修改标签页标头
	// document.title = to.name
	let adminInfo = JSON.parse(sessionStorage.getItem('adminInfo'))
	axios.defaults.headers.common['Authorization'] = token
	// if (adminInfo != null) {
	// 	axios.defaults.headers.common['resource_key'] = adminInfo.id
	// }
	if (to.path == "/login") {
		isT = false
		butObj = null
	}
	console.log('qqaa', to.path)
	console.log('adminInfo', adminInfo)
	console.log('token', token)
	if ((adminInfo == null || token == null) && to.path != "/login" && to.path != "/loginWait" && to.path != "/stampPage") {
		console.log('进这里');
		if (window.location.href.split('?').length > 1) {
			console.log('loginWait');
			const token = window.location.href.split('?')[1].split('=')[1]
			sessionStorage.setItem('AdminToken', token)
			next({
				path: "/loginWait"
			});
			// next();
		} else {
			console.log('login');
			next({
				path: "/login"
			});
		}
	} else {
		if (butObj == null) {
			if (sessionStorage.getItem("adminInfo") != null) {
				butList = JSON.stringify(JSON.parse(sessionStorage.getItem("adminInfo")).buttons)
			}
			butObj = JSON.parse(sessionStorage.getItem("BUTTON_TYPE"))
		}
		next();
	}
	// 动态添加路由
	if (adminInfo != null && !isT && to.path != "/login" && to.path != "/loginWait") {
		isT = true
		var getRoutes = adminInfo.resource
		// console.log('getRoutes', getRoutes)
		let arr = getTreeData(getRoutes)
		router.options.routes = fixedRouter.concat(arr);
		router.addRoutes(fixedRouter.concat(arr))
		router.push({
			path: to.path
		})
	}

})

function getTreeData(data) {
  console.log(data,'data')
	for (var i = 0; i < data.length; i++) {
		const url = data[i].routeUrl
		// 去掉.vue
		// let uu = url.slice(0, url.length - 4)
		// console.log('2233', `@/views${url}.vue`)
		data[i].component = resolve => require([`@/views${url}.vue`], resolve)
		if (data[i].typeId == 2) {
			data[i].children = []
		}
		if (data[i].children != null && data[i].typeId != 2) {
			getTreeData(data[i].children);
		}
	}
	return data;
}

const store = new Vuex.Store({
	state: {
		userAgent: null, //webrtc对象
		userAgentStatus: false, //webrtc对象注册是否成功
		tabHeight: 600, //webrtc对象注册是否成功
		isZx: false, //webrtc对象注册是否成功
	}
});

new Vue({
	router,
	i18n,
	store,
	render: h => h(App)
}).$mount('#app')
