<template>
  <div class="admin-project-myproject-container">
    <el-form
      :inline="true"
      :model="formInline"
      class="demo-form-inline">
      <el-form-item label="日期">
					<el-date-picker
          v-model="formInline.allTime"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          class="einp">
					</el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          @click="onRefer"
          class="searchBtn"
        >查看</el-button>
      </el-form-item>
    </el-form>
    <div v-if="dictionarTotal!=0">
      <el-table
        :data="tableData"
        ref="multipleTable"
        style="width: 100%;margin-bottom: 20px;"
        @sort-change="handleSort"
      >
        <el-table-column
          type="index"
          label="序号"
          width="50px"
        />
        <el-table-column
          align='center'
          prop="eleName"
          label="电梯名称"
          width="200px"
        />
        <el-table-column
          align='center'
          prop="sosTypeName"
          label="报警类型"
          width="100px"
        >
          <template slot-scope="scope">
            {{scope.row.sosType == -1 ? scope.row.sosText : scope.row.sosTypeName}}
          </template>
        </el-table-column>
        <el-table-column
          align='center'
          prop="sosTime"
          label="报警时间"
          width="200px"
          sortable
        />
        <el-table-column
          align='center'
          prop="callTime"
          label="接警时间"
          width="200px"
          sortable
        />
        <el-table-column
          align='center'
          prop="presentTime"
          label="到场时间"
          width="200px"
        />
        <el-table-column
          align='center'
          prop="finishTime"
          label="完成时间"
          width="200px"
        />
        <el-table-column
          align='center'
          prop="sosFloor"
          label="报警楼层"
          width="100px"
        />
        <el-table-column
          align='center'
          prop="useTime"
          label="所用时长"
          width="200px"
        />
        <el-table-column
          align='center'
          prop="sosStatusName"
          label="应急状态"
          width="100px"
        />
        <el-table-column
          align='center'
          prop="eleCode"
          label="电梯注册码"
          width="200px"
        />
        <el-table-column
          align='center'
          prop="propertyUnitName"
          label="物业单位"
          width="200px"
        />
        <el-table-column
          align='center'
          prop="maintainUnitName"
          label="维保单位"
          width="200px"
        />
        <el-table-column
          align='center'
          prop="maintainUsers"
          label="维保人员"
          width="200px"
        />
        <el-table-column
          fixed="right"
          align='center'
          prop="address"
          label="操作"
          width="120px"
        >
          <template slot-scope="scope">
            <el-button
              @click="showDetails(scope.row)"
              type="primary"
              size="small"
            >查看</el-button>
          </template>
        </el-table-column>
      </el-table>
      <Pagination
        :total="dictionarTotal"
        :page.sync="formInline.current"
        :limit.sync="formInline.size"
        @pagination="dictionarPage"
      />
    </div>
    <div
      class="nullDate"
      v-else
    >
      <img
        src="@/assets/nullDate.png"
        style="width: 260px;"
      />
    </div>
    <el-dialog
      v-if='dioDetails'
      :close-on-click-modal='false'
      :visible.sync="dioDetails"
      :append-to-body="true"
      width="1200px"
      top='15vh'
    >
      <div
        class="tabBut"
        style="text-align: center"
      >
        <!-- <template v-for="(item, index) in detailsItem.seleArr"> -->

        <el-button
          @click="detailsItem.seleId=1"
          :type='1 == detailsItem.seleId ? "primary" : ""'
        >报警消息</el-button>
        <el-button
          v-show="dioDetailsItem.sosTypeName == '安全回路'"
          @click="detailsItem.seleId=2"
          :type='2 == detailsItem.seleId ? "primary" : ""'
        >运行参数</el-button>
        <el-button
          @click="detailsItem.seleId=3"
          :type='3==detailsItem.seleId ? "primary" : ""'
        >处置流程</el-button>
        <!-- </template> -->
      </div>
      <div
        style="height: 50vh;margin-top: 10px;"
        class="scrollElement"
        :class="[detailsItem.seleId==1?'yeScroll':'noScroll']"
      >
        <AlarmMessage
          v-if="detailsItem.seleId==1"
          :dioData="dioDetailsItem"
        />
        <Operational
          v-else-if="detailsItem.seleId==2"
          :dioData="dioDetailsItem"
        />
        <Process
          v-else
          :dioData="dioDetailsItem"
        />
      </div>
      <div
        slot="footer"
        class="dialog-footer"
        style="display: flex;align-items: center;justify-content: center;"
      >
        <div v-if="detailsItem.seleId==1">
          <el-button
            :disabled='dioDetailsItem.sosStatus!=1'
            size="mini"
            type="primary"
            @click="sosClick(1)"
          >
            <span>人为触发(无故障)</span>
            <p style="margin: 10px 0 0 0;">确认关闭</p>
          </el-button>
          <el-button
            :disabled='dioDetailsItem.sosStatus!=1'
            size="mini"
            type="info"
            @click="sosClick(2)"
          >
            <span>电梯故障(无困人)</span>
            <p style="margin: 10px 0 0 0;">派发维修工单</p>
          </el-button>
          <el-button
            :disabled='dioDetailsItem.sosStatus!=1'
            size="mini"
            type="warning"
            @click="sosClick(3)"
          >
            <span>困人</span>
            <p style="margin: 10px 0 0 0;">立即派发维修工单</p>
          </el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Pagination from '@/components/Pagination'
import Process from '../../../bjgl/bjjl/components/process'
import Operational from '../../../bjgl/bjjl/components/operational'
import AlarmMessage from '../../../bjgl/bjjl/components/alarmMessage'
import { formatDate } from '@/util';
let current_time = formatDate(new Date(), 'yyyy-MM-dd') + ' 00:00:00';
let current_time1 = new Date(current_time).getTime();
let starTime = new Date(current_time1 - 30 * 24 * 60 * 60 * 1000);
let endTime = new Date(current_time1 + 24 * 60 * 60 * 1000 - 1000);

export default {
  components: {
    Pagination,
    Process,
    Operational,
    AlarmMessage,
  },

  props: {
    elevatorId: {
      type: String,
      default: '',
    },
    deviceCode: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      // 报警详情
      detailsItem: {
        seleId: 1,
        seleArr: [
          {
            value: 1,
            label: '报警消息',
          },
          {
            value: 2,
            label: '运行参数',
          },
          {
            value: 3,
            label: '处置流程',
          },
        ],
      },
      dioDetails: false,
      dioDetailsItem: null,
      // 表格参数
      formInline: {
        current: 1,
        size: 10,
        allTime: [starTime, endTime],
      },
      tableData: [],
      // 分页参数
      dictionarTotal: 0,
      // 状态
      typeDate: [], //字典表
      stateList: [
        {
          value: 1,
          label: '待处理',
        },
        {
          value: 2,
          label: '已关闭',
        },
        {
          value: 3,
          label: '进行中',
        },
        {
          value: 4,
          label: '已完成',
        },
      ],
    }
  },
  methods: {
    // 详情操作按钮
    sosClick(type) {
      this.dioDetailsItem.sosStatus = 2
      this.$http
        .post('/api/ele/app/sos/submit', {
          id: this.dioDetailsItem.id,
          submitType: type,
          sosTime: this.dioDetailsItem.sosTime
        })
        .then((res) => {
          if (res.data.success) {
            this.$message.success(res.data.msg)
          } else {
            this.$message.error(res.data.msg)
          }
          this.onRefer()
        })
    },
    // 详情
    showDetails(item) {
      this.$http
        .post('/api/ele/web/sos/getInfo', {
          id: item.id,
          sosTime: item.sosTime ? item.sosTime : ''
        })
        .then((res) => {
          if (res.data.success) {
            res.data.data.sosVideo.forEach((qs) => {
              let url = qs.videoUrl.split('?')
              console.log(url)
              qs.videoUrl = url[0]
              qs.videoName = url[1].split('&')[0].split('=')[1]
            })

            res.data.data.sosTypeName = item.sosTypeName
            // res.data.data.sosStatusName = item.sosStatusName
            res.data.data.sosTypeName = item.sosType == -1 ? item.sosText : item.sosTypeName
            res.data.data.speed = res.data.data.speed !== '' ? parseFloat(res.data.data.speed) : 0
            res.data.data.speedarr = res.data.data.speedRecord.split(',')

            this.detailsItem.seleId = 1
            this.dioDetailsItem = res.data.data
            this.dioDetails = true

            console.log(123, res.data.data)
          } else {
            this.$message.error(res.data.msg)
          }
        })
    },
    // 分页
    dictionarPage(data) {
      this.formInline.current = data.page
      this.formInline.size = data.limit
      this.onRefer()
    },
    // 获取类型
    getType(type, sta) {
      let obj
      let arr = []
      if (sta == 1) {
        arr = this.typeDate.SOS_TYPE
      } else {
        arr = this.stateList
      }
      arr.forEach((qs) => {
        if (qs.value == type) {
          obj = qs.label
        }
      })
      return obj
    },
    //排序
    handleSort(column,prop,order){
      console.log('column',column)
      console.log('prop',prop)
      console.log('order',order)
      if(column.prop == 'sosTime'){
        this.formInline.sosTimeSortSign = column.order === 'descending' ? 'DESC' : 'ASC'
      }else if(column.prop == 'callTime'){
        this.formInline.callTimeSortSign = column.order === 'descending' ? 'DESC' : 'ASC'
      }
      this.onRefer()
    },
    // 表格方法
    onRefer(ishm) {
      this.formInline.eleId = this.elevatorId
      if (this.formInline.allTime) {
        let atime = JSON.parse(JSON.stringify(this.formInline.allTime))

        if (new Date(atime[0]).getTime() < new Date('2022-12-01').getTime()) {
          this.$message.error('查询时间不得早于2022年12月')
          return
        }
        // if (new Date(atime[1]).getTime() > new Date(new Date().toLocaleDateString()).getTime()) {
        //   this.$message.error('查询时间不得晚于当天')
        //   return
        // }
        this.formInline.start = new Date(atime[0]).getTime()
        this.formInline.end = new Date(atime[1]).getTime() + 24 * 60 * 60 * 1000 - 1000
      } else {
        this.$message.error('请选择查询时间')
        return
      }
      // console.log(this.formInline.allTime,'this.formInline.allTime');
      // this.formInline.start = starTime.getTime()
      // this.formInline.end = endTime.getTime()

      // console.log(this.formInline, 'this.formInline');

      this.$http
        .post('/api/ele/web/sos/getList', this.formInline)
        .then((res) => {
          console.log('列表', res.data)
          if (res.data.success) {
            res.data.data.records.forEach((qs) => {
              qs.sosTypeName = this.getType(qs.sosType, 1)
              qs.sosStatusName = this.getType(qs.sosStatus, 2)
            })
            this.tableData = res.data.data.records
            this.dictionarTotal = res.data.data.total
            this.$nextTick(() => {
              if (this.dictionarTotal > 0 && this.$refs.multipleTable) {
                this.$refs.multipleTable.bodyWrapper.scrollTop = 0;
              }
            })
          } else {
            this.$message.error(res.data.msg)
          }
        })
    },
  },
  created() {
    this.$http
      .post('/api/system/web/dict/dictionary?codes=SOS_TYPE')
      .then((res) => {
        if (res.data.success) {
          this.typeDate = res.data.data
          // this.onRefer()
        }
      })
  },
}
</script>
<style lang="scss" scoped>
.yeScroll {
  overflow-y: scroll;
}
.nullDate {
  width: 100%;
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.yeScroll {
  overflow-y: scroll;
}
</style>
