<template>
  <div class="showEl">
    <div style="display: flex;margin-bottom: 20px;">
        <div style="width: 50%;"> 
          <el-card>
            <span style="width: 100%;display: flex;justify-content: space-between; align-items: center;">
              电梯名称: {{dioData.eleName}}
              <el-button type="danger" size="mini">{{dioData.sosTypeName}}</el-button>
            </span>
            <p style="margin-top: 8px">电梯注册代码: {{dioData.eleCode}}</p>
            <p>报警时间: {{dioData.sosTime}}</p>
            <p>维保单位: {{dioData.maintainUnitName}}</p>
            <p>物业单位: {{dioData.propertyUnitName}}</p>
            <p>所属小区: {{dioData.plotName}}</p>
            <span>地点: {{dioData.eleAddress}}</span>
          </el-card>
          <div style="margin-top: 15px;border-radius: 5px; overflow: hidden;">
            <div id="allmap"></div>
          </div>
        </div>
        <div class="scrollElement" style="height: 55vh;width: 50%;margin: auto; overflow-y: scroll;">
          <el-timeline>
            <el-timeline-item
              style="width: 100%;text-align: left;"
              v-for="(activity, index) in rescue"
              :key="index"
              :timestamp="activity.timestamp"
            >
              <h3 style="margin: 0;">{{activity.sosStatusName}}</h3>

              <p>{{activity.time}}</p>

              <template v-if="activity.status==25">
                <p>维修时长:{{activity.time}}</p>
                <p>故障维修完成时间:{{activity.end}}</p>
              </template>

              <template v-if="activity.status==2||activity.status==13||activity.status==23">
                <p>账号:&ensp;{{activity.userName}}</p>
                <p>{{activity.status==2?'接警时间':'确认时间'}}:&ensp;{{activity.time}}</p>
              </template>

              <template v-if="activity.status==14">
                <p>{{activity.users}}</p>
                <p>救援时长:&ensp;{{activity.time}}</p>
                <p>救援开始时间:&ensp;{{activity.start}}</p>
                <p>救援结束时间:&ensp;{{activity.end}}</p>
                <p>救援描述:&ensp;{{activity.desc}}</p>
              </template>

              <template v-if="activity.status==24">
                <p>{{activity.users}}</p>
                <p>开始时间:&ensp;{{activity.start}}</p>
              </template>
            </el-timeline-item>
          </el-timeline>
        </div>
    </div>
  </div>
</template>

<script>
let map
let marker
export default {
  props: ['dioData'],
  data() {
    return {
      isType: null,
      lineArr: [], //轨迹
      sosWay: [],
      rescue: null,
      stateList: [
        {
          value: 1,
          label: '发生报警',
        },
        {
          value: 2,
          label: '人员接警',
        },
        {
          value: 3,
          label: '已完成',
        },
        {
          value: 23,
          label: '确认故障',
        },
        {
          value: 24,
          label: '故障维修中',
        },
        {
          value: 25,
          label: '已完成',
        },
        {
          value: 13,
          label: '确认救援',
        },
        {
          value: 14,
          label: '救援行动',
        },
        {
          value: 15,
          label: '已完成',
        },
      ],
    }
  },
  mounted() {
    map = new AMap.Map('allmap', {})
    if (this.dioData.rescueTaskRoute.length > 0) {
      this.dioData.rescueTaskRoute.forEach((qs, ind) => {
        let palArr = JSON.parse(qs.gpsRoute)
        marker = new AMap.Marker({
          map: map,
          position: palArr[0],
          icon: 'https://webapi.amap.com/images/car.png',
          offset: new AMap.Pixel(-26, -13),
          autoRotation: true,
          angle: -90,
          title: qs.userName,
        })

        map.setFitView([marker])

        // 绘制轨迹
        var polyline = new AMap.Polyline({
          map: map,
          path: palArr,
          showDir: true,
          strokeColor: '#28F', //线颜色
          // strokeOpacity: 1,     //线透明度
          strokeWeight: 6, //线宽
          // strokeStyle: "solid"  //线样式
        })

        let passedPolyline = new AMap.Polyline({
          map: map,
          // path: lineArr,
          strokeColor: '#AF5', //线颜色
          // strokeOpacity: 1,     //线透明度
          strokeWeight: 6, //线宽
          // strokeStyle: "solid"  //线样式
        })

        marker.on('moving', function (e) {
          passedPolyline.setPath(e.passedPath)
        })
      })
    }
    console.log(
      [this.dioData.ypoint, this.dioData.xpoint],
      '[this.dioData.xpoint, this.dioData.ypoint]'
    )
    var marker1 = new AMap.Marker({
      map: map,
      position: [this.dioData.ypoint, this.dioData.xpoint], //位置
    })
    // markers.push(marker)
    map.add(marker1) //添加到地图
    map.setCenter([this.dioData.ypoint, this.dioData.xpoint])
  },
  created() {
    this.isType = '救援'
    console.log('this.rescue', this.dioData.disposalProcess)
    this.rescue = this.dioData.disposalProcess.rescue
    if (this.rescue == undefined) {
      this.isType = '故障'
      this.rescue = this.dioData.disposalProcess.failure
      if (this.rescue == undefined) {
        this.rescue = this.dioData.disposalProcess.sos
      }
    }

    this.sosWay = this.dioData.rescueTaskRoute

    if (this.rescue != undefined) {
      this.rescue.forEach((qs) => {
        qs.sosStatusName = this.getType(qs.status)
      })
    }
    console.log('this.rescue', this.dioData, this.rescue)
  },
  methods: {
    getType(type) {
      let obj = null
      this.stateList.forEach((qs) => {
        if (qs.value == type) {
          obj = qs.label
        }
      })
      return obj
    },
  },
}
</script>

<style scoped>
.mapBom {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  width: 650px;
  height: 7vh;
}

#allmap {
  width: 100%;
  height: 28vh;
  color: #444;
}

.showEl {
  /* width: 400px; */
  /* overflow-y: scroll; */
  height: 58vh;
  margin-right: 20px;
  margin-top: 30px;
}

span,
p,
h3 {
  color: white;
}
</style>
