<template>
  <div class="admin-project-myproject-container">
    <el-form
      :inline="true"
      :model="updateVideoSearch"
      class="demo-form-inline"
    >
      <el-form-item label="日期">
        <el-date-picker
          v-model="updateVideoSearch.time"
          type="date"
          format="yyyy 年 MM 月 dd 日"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          @click="searchElevator"
          class="searchBtn"
        >查看</el-button>
        <!-- <el-button
          type="primary"
          @click="batchUpload"
        >批量上传</el-button> -->
      </el-form-item>
    </el-form>
    <el-table
      ref="updateVideoList"
      :data="updateVideoList"
      v-loading="loading"
      stripe
      fit
      highlight-current-row
      row-key="id"
      @select="handleSelectionChange"
      @select-all="selectAll"
    >
      <el-table-column
        label="序号"
        type="index"
        width="60px"
        align="center"
      />
      <el-table-column
        align="center"
        prop="file_name"
        label="文件名称"
        min-width="150px"
      />
      <el-table-column
        align="center"
        label="文件上传进度"
        min-width="80px"
      >
        <template slot-scope="scope">
          <span v-if="!scope.row.upload"> 未上传 </span>
          <span v-else>{{scope.row.upload}}</span>
        </template>
      </el-table-column>

      <el-table-column
        align="center"
        prop="address"
        label="操作"
        fixed="right"
        width="300px"
      >
        <template slot-scope="scope">
          <el-button
            @click="update(scope.row.file_name, scope.row)"
            type="primary"
            size="small"
          >上传
          </el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    elevatorId: {
      type: String,
      default: '',
    },
    deviceCode: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      visible: false,
      loading: true,
      updateVideoSearch: {
        time: '',
      },
      updateVideoList: [],
      initArr: [],
      upload: '0%', // 上传进度
      i: 0,
    }
  },
  mounted() {
    this.updateVideoSearch.time = new Date()
  },
  methods: {
    //全选
    selectAll(selection) {
      this.initArr = selection
    },

    handleSelectionChange(val, row) {
      if (this.initArr.find((item) => item.id === row.id)) {
        this.initArr = this.initArr.filter((item) => item.id !== row.id)
      } else {
        this.initArr.push(row)
      }
    },

    // 获取视频列表
    searchElevator() {
      this.loading = true
      // 转换格式
      let y = this.updateVideoSearch.time.getFullYear()
      let m = this.updateVideoSearch.time.getMonth() + 1
      m = m < 10 ? '0' + m : m
      let d = this.updateVideoSearch.time.getDate()
      d = d < 10 ? '0' + d : d
      const day = `${y}${m}${d}`

      if (this.socketApi.isheart) {
        const agentData = {
          act: 'server_push_get_local_video_list',
          token: JSON.parse(sessionStorage.getItem('adminInfo')).token.token,
          startTime: `${day}000000`,
          endTime: `${day}235959`,
          deviceId: this.deviceCode,
        }
        this.socketApi.sendSock(agentData, (e) => {
          if (e.cmd === 'server_push_get_local_video_list' && e.status) {
            this.updateVideoList = e.data
            // this.updateVideoList.forEach((item) => {
            //   item.upload = '未上传'
            // })
          }
          this.loading = false
        })
      }
    },

    // 上传
    update(url, row) {
      this.$set(
        this.updateVideoList.find((item) => item.file_md5 === row.file_md5),
        'upload',
        '0%'
      )
      const urlArr = [url]
      const data = {
        deviceId: this.deviceCode,
        fileList: urlArr,
        type: 1,
        md5: row.file_md5,
        talkBackId: row.file_md5,
      }
      this.$http.post('/api/ele/web/eleLog/pushFile', data).then((res) => {
        if (!res.hasOwnProperty('code')) {
          this.$message(res.data.msg)
          // 循环调用获取进度
          this.timer = setInterval(() => {
            this.get_progress(row)
          }, 5 * 1000)
        }
      })
    },

    // 批量上传
    batchUpload() {},

    // 获取对应文件的上传进度
    get_progress(row) {
      this.i++
      this.$http
        .get(`/api/group1/uploadGetProgress?X-Progress-ID=${row.file_md5}`)
        .then((res) => {
          if (res.data) {
            const data = res.data

            if (data.status === 'uploading') {
              this.upload = `${data.message}%`
              this.$set(
                this.updateVideoList.find(
                  (item) => item.file_md5 === row.file_md5
                ),
                'upload',
                this.upload
              )
            }
            // 上传开始
            if (data.status === 'starting') {
              this.upload = '50%'

              this.$set(
                this.updateVideoList.find(
                  (item) => item.file_md5 === row.file_md5
                ),
                'upload',
                this.upload
              )
            }
            // 上传开始
            // if (data.status === '') {
            //   const num = this.i * 5 < 100 ? this.i * 5 : 99 
            //   this.upload = `${num}%`

            //   this.$set(
            //     this.updateVideoList.find(
            //       (item) => item.file_md5 === row.file_md5
            //     ),
            //     'upload',
            //     this.upload
            //   )
            // }
            // 上传完成
            if (data.status === 'ok') {
              this.i = 0
              this.upload = '100%'
              this.$set(
                this.updateVideoList.find(
                  (item) => item.file_md5 === row.file_md5
                ),
                'upload',
                this.upload
              )
              clearInterval(this.timer)
            }
            // 删除定时器对应md5的上传文件
            if (this.upload === '100%') {
              clearInterval(this.timer)
            }
          }
        })
    },

    updateVideoDialogClose() {
      this.$refs.updateVideoList.setCurrentRow()
      this.$refs.updateVideoList.clearSelection()
    },
  },
}
</script>
<style lang="scss" scoped>
/deep/.el-loading-mask{
  background-color: #7474744d!important;
}
</style>
