<template>
  <div
    :class="className"
    :style="{width:width, height:height}"
  />
</template>

<script>
import echarts from 'echarts'

export default {
  props: {
    className: {
      type: String,
      default: 'chart',
    },
    width: {
      type: String,
      default: '100%',
    },
    height: {
      type: String,
      // default: 'calc(100% - 30px)'
      default: '240px',
    },
    autoResize: {
      type: Boolean,
      default: true,
    },
    eleStatus: {
      type: Object,
      default: () => {
        return {
          xAxis: ['在线', '维保中', '故障', '困人', '离线'], // x轴
          seriesData: [0, 0, 0, 0, 0], // 数据
        }
      },
    },
  },

  data() {
    return {
      chart: null,
    }
  },

   // 监听data里面对应的
  watch: {
    data: {
      deep: true,
      handler(val) {
        this.setOptions(val)
      },
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.initChart()
    })
  },

  beforeDestroy() {
    if (!this.chart) return
    // 释放图表实例
    this.chart.dispose()
    this.chart = null
  },

  methods: {
    initChart() {
      this.chart = this.$echarts.init(this.$el)
      this.setOptions(this.eleStatus)
    },
    setOptions(data) {
      const { seriesData, xAxis } = data
      console.log(seriesData,'seriesData');

      const options = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
          },
        },
        textStyle: {
          color: '#fff', // 所有文字的颜色
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true,
        },
        xAxis: [
          {
            type: 'category',
            data: xAxis,
            axisTick: {
              alignWithLabel: true,
            },
            axisLine: {
              lineStyle: {
                color: '#fff', // 坐标轴颜色
              },
            },
          },
        ],
        yAxis: [
          {
            type: 'value',
          },
        ],
        series: [
          {
            name: '电梯数量',
            type: 'bar',
            barWidth: '25px',
            data: seriesData,
            label: {
              normal: {
                show: true, //开启显示
                position: 'top', //柱形上方
                textStyle: {
                  //数值样式
                  color: '#fff',
                },
              },
            },
            itemStyle: {
              color: function (params) {
                //注意，如果颜色太少的话，后面颜色不会自动循环，最好多定义几个颜色
                var colorList = [
                  '#00b1ff',
                  '#00ff45',
                  '#ff0000',
                  '#ff9300',
                  '#9b9b9b',
                ]
                return colorList[params.dataIndex]
              },
            },
          },
        ],
      }
      this.chart.setOption(options)
    },
  },
}
</script>
