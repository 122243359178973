<template>
  <el-dialog
    :title="tit"
    :close-on-click-modal="false"
    :visible.sync="dioDetails"
    width="1400px"
    top="10vh"
    @close="closeDialog"
  >
    <div style="height: 70vh; overflow-y: scroll" class="scrollElement">
      <div v-if="total != 0">
        <!-- 今日需维保电梯 -->
        <div v-if="type == 'DayCount'">
          <el-table
            ref="multipleTable"
            :data="tableData"
            style="width: 100%; margin-bottom: 20px"
            :height="$store.state.tabHeight"
          >
            <el-table-column type="index" label="序号" width="50px" />
            <el-table-column align="center" prop="planName" label="计划名称" />
            <el-table-column align="center" prop="eleName" label="电梯名称" />
            <el-table-column align="center" prop="plotName" label="小区名称" />
            <el-table-column align="center" prop="maintainTypeName" label="维保类型" />
            <el-table-column align="center" prop="startTime" label="计划开始时间" />
            <el-table-column align="center" prop="finishTime" label="计划结束时间" />
            <el-table-column align="center" prop="createTime" label="创建时间" />
            <el-table-column align="center" prop="createUserName" label="创建人" />
          </el-table>
        </div>
        <!-- 今日已维保电梯 -->
        <div v-else-if="type == 'DayFinishedCount'">
          <el-table
            ref="multipleTable"
            :data="tableData"
            style="width: 100%; margin-bottom: 20px"
            :height="$store.state.tabHeight"
          >
            <el-table-column type="index" label="序号" width="50px" />
            <el-table-column align="center" prop="eleName" label="电梯名称" />
            <el-table-column align="center" prop="startTime" label="计划时间" />
            <el-table-column align="center" prop="signTime" label="签到时间" />
            <el-table-column align="center" prop="finishTime" label="完成时间" />
            <el-table-column align="center" prop="plotName" label="所属小区" />
            <el-table-column align="center" prop="maintainTypeName" label="计划类型" />
            <el-table-column align="center" prop="eleCode" label="电梯注册代码" />
            <el-table-column align="center" prop="eleAddress" label="电梯安装位置" />
            <el-table-column align="center" prop="propertyUnitName" label="物业单位" />
            <el-table-column align="center" prop="maintainUnitName" label="维保单位" />
            <el-table-column align="center" prop="maintainUserNames" label="维保人员" />
          </el-table>
        </div>
        <!-- 今日未维保电梯 -->
        <div v-else-if="type == 'DayUnfinishedCount'">
          <el-table
            ref="multipleTable"
            :data="tableData"
            style="width: 100%; margin-bottom: 20px"
            :height="$store.state.tabHeight"
          >
            <el-table-column type="index" label="序号" width="50px" />
            <el-table-column align="center" prop="eleName" label="电梯名称" />
            <el-table-column align="center" prop="startTime" label="计划时间" />
            <el-table-column align="center" prop="signTime" label="签到时间" />
            <el-table-column align="center" prop="finishTime" label="完成时间" />
            <el-table-column align="center" prop="plotName" label="所属小区" />
            <el-table-column align="center" prop="maintainTypeName" label="计划类型" />
            <el-table-column align="center" prop="eleCode" label="电梯注册代码" />
            <el-table-column align="center" prop="eleAddress" label="电梯安装位置" />
            <el-table-column align="center" prop="propertyUnitName" label="物业单位" />
            <el-table-column align="center" prop="maintainUnitName" label="维保单位" />
            <el-table-column align="center" prop="maintainUserNames" label="维保人员" />
          </el-table>
        </div>
        <!-- 即将大修/改造电梯 -->
        <div v-else-if="type == 'overhaulCount'">
          <el-table
            ref="multipleTable"
            :data="tableData"
            style="width: 100%; margin-bottom: 20px"
            :height="$store.state.tabHeight"
          >
            <el-table-column type="index" label="序号" width="50px" />
            <el-table-column align="center" prop="eleName" label="电梯名称" />
            <el-table-column align="center" prop="eleCode" label="电梯注册码" />
            <el-table-column align="center" prop="eleAddress" label="电梯地址" width="200px" />
            <el-table-column align="center" prop="maintainUnitName" label="维保公司" />
            <el-table-column align="center" prop="overhaulUser" label="大修负责人" />
            <el-table-column align="center" prop="planStartTime" label="计划开始时间" />
            <el-table-column align="center" prop="planEndTime" label="计划结束时间" />
            <el-table-column align="center" prop="actualStartTime" label="实际开始时间" />
            <el-table-column align="center" prop="actualEndTime" label="实际结束时间" />
            <!-- <el-table-column align="center" label="状态">
              <template slot-scope="scope">
                {{ scope.row.overhaulStatus == 0 ? "未开始" : (scope.row.overhaulStatus == 1 ? "进行中" : "已完成") }}
              </template>
            </el-table-column> -->
          </el-table>
        </div>
        <!-- 维修中电梯 -->
        <div v-else-if="type == 'failureNum'" v-loading="loading">
          <el-table
            ref="multipleTable"
            :data="tableData"
            style="width: 100%; margin-bottom: 20px"
            :height="$store.state.tabHeight"
          >
            <el-table-column type="index" label="序号" width="50px" />
            <el-table-column align='center' prop="eleName" label="电梯名称" width="150px" />
            <el-table-column align='center' prop="eleCode" label="电梯注册代码" width="150px"/>
            <el-table-column align='center' prop="failureDesc" label="故障描述" width="200px" />
            <el-table-column align='center' prop="statusName" label="处理状态" width="120px" />
            <el-table-column align='center' prop="sourceName" label="故障来源" width="120px" />
            <el-table-column align='center' prop="declarantName" label="故障申报人" width="120px" />
            <el-table-column align='center' label="故障报告时间" width="180px">
              <template slot-scope="scope">
                {{ scope.row.failureTime ? scope.row.failureTime : '-' }}
              </template>
            </el-table-column>
            <el-table-column align='center' label="电梯停运时间" width="180px">
              <template slot-scope="scope">
                {{ scope.row.outageTime ? scope.row.outageTime.split(' ')[0] : '-' }}
              </template>
            </el-table-column>
            <el-table-column align='center' label="完成时间" width="180px">
              <template slot-scope="scope">
                {{ scope.row.finishTime ? scope.row.finishTime : '-' }}
              </template>
            </el-table-column>
            <el-table-column align='center' label="所属小区" width="180px">
              <template slot-scope="scope">
                {{scope.row.plotName ? scope.row.plotName : '-'}}
              </template>
            </el-table-column>
            <el-table-column align="center" prop="propertyUnitName" label="物业单位" width="180px"/>
            <el-table-column align="center" prop="maintainUnitName" label="维保单位" width="180px"/>
            <el-table-column align="center" prop="eleAddress" label="电梯安装位置"  width="180px"/>
          </el-table>
        </div>
        <!-- 保险即将过期电梯 -->
        <div v-else-if="type == 'contractCount'">
          <el-table
            ref="multipleTable"
            :data="tableData"
            style="width: 100%; margin-bottom: 20px"
            :height="$store.state.tabHeight"
          >
            <el-table-column type="index" label="序号" width="50px" />
            <el-table-column align="center" prop="type" label="保险类型" >
              <template slot-scope="scope">
                {{
                contractType.find((item) => item.value === scope.row.type)
                  ? contractType.find((item) => item.value === scope.row.type)
                    .label
                  : ''
                }}
              </template>
            </el-table-column>
            <el-table-column align="center" prop="insuredOrg" label="投保单位" />
            <el-table-column align="center" prop="insuranceCompany" label="保险公司" />
            <el-table-column align="center" prop="count" label="电梯数量" />
            <el-table-column align="center" label="状态" >
              <template slot-scope="scope">
                <span>{{scope.row.status == 2 ? '已过期' : ( scope.row.status == 1 ? '生效中' : '未生效')}}</span>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="startTime" label="保险开始时间" />
            <el-table-column align="center" prop="endTime" label="保险结束时间" />
          </el-table>
        </div>
        <Pagination
          :total="total"
          :page.sync="formInline.current"
          :limit.sync="formInline.size"
          @pagination="dictionarPage"
        />
      </div>

      <div class="nullDate" v-else>
        <img src="@/assets/nullDate.png" style="width: 260px" />
      </div>
    </div>
    <span
      slot="footer"
      class="dialog-footer"
      style="display: flex; align-items: center; justify-content: center"
    >
    </span>
  </el-dialog>
</template>

<script>
import Pagination from '@/components/Pagination'
export default {
  components: {
    Pagination
  },
  data() {
    return {
      dioDetails: false,
      addBrandCallback: null, // 弹窗回调

      loading: true,

      tit: "",
      total: 0,
      formInline: {
        current: 1,
        size: 10,
      },
      tableData: [],
      type: "",
      // 合同类型
      contractType: [{
          label: '物业合同',
          value: 'TENEMENT_CONTRACT',
        },
        {
          label: '维保合同',
          value: 'MAINTAIN_CONTRACT',
        },
        {
          label: '电梯安全责任险',
          value: 'SAFETY_INSURANCE',
        },
        {
          label: '电梯意外责任险',
          value: 'ACCIDENT_INSURANCE',
        },
      ],
      // 两个下拉框
        gzType: [
          { value: 1, label: '维保发现' },
          { value: 2, label: '救援发现' },
          { value: 3, label: '巡逻发现' },
          { value: 4, label: '乘梯发现' },
          { value: 5, label: '平台发现' },
          { value: 6, label: '监控发现' }
        ],
        clType: [],
    };
  },

  created() {
    this.getDictionar(['FAILURE_STATUS'])
  },
  mounted() {},
  methods: {
    init(type, callback) {
      this.type = type;
      switch (type) {
        case "DayCount":
          this.getCount(1)
          this.tit = "今日需维保电梯";
          break;
        case "DayFinishedCount":
          this.getCount(3)
          this.tit = "今日已维保电梯";
          break;
        case "DayUnfinishedCount":
          this.getCount(2)
          this.tit = "今日未维保电梯";
          break;
        case "overhaulCount":
          this.getOverhaul()
          this.tit = "即将大修/改造电梯";
          break;
        case "failureNum":
          this.getFailure()
          this.tit = "维修中电梯";
          break;
        case "contractCount":
          this.getContract()
          this.tit = "保险即将过期电梯";
          break;

        default:
          this.tit = "title";
          break;
      }
      this.dioDetails = true;
      this.addBrandCallback = callback;
      this.$nextTick(() => {});
    },

    
    // 获取字典数据
    getDictionar(code) {
      // code = code.join()
      this.$http
        .post(`/api/system/web/dict/dictionary?codes=${code}`)
        .then((res) => {
          console.log('字典', res)
          if (res.data.code === 200) {
            const {
              data: {
                data
              },
            } = res
            this.clType = data.FAILURE_STATUS
          } else {
            this.$message.error(res.data.msg)
          }
        })
    },

    //1:需维保 2：未维保3：已维保
    getCount(num) {
      this.$http
        .get(`/api/ele/web/maintainTask/loginTopEleListData?current=${this.formInline.current}&size=${this.formInline.size}&selectType=${num}`)
        .then((res) => {
          if (res.data.success) {
            this.tableData = res.data.data.records;
            this.total = res.data.data.total;
            this.$nextTick(() => {
              if (this.total > 0 && this.$refs.multipleTable) {
                this.$refs.multipleTable.bodyWrapper.scrollTop = 0;
              }
            });
          } else {
            this.$message.error(res.data.msg);
          }
      });
    },
    // 即将大修
    getOverhaul() {
      const data = JSON.parse(JSON.stringify(this.formInline))
      data.selectType = 1
      this.$http
        .post("/api/ele/web/elevatorOverhaul/getElevatorOverhaulPage", data)
        .then((res) => {
          if (res.data.success) {
            this.tableData = res.data.data.records;
            this.total = res.data.data.total;
            this.$nextTick(() => {
              if (this.total > 0 && this.$refs.multipleTable) {
                this.$refs.multipleTable.bodyWrapper.scrollTop = 0;
              }
            });
          } else {
            this.$message.error(res.data.msg);
          }
        });
    },

    // 维修中电梯
    getFailure() {
      const data = JSON.parse(JSON.stringify(this.formInline))
      data.taskStatus = 2
      this.loading = true
      this.$http
          .post('/api/ele/web/failureTask/getList', data)
          .then((res) => {
            console.log('列表', res.data)
            if (res.data.success) {
              res.data.data.records.forEach((qs) => {
								qs.statusName = this.getType(qs.status, 2)
                qs.sourceName = this.getType(qs.sourceType, 1)
              })
              this.tableData = res.data.data.records
              this.total = res.data.data.total
              this.$nextTick(() => {
                this.loading = false
                if (this.total > 0 && this.$refs.multipleTable) {
                  this.$refs.multipleTable.bodyWrapper.scrollTop = 0;
                }
              })
            } else {
              this.$message.error(res.data.msg)
            }
          })
    },
    // 保险即将过期
    getContract() {
      const data = JSON.parse(JSON.stringify(this.formInline))
      data.selectType = 1
      this.$http
        .post("/api/ele/web/contract/list", data)
        .then((res) => {
          if (res.data.success) {
            this.tableData = res.data.data.records;
            this.total = res.data.data.total;
            this.$nextTick(() => {
              if (this.total > 0 && this.$refs.multipleTable) {
                this.$refs.multipleTable.bodyWrapper.scrollTop = 0;
              }
            });
          } else {
            this.$message.error(res.data.msg);
          }
        });
    },

    // 分页
    dictionarPage (data) {
      this.formInline.current = data.page;
      this.formInline.size = data.limit;
      switch (this.type) {
        case "DayCount":
          this.getCount(1)
          break;
        case "DayFinishedCount":
          this.getCount(3)
          break;
        case "DayUnfinishedCount":
          this.getCount(2)
          break;
        case "overhaulCount":
          this.getOverhaul()
          break;
        case "failureNum":
          this.getFailure()
          break;
        case "contractCount":
          this.getContract()
          break;

        default:
          break;
      }
    },
    
    // 获取类型
    getType (type, sta) {
      let obj
      let arr = []
      if (sta == 1) {
        arr = this.gzType
      } else {
        arr = this.clType
      }
      arr.forEach((qs) => {
        if (qs.value == type) {
          obj = qs.label
        }
      })
      return obj
    },
    closeDialog() {
      this.dioDetails = false;
      this.$emit("closeEleDialog");
    },
  },
};
</script>
<style lang="scss" scoped>
.nullDate {
  width: 100%;
  height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
