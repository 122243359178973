<template>
  <div style="padding: 5px; background-color: #3d7796; height: 100%">
    <!-- background-color: #1288a9; -->
    <div class="floor-info">
      <span style="color: white">楼层信息</span>
      <el-button type="text" style="font-size: 16px" @click="close"
        >关闭
      </el-button>
    </div>
    <el-table :data="list" height="350px">
      <el-table-column label="序号" type="index" />
      <el-table-column label="默认楼层名称" prop="floorName" />
      <el-table-column label="海拔高度" prop="altitudeHeight" />
      <el-table-column label="楼层高度" prop="height" />
    </el-table>
    <div slot="right-footer" class="table-footer">
      <el-button
        type="text"
        style="color: #f00 !important"
        @click="errorInfo(2)"
        >信息不准确
      </el-button>
      <el-button type="text" style="margin-right: 15px" @click="errorInfo(1)"
        >信息准确
      </el-button>
    </div>
  </div>
</template>

<script>
export default {
  props: ["elevatorId", "deviceCode", "floorId"],
  data() {
    return {
      list: null,
      infoData: [],
      heights: [],
    };
  },
  methods: {
    //关闭
    close() {
      this.$emit("close");
    },
    getDate() {
      this.$http
        .get(`/api/ele/web/floor/getFloorDetail?id=${this.floorId}`)
        .then((res) => {
          if (res.status == 200 && res.data.success) {
            //别名
            const floorAlias = res.data.data.floorAlias
              ? JSON.parse(res.data.data.floorAlias).data
              : [];
            //层高
            const heightMap = res.data.data.heightMap
              ? JSON.parse(res.data.data.heightMap).data
              : [];
            //海拔
            const floorHeight =
              res.data.data.floorHeight != ""
                ? JSON.parse(res.data.data.floorHeight).data
                : [];
            this.list = heightMap;
            floorHeight.forEach((item, index) => {
              this.list[index].altitudeHeight = item.height;
              this.list[index].index = index;
            });
            if (floorAlias && floorAlias.length > 0) {
              floorAlias.forEach((item, index) => {
                this.list[index].floorName = item.name;
                this.list[index].index = index;
              });
            }
            console.log("item2", this.list);
            //信息强度
            // const signalValue=res.data.signalValue ? JSON.parse(res.data.signalValue).data : [];
            // signalValue.forEach((item, index) => {
            //   this.list[index].infoStrong=item.rSSI
            // })
          } else {
            this.$message.error(res.data.msg);
          }
        });
    },
    //信息检验
    errorInfo(type) {
      console.log("this.floorId", this.floorId);
      this.infoData = {
        id: this.floorId,
        examineStatus: type,
        elevatorId: this.elevatorId,
      };
      this.$http
        .post("/api/ele/web/floor/examineFloorLog", this.infoData)
        .then((res) => {
          if (res.data.success) {
            this.$message.success({
              type: "success",
              message: res.data.msg,
            });
            if (type == 2) {
              const agentData = {
                act: 'device_restart',
                device_id: this.deviceCode
              }
              this.socketApi.sendSock(agentData, (e) => {
                if (e.cmd === 'device_restart' && e.status) {
                  this.$message.success('稍后球机自动重启！')
                } else {
                  this.$message.error(e.msg)
                }
              })
            }
            
            this.$emit("close");
          } else {
            this.$message.error(res.data.msg);
          }
        });
    },
  },
  mounted() {
    // this.$nextTick(() => {
    this.getDate();
    // })
  },
};
</script>

<style scoped>
.floor-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
::-webkit-scrollbar {
  display: none;
}

.table-scroll {
  height: 370px;
  overflow-y: scroll;
}

.table-footer {
  /* float: right;
    margin: 20px 0px 10px 0px; */
  display: flex;
  flex-direction: row-reverse;
}
</style>
