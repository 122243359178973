<template>
  <div>
    <div class="parentRec">
      <div class="loginRec">
        <div class="rRec">
          <div class="inpu">
            <div class="rRec01">
                正在进去平台，请稍等....
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="particles-js" style="display: flex;align-items: center;justify-content: center;    width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;">
    </div>
  </div>
</template>

<script>
  import particlesJS from '../../../public/static/particles.js'

  import base from '../../Base64.js'
  import Cookie from 'js-cookie'
  export default {
    components: {
    },
    data() {
      return {
        isLogin: false,
        isShow: false,
        isShow01: false,
        isRemember: false,
        userName: '',
        password: '',
        auth: '666666',
        hint: '请输入字母、数字、下划线,长度不能少于2',
        passHint: '管理员密码长度应大于6',
        codeShow: false
      }
    },
    methods: {
      // 登录事件
      goHome() {
        const token = sessionStorage.getItem('AdminToken')
        console.log(token, 'state123123123123213')
        this.axios.post("/api/system/web/user/tkWebDoLogin",{ token }).then((res) => {
          console.log(res,'api/system/web/user/tkWebDoLogin');
          
          if (res.data.success) {
            let agentData = {
              act: 'ma_login',
              token,
            }
            sessionStorage.setItem('adminInfo', JSON.stringify(res.data.data))
            sessionStorage.setItem('sosConfig', JSON.stringify(res.data.data.sosConfig))
            this.$router.push('/dashboard')
            // this.socketApi.initWebSocket()
            // this.socketApi.sendSock(agentData, (e) => {
            //   if (e.cmd == 'ma_login' && e.status) {
            //     console.log('ma_login', e)
            //     sessionStorage.setItem(
            //       'adminInfo',
            //       JSON.stringify(res.data.data)
            //     )
            //     sessionStorage.setItem('me_id', e.data)
            //     sessionStorage.setItem('sosConfig', JSON.stringify(res.data.data.sosConfig))
            //     this.$router.push('/dashboard')
            //     this.$message.success('登录成功')
            //   } else {
            //     // this.$router.push('/dashboard')
            //     // this.isLogin = false
            //     // this.$message.error(e.msg)
            //   }
            // })
            // 安全帽
            // this.socketCapsApi.initCapsSocket()
            // this.socketCapsApi.sendCapsSock(agentData, (e) => {
            //   if (e.cmd == 'ma_login' && e.status) {
            //     console.log('e', e)
            //   } else {
            //     this.$router.push('/dashboard')
            //     // this.isLogin = false
            //     // this.$message.error(e.msg)
            //   }
            // })
          }
        });
      },
    },
    mounted() {
      this.$nextTick(() => {
        openLz();
      });
      window.addEventListener('keydown', this.keyDown)
    },
    destroyed() {
      window.removeEventListener('keydown', this.keyDown, false)
    },
    created() {
      if (this.$store.state.isZx) {
        document.title = '锦地梯控物联网监管系统'
        var link =
          document.querySelector("link[rel*='icon']") ||
          document.createElement("link");
        link.type = "image/x-icon";
        link.rel = "shortcut icon";
        link.href = require('@/assets/zhongxin.png')
        document.getElementsByTagName("head")[0].appendChild(link);
      }
      console.log('loginWait');
      this.goHome()
    },
  }
</script>
<style lang="scss" scoped>
  .yzm {
    width: 20%;
    height: 100%;
    font-size: 12px;
    color: wheat;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ts {
    font-size: 12px;
    color: red;
    width: 85%;
    height: 40%;
    // border-top: 2px solid #ff0000;
    text-align: left;
    display: block;
    margin-left: 10%;
    margin-top: 1%;
  }

  .tsno {
    width: 85%;
    height: 40%;
    display: none;
  }

  .inpdiv {
    width: 100%;
    height: 20%;
    display: flex;
    flex-direction: column;
  }

  .rinps {
    width: 100%;
    height: 70%;
    color: white;
    background-color: #1282a5;
    /* background: url('../../assets/loginBG.png'); */
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .rinp {
    width: 100%;
    height: 70%;
    color: white;
    // background-color: #1282a5;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .rinpSpace {
    justify-content: space-between;
  }

  .rRec01 {
    width: 100%;
    height: 15%;
    font-size: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .rRec02 {
    width: 100%;
    height: 60%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
  }

  .rRec03 {
    width: 100%;
    height: 20%;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .inpu {
    width: 76%;
    height: 80%;
    display: flex;
    align-items: center;
  }

  .rRec {
    width: 50%;
    height: 100%;
    /* background-color: #1282a5; */
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    background-color: rgba(255, 255, 255, 0.1);
    background-size: 100% 100%;
    backdrop-filter: blur(10px);
    border-radius: 15px;

    /deep/.el-input__inner {
      background-color: #fff !important;
      color: #000 !important;
    }
  }

  .loginRec {
    width: 1000px;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }

  .parentRec {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url(../../assets/loginImg.png);
    background-size: 100% 100%;
  }
</style>

<style scoped>
  .rinps>>>.el-input__inner {
    border: none !important;
    outline: none !important;
    margin-left: 20px;
  }

  .rinp>>>.el-input__inner {
    width: 94% !important;
    height: 38px !important;
    border: none !important;
    outline: none !important;
    margin-left: 20px;
  }

  .rinp>>>.el-input .el-button {
    color: #FFF;
    background-color: #409EFF;
    border-color: #409EFF;
  }

  .rinp>>>.el-input__suffix {
    right: 15px;
  }

  .rinp>>>.el-input__icon {
    line-height: 32px;
  }

  .rRec03>>>.el-button {
    width: 100% !important;
    height: 60% !important;
  }
</style>