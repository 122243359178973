<template>
  <div>
    <el-row>
      <el-col
        :span="24"
        style="margin-bottom: 30px"
      >
        <div>
          <el-date-picker
            style="margin-left: 20px"
            @change="swchange"
            v-model="getTime"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </div>
        <!-- 统计图表 -->
        <Line-Chart
          v-if="chartStatus"
          :overDay="overDay"
          :sos-line-data="sosLineData"
          :height="'300px'"
        />
      </el-col>
    </el-row>
    <el-row>
      <h3 style="margin-top:0;color:#fff">电梯数据（{{sosLineData.date[0]}} - {{sosLineData.date[sosLineData.date.length - 1]}}）</h3>
      <el-descriptions
        class="margin-top"
        :column="4"
        border
      >
        <el-descriptions-item
          label="运行次数"
          label-class-name="myLabel"
        >{{ statisticsInfo.runningNum }}次</el-descriptions-item>
        <el-descriptions-item
          label="运行里程"
          label-class-name="myLabel"
        >{{ statisticsInfo.distance > 1000 ? `${(statisticsInfo.distance / 1000).toFixed(2)} km` : `${statisticsInfo.distance} m` }}</el-descriptions-item>
        <el-descriptions-item
          label="运行时间"
          label-class-name="myLabel"
        >{{ statisticsInfo.runningTime }}</el-descriptions-item>
        <el-descriptions-item
          label="震动次数"
          label-class-name="myLabel"
        >{{ statisticsInfo.shakeNum }}次</el-descriptions-item>
        <el-descriptions-item
          label="主动报警"
          label-class-name="myLabel"
        >{{ statisticsInfo.autoSos }}次</el-descriptions-item>
        <el-descriptions-item
          label="语音报警"
          label-class-name="myLabel"
        >{{ statisticsInfo.audioSos }}次</el-descriptions-item>
        <el-descriptions-item
          label="安全回路报警"
          label-class-name="myLabel"
        >{{ statisticsInfo.security }}次</el-descriptions-item>
        <el-descriptions-item
          label="困人报警"
          label-class-name="myLabel"
        >{{ statisticsInfo.stuckSos }}次</el-descriptions-item>
        <el-descriptions-item
          label="开关门次数"
          label-class-name="myLabel"
        >{{ statisticsInfo.openDoorNum }}次</el-descriptions-item>

      </el-descriptions>
    </el-row>
    <el-row>
      <h3 style="color:#fff">电梯累计数据</h3>
      <el-descriptions
        class="margin-top"
        :column="4"
        border
      >
        <el-descriptions-item
          label="总运行次数"
          label-class-name="myLabel"
        >{{ statisticsInfo.runningNumTotal }}次</el-descriptions-item>
        <el-descriptions-item
          label="总运行里程"
          label-class-name="myLabel"
        >
        {{ statisticsInfo.distanceTotal > 1000 ? `${(statisticsInfo.distanceTotal / 1000).toFixed(2)} km` : `${statisticsInfo.distanceTotal} m` }}</el-descriptions-item>
        <el-descriptions-item
          label="总运行时长"
          label-class-name="myLabel"
        >{{ statisticsInfo.runningTimeTotal }}</el-descriptions-item>
        <el-descriptions-item
          label="总在线时长"
          label-class-name="myLabel"
        >{{ statisticsInfo.onlineTimeTotal }}</el-descriptions-item>

      </el-descriptions>
    </el-row>
  </div>
</template>

<script>
import LineChart from './lineChart'
let getTimeFif = [ new Date( new Date(new Date().toLocaleDateString()).getTime() - 15 * 24 * 3600 * 1000).getTime(),
        new Date( new Date(new Date().toLocaleDateString()).getTime() - 1000).getTime()]
let getTimeNin = [ new Date( new Date(new Date().toLocaleDateString()).getTime() - 90 * 24 * 3600 * 1000).getTime(),
        new Date( new Date(new Date().toLocaleDateString()).getTime() - 1000).getTime()]
export default {
  components: { LineChart },

  props: {
    elevatorId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      visible: false,
      loading: true,
      chartStatus: false,
      // 默认选择最近一周的时间戳
      getTime: [
        // new Date(
        //   new Date(new Date().toLocaleDateString()).getTime() -
        //     15 * 24 * 3600 * 1000
        // ).getTime(),
        // new Date(
        //   new Date(new Date().toLocaleDateString()).getTime() - 1000
        // ).getTime(),
      ],
      getOldTime: [
        new Date(
          new Date(new Date().toLocaleDateString()).getTime() -
            15 * 24 * 3600 * 1000
        ).getTime(),
        new Date(
          new Date(new Date().toLocaleDateString()).getTime() - 1000
        ).getTime(),
      ],
      statisticsInfo: {}, // 运行详情
      sosLineData: {
        runningNum: [], // 运行次数
        runningTime: [], // 运行时长
        distance: [], // 运行距离
        date: [], // 日期
      },

      overDay: false,
      overDayNum: 90,
    }
  },
  mounted() {},
  methods: {
    getMainRecentDays() {
      this.$http
        .get(`/api/ele/web/runningNum/getMainRecentDays?eleId=${this.elevatorId}`)
        .then((res) => {
          if (res.data.success) {
            const {
              data: { data },
            } = res
            console.log(data,'asdasd')
            this.overDay = data < 90 
            this.overDayNum = this.overDay ? 90 : 15
            this.getTime = this.overDay ? getTimeNin : getTimeFif
            this.getRunStatistical()
          }
        })
    },
    getRunStatistical() {
      console.log(this.overDay,'123123121321321');
      this.sosLineData = {
        runningNum: [], // 运行次数
        runningTime: [], // 运行时长
        distance: [], // 运行距离
        date: [], // 日期
      }
      this.chartStatus = false
      const params = {
        eleId: this.elevatorId,
        startTime: this.formatDate(this.getTime[0]),
        endTime: this.formatDate(this.getTime[1]),
      }
      console.log(params, 'params')
      this.loading = true
      this.$http
        .post('/api/ele/web/runningNum/getRunStatistical', params)
        .then((res) => {
          if (res.data.success) {
            const {
              data: { data },
            } = res
            console.log(res)
            this.statisticsInfo = data.eleInfo
            console.log(data.topData, 'data.topData')
            data.topData.map((item) => {
              // 运行次数、运行时长、运行距离
              this.sosLineData.runningNum.push(item.runningNum)
              this.sosLineData.runningTime.push(item.runningTime)
              this.sosLineData.distance.push(item.distance)
              this.sosLineData.date.push(item.date)
            })
            this.loading = false
            this.chartStatus = true
          }
        })
    },
    // 搜索时间
    swchange() {
      console.log('getTime', this.getTime)
      const dateNum = Math.ceil(
        (this.getTime[1].getTime() - this.getTime[0].getTime() + 24 * 3600 * 1000 - 1000) /
          1000 /
          24 /
          60 /
          60
      )
      console.log('getTime', (this.getTime[1].getTime() - this.getTime[0].getTime()) / 1000 / 24 / 60 / 60)
      console.log('dateNum', dateNum)
      if (dateNum !== this.overDayNum) {
        this.$message.error(`查询时间${dateNum > this.overDayNum ? '最大' : '最小'}是${this.overDayNum}天`)

        this.getTime = this.getOldTime
        return
      }
      this.getRunStatistical()
    },
    // 转换时间
    formatDate(d) {
      var date = new Date(d)
      var YY = date.getFullYear() + '-'
      var MM =
        (date.getMonth() + 1 < 10
          ? '0' + (date.getMonth() + 1)
          : date.getMonth() + 1) + '-'
      var DD = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
      return YY + MM + DD
    },
  },
}
</script>
<style lang="scss" scoped>
.grid-hand {
  margin: 8px 0;
}
/deep/.el-descriptions-item__label.is-bordered-label {
  background: #1282a5 !important;
  text-align: center;
  width: 135px;
}
/deep/.el-descriptions-item__content {
  width: 200px;
}
</style>
