<template>
	<div :class="{'hidden':hidden}" class="pagination-container">
		<el-pagination :background="background" :current-page.sync="currentPage" :page-size.sync="pageSize"
			:layout="layout" :page-sizes="pageSizes" :pager-count="pagerCount" :total="total" v-bind="$attrs"
			@size-change="handleSizeChange" @current-change="handleCurrentChange" />
		<i class="el-icon-refresh refresh-button" @click="handleRefreshClick" />
	</div>
</template>

<script>
	export default {
		name: 'Pagination',
		props: {
			total: {
				required: true,
				type: Number
			},
			pagerCount: {
				type: Number,
				default: 5
			},
			page: {
				type: Number,
				default: 1
			},
			limit: {
				type: Number,
				default: 20
			},
			pageSizes: {
				type: Array,
				default () {
					return [10, 20, 30, 50]
				}
			},
			layout: {
				type: String,
				default: 'total, sizes, prev, pager, next, jumper'
			},
			background: {
				type: Boolean,
				default: true
			},
			autoScroll: {
				type: Boolean,
				default: true
			},
			hidden: {
				type: Boolean,
				default: false
			}
		},
		watch: {
			currentPage(newData, oldData) {
				this.$emit('pagination', {
					page: this.page,
					limit: this.pageSize
				})
			}

		},
		computed: {
			currentPage: {
				get() {
					return this.page
				},
				set(val) {
					this.$emit('update:page', val)
				}
			},
			pageSize: {
				get() {
					return this.limit
				},
				set(val) {
					this.$emit('update:limit', val)
				}
			}
		},
		methods: {
			handleSizeChange(val) {
				this.$emit('pagination', {
					page: this.currentPage,
					limit: val
				})
			},
			handleCurrentChange(val) {
				// this.$emit('pagination', {
				// 	page: val,
				// 	limit: this.pageSize
				// })
			},
			handleRefreshClick(e) {
				this.$emit('pagination', {
					page: this.page,
					limit: this.pageSize
				})
			}
		}
	}
</script>

<style scoped>
	.pagination-container {
		padding: 8px 0 4px 16px;
		display: flex;
		position: relative;
		justify-content: right;
	}

	.pagination-container.hidden {
		display: none;
	}

	.refresh-button {
		float: left;
		height: 100%;
		width: 50px;
		text-align: center;
		height: 32px;
		line-height: 32px;
		padding: 0px;
		margin: 0;
	}

	.refresh-button:hover {
		opacity: 0.8;
	}

	.refresh-button:active {
		color: #409EFF;
	}
</style>
