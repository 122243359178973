<template>
  <el-dialog
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    append-to-body
    width="1400px"
    top="8vh"
    @close="closeDialog"
  >
    <el-tabs
      style="margin-top: 10px;"
      v-model="activeName"
      lazy
      @tab-click="tabClick"
    >
      <el-tab-pane
        label="电梯监控"
        name="dtjk"
      >
        <div
          style="height: 70vh; overflow-y: scroll; padding-right: 20px"
          class="scrollElement"
        >
          <eleMonitoring
            v-if="activeName=='dtjk'"
            :eleInfo="eleInfo"
            :elevatorId="elevatorId"
            :deviceCode="deviceCode"
          />
        </div>
      </el-tab-pane>
      <el-tab-pane
        label="运行统计"
        name="yxtj"
        v-if="deviceCode"
      >
        <div
          style="height: 60vh; overflow-y: scroll; padding-right: 20px"
          class="scrollElement"
        >
          <Statistics
            ref="Statistics"
            v-if="activeName=='yxtj'"
            :elevatorId="elevatorId"
          />
        </div>
      </el-tab-pane>
      <template v-if="windowHost">
        <el-tab-pane
          v-if="(adminInfo.userName == 'admin' || adminInfo.userName == 'zxszjs' || adminInfo.userName == 'ruixing') && deviceCode"
          label="上下线记录"
          name="sxxjl"
        >
          <div
            style="height: 60vh; overflow-y: scroll; padding-right: 20px"
            class="scrollElement"
          >
            <OnoffList
              ref="OnoffList"
              v-if="activeName=='sxxjl'"
              :elevatorId="elevatorId"
              :deviceCode="deviceCode"
            />
          </div>
        </el-tab-pane>
      </template>
      <template v-else>
        <el-tab-pane
          v-if="(adminInfo.userName == 'admin' || adminInfo.userName == 'zxszjs') && deviceCode"
          label="上下线记录"
          name="sxxjl"
        >
          <div
            style="height: 60vh; overflow-y: scroll; padding-right: 20px"
            class="scrollElement"
          >
            <OnoffList
              ref="OnoffList"
              v-if="activeName=='sxxjl'"
              :elevatorId="elevatorId"
              :deviceCode="deviceCode"
            />
          </div>
        </el-tab-pane>
      </template>
      <el-tab-pane
        label="维保信息"
        name="wbxx"
      >
        <div
          style="height: 60vh; overflow-y: scroll; padding-right: 20px"
          class="scrollElement"
        >
          <MaintenanceInfo
            ref="MaintenanceInfo"
            v-if="activeName=='wbxx'"
            :elevatorId="elevatorId"
            :deviceCode="deviceCode"
          />
        </div>
      </el-tab-pane>
      <el-tab-pane
        label="报警信息"
        name="bjxx"
      >
        <div
          style="height: 60vh; overflow-y: scroll; padding-right: 20px"
          class="scrollElement"
        >
          <SosInfo
            ref="SosInfo"
            v-if="activeName=='bjxx'"
            :elevatorId="elevatorId"
            :deviceCode="deviceCode"
          />
        </div>
      </el-tab-pane>
      <el-tab-pane
        label="云端视频"
        name="splb"
        v-if="deviceCode"
      >
        <div
          style="height: 60vh; overflow-y: scroll; padding-right: 20px"
          class="scrollElement"
        >
          <MonitorVideo
            ref="videoList"
            v-if="activeName=='splb'"
            :elevatorId="elevatorId"
          />
        </div>
      </el-tab-pane>
      <el-tab-pane
        label="本地视频"
        name="scsp"
        v-if="deviceCode"
      >
        <div
          style="height: 60vh; overflow-y: scroll; padding-right: 20px"
          class="scrollElement"
        >
          <UploadVideo
            ref="UploadVideo"
            v-if="activeName=='scsp'"
            :elevatorId="elevatorId"
            :deviceCode="deviceCode"
          />
        </div>
      </el-tab-pane>
      <el-tab-pane
        label="运行日志"
        name="yxrz"
        v-if="deviceCode"
      >
        <div
          style="height: 60vh; padding-right: 20px"
          class="scrollElement"
        >
          <RunLog
            ref="RunLog"
            v-if="activeName=='yxrz'"
            :deviceCode="deviceCode"
          />
        </div>
      </el-tab-pane>
      <el-tab-pane
        label="设备日志"
        name="sbrz"
        v-if="deviceCode && adminInfo.userName == 'admin'"
      >
        <div
          style="height: 60vh; overflow-y: scroll; padding-right: 20px"
          class="scrollElement"
        >
          <Equipment
            ref="Equipment"
            v-if="activeName=='sbrz'"
            :deviceCode="deviceCode"
            :elevatorId="elevatorId"
          />
        </div>
      </el-tab-pane>
    </el-tabs>

    <span
      slot="footer"
      class="dialog-footer"
    >
      <!-- <el-button size="small" @click="closeDialog">关 闭</el-button> -->
    </span>
  </el-dialog>
</template>

<script>
import EleMonitoring from './eleMonitoring' // 电梯监控
import Statistics from './statistics' // 运行统计
import OnoffList from './onoffList' // 上下线
import MaintenanceInfo from './maintenanceInfo' // 维保信息
import SosInfo from './sosInfo' // 报警信息
import MonitorVideo from './monitorVideo' // 电梯视频
import UploadVideo from './uploadVideo' // 上传视频
import RunLog from './runLog' // 运行日志
import Equipment from './equipment' // 设备日志

export default {
  components: {
    EleMonitoring,
    Statistics,
    OnoffList,
    MaintenanceInfo,
    SosInfo,
    MonitorVideo,
    UploadVideo,
    RunLog,
    Equipment,
  },
  data() {
    return {
      loading: true,
      dialogVisible: true,
      activeName: 'dtjk', // 默认第一屏
      adminInfo: JSON.parse(sessionStorage.getItem('adminInfo')),
      windowHost: window.location.hostname != 'localhost',
    }
  },
  props: {
    eleInfo: {
      type: Object,
      default: () => ({}),
    },
    elevatorId: {
      type: String,
      default: '',
    },
    activeNameProps: {
      type: String,
      default: 'dtjk',
    },
    deviceCode: {
      type: String,
      default: '',
    },
  },

  created() {
    this.getNotice(['ELEVATOR_TYPE'])
    this.activeName = this.activeNameProps
  },
  mounted() {
    if (this.activeName === 'scsp') {
      this.$nextTick(() => {
        this.$refs['UploadVideo'].searchElevator()
      })
    }
  },
  destroyed() {
    console.log('xiaohui')
  },

  methods: {
    tabClick() {
      switch (this.activeName) {
        case 'dtjk':
          break
        case 'yxtj':
          this.$nextTick(() => {
            this.$refs['Statistics'].getMainRecentDays()
            // this.$refs['Statistics'].getRunStatistical()
          })
          break
        case 'sxxjl':
          this.$nextTick(() => {
            this.$refs['OnoffList'].onRefer()
          })
          break
        case 'wbxx':
          this.$nextTick(() => {
            this.$refs['MaintenanceInfo'].onRefer()
          })
          break
        case 'bjxx':
          this.$nextTick(() => {
            this.$refs['SosInfo'].onRefer()
          })
          break
        case 'splb':
          this.$nextTick(() => {
            this.$refs['videoList'].getVideo()
          })
          break
        case 'scsp':
          this.$nextTick(() => {
            this.$refs['UploadVideo'].searchElevator()
          })
          break
        case 'yxrz':
          this.$nextTick(() => {
            this.$refs['RunLog'].getRunLogList()
          })
          break
        case 'sbrz':
          this.$nextTick(() => {
            this.$refs['Equipment'].getFileList('/')
          })
          break
        default:
          break
      }
    },

    // 获取字典
    getNotice(code) {
      this.$http
        .post(`/api/system/web/dict/dictionary?codes=${code}`)
        .then((res) => {
          console.log('字典', res)
          if (res.data.success) {
            const {
              data: { data },
            } = res
            this.eleType = data.ELEVATOR_TYPE
          } else {
            this.$message.error(res.data.msg)
          }
        })
    },

    closeDialog() {
      this.dialogVisible = false
      this.activeName = ''
      this.$emit('closeMonitor')
    },
  },
}
</script>
<style lang="scss" scoped>
/deep/.el-tabs__item,
.el-radio,
.el-checkbox {
  color: #fff;
}

/deep/.el-tabs__item {
  width: 120px;
  text-align: center;
  padding-left: 0;
}
</style>
