<template>
  <div
    :class="className"
    :style="{width:width, height:height}"
  />
</template>

<script>
import echarts from 'echarts'

export default {
  props: {
    className: {
      type: String,
      default: 'chart',
    },
    width: {
      type: String,
      default: '100%',
    },
    height: {
      type: String,
      // default: 'calc(100% - 30px)'
      default: '300px',
    },
    autoResize: {
      type: Boolean,
      default: true,
    },
    sosLineData: {
      type: Object,
      default: () => {
        return {
          runningNum: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,0, 0, 0, 0, 0, 0, 0, 0, 0,0, 0, 0, 0, 0, 0, 0, 0, 0,0, 0, 0, 0, 0, 0, 0, 0, 0,0, 0, 0, 0, 0, 0, 0, 0, 0,0, 0, 0, 0, 0, 0, 0, 0, 0,0, 0, 0, 0, 0, 0, 0, 0, 0,0, 0, 0, 0, 0, 0, 0, 0, 0,0, 0, 0, 0, 0, 0, 0, 0, 0,0, 0, 0, 0, 0, 0, 0, 0, 0], // 运行次数
          runningTime: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,0, 0, 0, 0, 0, 0, 0, 0, 0,0, 0, 0, 0, 0, 0, 0, 0, 0,0, 0, 0, 0, 0, 0, 0, 0, 0,0, 0, 0, 0, 0, 0, 0, 0, 0,0, 0, 0, 0, 0, 0, 0, 0, 0,0, 0, 0, 0, 0, 0, 0, 0, 0,0, 0, 0, 0, 0, 0, 0, 0, 0,0, 0, 0, 0, 0, 0, 0, 0, 0,0, 0, 0, 0, 0, 0, 0, 0, 0], // 运行时间
          distance: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,0, 0, 0, 0, 0, 0, 0, 0, 0,0, 0, 0, 0, 0, 0, 0, 0, 0,0, 0, 0, 0, 0, 0, 0, 0, 0,0, 0, 0, 0, 0, 0, 0, 0, 0,0, 0, 0, 0, 0, 0, 0, 0, 0,0, 0, 0, 0, 0, 0, 0, 0, 0,0, 0, 0, 0, 0, 0, 0, 0, 0,0, 0, 0, 0, 0, 0, 0, 0, 0,0, 0, 0, 0, 0, 0, 0, 0, 0], // 运行距离
          date: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,0, 0, 0, 0, 0, 0, 0, 0, 0,0, 0, 0, 0, 0, 0, 0, 0, 0,0, 0, 0, 0, 0, 0, 0, 0, 0,0, 0, 0, 0, 0, 0, 0, 0, 0,0, 0, 0, 0, 0, 0, 0, 0, 0,0, 0, 0, 0, 0, 0, 0, 0, 0,0, 0, 0, 0, 0, 0, 0, 0, 0,0, 0, 0, 0, 0, 0, 0, 0, 0,0, 0, 0, 0, 0, 0, 0, 0, 0], // 日期
        }
      },
    },
    overDay: {
      type: Boolean,
      default: true,
    }
  },

  data() {
    return {
      chart: null,
    }
  },

  // 监听data里面对应的
  watch: {
    data: {
      deep: true,
      handler(val) {
        this.setOptions(val)
      },
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.initChart()
    })
  },

  beforeDestroy() {
    if (!this.chart) return
    // 释放图表实例
    this.chart.dispose()
    this.chart = null
  },

  methods: {
    initChart() {
      this.chart = this.$echarts.init(this.$el)
      this.setOptions(this.sosLineData)
    },
    setOptions(data) {
      const { date, runningNum, runningTime, distance } = data
      console.log(data, 'running_num')

      const options = {
        tooltip: {
          trigger: 'axis',
          // 在这里设置
          formatter: (params) => {
            let value = ``
            let type = ''
            let time = ''
            params.map((item) => {
              switch (item.seriesName) {
                case '运行次数':
                  type = '次'
                  break
                case '运行时间':
                  type = 's'
                  break
                case '运行里程':
                  type = 'm'
                  break

                default:
                  break
              }
              time = item.name
              value += `${item.seriesName}：${item.value + type} <br>`
            })
            return `${time}<br>${value}`
          },
        },
        textStyle: {
          color: '#fff', // 所有文字的颜色
        },
        legend: {
          right: '30px',
          top: '1%',
          bottom: '10px',
          data: ['运行次数', '运行时间', '运行里程'],
          textStyle: {
            color: '#409EFF',
          },
        },
        grid: {
          left: '6%',
          right: '30px',
          top: '12%',
          bottom: '20%',
          containLabel: false,
          borderColor: '#fff',
        },
        xAxis: {
          type: 'category',
          show: true,
          data: date,
          boundaryGap: false,
          splitLine: { show: false }, // 去除网格线,
          axisLine: {
            lineStyle: {
              color: '#4dc1ed', // 坐标轴颜色
            },
          },
          axisLabel: {
            interval: this.overDay ? 10 : 2,
            rotate: 0,
            textStyle: {
              fontSize: 14,
            },
          },
          
          axisTick: {
            show: false,
          },
        },
        yAxis: {
          type: 'value',
          name: '',
          show: true,
          axisLine: {
            lineStyle: {
              color: '#4dc1ed',
            },
          },
          axisTick: {
            show: false,
          },
        },
        series: [
          {
            name: `运行次数`,
            type: 'line',
            barWidth: '30%',
            data: runningNum,
            itemStyle: {
              normal: {
                color: '#75147B',
                barBorderRadius: [0, 7, 7, 0],
              },
            },
            lineStyle: {
              smooth: true,
            },
          },
          {
            name: `运行时间`,
            type: 'line',
            barWidth: '30%',
            data: runningTime,
            itemStyle: {
              normal: {
                color: '#EB4334',
                barBorderRadius: [0, 7, 7, 0],
              },
            },
            lineStyle: {
              smooth: true,
            },
          },
          {
            name: `运行里程`,
            type: 'line',
            barWidth: '30%',
            data: distance,
            itemStyle: {
              normal: {
                color: '#55A7B1',
                barBorderRadius: [0, 7, 7, 0],
              },
            },
            lineStyle: {
              smooth: true,
            },
          },
        ],
      }
      this.chart.setOption(options)
    },
  },
}
</script>
