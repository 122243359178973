<template>
  <div>
    <div class="dtBox1" v-if="deviceCode">
      <div class="supervisoryBox">
        <template v-if="dioData.DeviceData && !dioData.DeviceData.isOnline">
          <div
            style="width: 100%;height:100%;margin:auto;background:#000;display:flex;align-items: center;justify-content: center;">
            <el-image fit="contain" :src="require('@/assets/notOnline.png')" style="width: 25%;" />
            <div style="color:#fff;width: 55%;margin-left: 5%;font-size:16px">
              <h4>离线时间：{{dioData.lastLogoutTime ? new Date(parseInt(dioData.lastLogoutTime)).toLocaleString() : ''}}
              </h4>
              <span>1、 登录App或后台网页，查看球机离线前的最后状态：</br>
                A、若显示低电量，建议安排现场检查电源情况；</br>
                B、若信号很弱或重启后又离线的，建议现场实测信号情况以及球机天线两头有没有拧紧，并进行重启观察；</br>
                2、登录网页后台“电梯运行监测”列表，检查球机是否有具体“激活时间”，若未曾激活上线过，建议现场重启后观察；</br>
                3、登录app或后台网页，查看电梯资料AI摄像头ID和设备ID号码是否一致；</br>
                4、如以上均无法解决，可在App内发起"帮助与反馈"，或者寻求管理员帮助。</span>
            </div>
          </div>
        </template>
        <acContainer v-else :eleInfo="eleInfo" :dioData="dioData" :url='url' />

      </div>
      <div :class="!lowIsDatail ? 'wid5' : 'wid15'">
        <div v-if="!lowIsDatail" @click="showDetail">
          <h4 style=" margin: 0;">顶层：{{lowTop}}</h4>
          <div style="height: 405px;padding:10px 0">
            <template v-if="lowInfo && lowInfo.length > 0">
              <div v-for="(item, index) in lowInfo" class="lowInfo" :style="{height: uploadStyle}" :key="index">
                <div class="lowBg" :class="lowNow == item.floor ? 'newLowBg' : ''">
                </div>
                <div style="width:40px;" v-if="lowNow == item.floor">{{item.name ? item.name : `${item.floor}`}}</div>
                <div style="width:40px;" v-else></div>
              </div>
            </template>
            <div v-else class="lowInfo" style="height: 100%; justify-content: flex-start;">
              <div class="lowBg">
              </div>
            </div>
          </div>
          <h4 style="margin: 0;">底层：{{lowBottom}}</h4>
        </div>
        <div v-else class="lowInfoBox">
          <div class="lowClose">
            <el-button type="text" icon="el-icon-close" @click="showDetail" />
          </div>
          <div class="scrollElement" style="height: 420px;overflow-y: scroll;padding:10px 5px">
            <template v-if="lowInfo && lowInfo.length > 0">
              <div style="height: 30px;display: flex;">
                <div style="width:60px;">层高(m)</div>
                <div style="width:80px;margin: 0 5px;">楼层(别名)</div>
                <div style="width:60px;">海拔(m)</div>
              </div>
              <div v-for="(item, index) in lowInfo" class="lowInfo" style="height: 30px;align-items: flex-start;"
                :key="index">
                <div style="width:60px;margin-top: -10px;text-align: center;">{{ index != 0 ? item.heightMap : ''}}
                </div>
                <div class="lowBg infoBg" :class="lowNow == item.floor ? 'newLowBg' : ''">
                  {{`${item.floor}`}}{{item.name ? `(${item.name})` : ''}}
                </div>
                <div style="width:60px;margin-top: 10px;text-align: center;">{{item.height}}</div>
              </div>
            </template>
            <template v-else>
              <div style="height: 30px;display: flex;">
                <div style="width:60px;">层高(m)</div>
                <div style="width:80px;margin: 0 5px;">楼层(别名)</div>
                <div style="width:60px;">海拔m</div>
              </div>
              <div class="lowInfo" style="height: 90%; justify-content: space-around;">
                <div class="lowBg">
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
      <div class="lift-sequence" :class="!lowIsDatail ? 'wid40' : 'wid30'">
        <!-- {{floorId}} -->
        <FloorPage v-if="floorVisible" @close='closeInfo' :elevatorId="elevatorId" :deviceCode="eleInfo.deviceCode" :floorId="floorId" />
        <div v-else>
          <div class="floor-check" v-if="adminInfo.userName == 'admin' && this.dioData.checkFloor == true">
            <span>有新的楼层学习记录，点击（查看）进行校验证</span>
            <el-button type="text" style="margin-top: -9px;" @click="checkInfo">查看</el-button>
          </div>
          <div class="lift-sequenceTop">
            <h3 style="margin: 0; width: 80%">电梯名称: {{dioData.name}} </h3>
            <div>
              <el-image v-if="dioData.DeviceData && dioData.DeviceData.isOnline !== ''" :fit="'cover'"
                :src="dioData.DeviceData && dioData.DeviceData.isOnline ? require('@/assets/monitor/qiuji1.png') : require('@/assets/monitor/qiuji0.png')"
                style="width:25px;height:25px" />
              <el-image v-if="dioData.terminalData && dioData.terminalData.isOnline !== ''" :fit="'cover'"
                :src="dioData.terminalData && dioData.terminalData.isOnline ?require('@/assets/monitor/hezi1.png') : require('@/assets/monitor/hezi0.png')"
                style="width:25px;height:25px" />
            </div>
          </div>
          <div>
            <el-row style="margin-top:15px">
              <el-col :span="8" class="parameter">
                <div style="position: relative">
                  <el-image :src="require('@/assets/round.png')" fit="cover" style="width:90px; height:90px" />
                  <h4 style="position: absolute;top: 36px;font-size: 18px;width: 100%;margin: 0;">
                    {{ dioData.DeviceData ? (
									dioData.DeviceData.textFloorAlias ? (
									JSON.parse(dioData.DeviceData.textFloorAlias).data.length > 0 ? (
										JSON.parse(dioData.DeviceData.textFloorAlias).data.find(item => item.floor == dioData.DeviceData.floor) ?
										(JSON.parse(dioData.DeviceData.textFloorAlias).data.find(item => item.floor == dioData.DeviceData.floor).name !== '' ?
											JSON.parse(dioData.DeviceData.textFloorAlias).data.find(item => item.floor == dioData.DeviceData.floor).name : dioData.DeviceData.floor
										) : '无')
									: '无' ) : (
									dioData.DeviceData.floor ? dioData.DeviceData.floor : '-')
									): '' }}
                  </h4>
                </div>
                <h4 class="sequenceTitle">当前楼层</h4>
                <!-- <h4>供电模式</h4> -->
              </el-col>
              <el-col :span="8" class="parameter">
                <div style="position: relative">
                  <!-- <canvas canvas-id="mySpeed" id="mySpeed"
									style="width: 100%;position: relative;z-index: 10; top:1px" />
								<el-progress v-if="dioData.DeviceData" type="circle" :percentage="0" :width="74"
									:show-text="false" style="position: absolute;left: 34px;z-index: 0;" /> -->

                  <el-image :src="require('@/assets/round.png')" fit="cover" style="width:90px; height:90px" />
                  <h4 style="position: absolute;top: 36px;font-size: 18px;width: 100%;margin: 0;">
                    {{dioData.DeviceData | showTitle(dioData.DeviceData.liftSpeed) | showType('m/s')}}
                  </h4>
                </div>
                <h4 class="sequenceTitle">运行速度</h4>
              </el-col>
              <el-col :span="8" class="parameter">
                <div style="position: relative">
                  <!-- <canvas canvas-id="myMove" id="myMove"
									style="width: 100%;position: relative;z-index: 10; top:1px" />
									<el-progress v-if="dioData.DeviceData || dioData.DeviceData.runningNum === 0"
									type="circle" :percentage="0" :width="74" :show-text="false"
									style="position: absolute;left: 34px;z-index: 0;" /> -->
                  <el-image :src="require('@/assets/round.png')" fit="cover" style="width:90px; height:90px" />
                  <div style="position: absolute;top: 24px;width: 100%;">
                    <el-image v-if="dioData.DeviceData && dioData.DeviceData.liftSpeed == 0" fit="contain"
                      :src="require(`@/assets/monitor/static.png`)" style="width:45px;height:45px;" />
                    <el-image v-if="dioData.DeviceData && dioData.DeviceData.liftSpeed > 0" fit="contain"
                      :src="require(`@/assets/monitor/up.png`)" style="width:45px;height:45px;" />
                    <el-image v-if="dioData.DeviceData && dioData.DeviceData.liftSpeed < 0" fit="contain"
                      :src="require(`@/assets/monitor/down.png`)" style="width:45px;height:45px;" />
                  </div>
                </div>
                <h4 class="sequenceTitle">运行方向</h4>
              </el-col>
            </el-row>
            <el-row>

              <!-- 供电模式,运行次数,运行里程 -->
              <el-col :span="8" class="parameter">
                <div style="position: relative">
                  <el-image :src="require('@/assets/round.png')" fit="cover" style="width:90px; height:90px" />
                  <h4 style="position: absolute;top: 36px;font-size: 18px;width: 100%;margin: 0;">
                    {{dioData.DeviceData | showTitle(dioData.DeviceData.runningNum) | showType('number')}}
                  </h4>
                  <!-- <el-progress type="circle"
									:percentage="dioData.DeviceData.runningNum ? ((dioData.DeviceData.runningNum / 5000) > 1 ? 100 : (dioData.DeviceData.runningNum / 5000).toFixed(0) * 100 ) : 0"
									:width="74" :show-text="false"></el-progress>
								<span
									style="position: absolute;top: 29px;left: 25px;font-size: 14px;width: 50%;">{{dioData.DeviceData | showTitle(dioData.DeviceData.runningNum) | showType('number')}}
								</span> -->
                </div>
                <h4 class="sequenceTitle">运行次数</h4>
              </el-col>
              <el-col :span="8" class="parameter">
                <div style="position: relative">

                  <!-- <canvas canvas-id="myMileage" id="myMileage"
									style="width: 100%;position: relative;z-index: 10; top:1px" />
								<el-progress v-if="dioData.DeviceData" type="circle" :percentage="0" :width="74"
									:show-text="false" style="position: absolute;left: 34px;z-index: 0;" /> -->

                  <el-image :src="require('@/assets/round.png')" fit="cover" style="width:90px; height:90px" />
                  <h4 style="position: absolute;top: 36px;font-size: 18px;width: 100%;margin: 0;">
                    {{dioData.DeviceData.liftDistance || dioData.DeviceData.liftDistance === 0 ?
										(dioData.DeviceData.liftDistance > 1000 ?
										`${(dioData.DeviceData.liftDistance / 1000).toFixed(2)}km` :
										`${dioData.DeviceData.liftDistance}m`) : '-'
										}}
                  </h4>
                </div>
                <h4 class="sequenceTitle">运行里程</h4>
              </el-col>
              <el-col :span="8" class="parameter">
                <div style="position: relative">
                  <!-- <canvas canvas-id="myPeople" id="myPeople"
									style="width: 100%;position: relative;z-index: 10; top:1px" />
								<el-progress v-if="dioData.DeviceData" type="circle" :percentage="0" :width="74"
									:show-text="false" style="position: absolute;left: 34px;z-index: 0;" /> -->

                  <el-image :src="require('@/assets/round.png')" fit="cover" style="width:90px; height:90px" />
                  <div style="position: absolute;top: 22px;width: 100%;">
                    <el-image fit="contain"
                      :src="dioData.DeviceData && dioData.DeviceData.heads != 0 ? require(`@/assets/monitor/people.png`) : require(`@/assets/monitor/nopeople.png`)"
                      style="width:45px;height:45px;" />
                  </div>
                </div>
                <h4 class="sequenceTitle">是否有人</h4>
              </el-col>
            </el-row>
            <el-row>
              <!-- 门状态,折弯次数,震动频率 -->
              <el-col :span="8" class="parameter">
                <div style="position: relative">
                  <!-- <canvas canvas-id="myBend" id="myBend"
									style="width: 100%;position: relative;z-index: 10; top:1px" />
								<el-progress type="circle" :percentage="0" :width="74" :show-text="false"
									style="position: absolute;left: 34px;z-index: 0;"></el-progress> -->

                  <el-image :src="require('@/assets/round.png')" fit="cover" style="width:90px; height:90px" />
                  <h4 style="position: absolute;top: 36px;font-size: 18px;width: 100%;margin: 0;">
                    <span v-if="dioData.DeviceData.bendNum">{{dioData.DeviceData.bendNum}}次</span>
                    <span v-else>-</span>
                  </h4>
                </div>
                <h4 class="sequenceTitle">折弯次数</h4>
              </el-col>
              <el-col :span="8" class="parameter">
                <div style="position: relative">
                  <!-- <canvas canvas-id="myShock" id="myShock"
									style="width: 100%;position: relative;z-index: 10; top:1px" />
								<el-progress v-if="dioData.DeviceData" type="circle" :percentage="0" :width="74"
									:show-text="false" style="position: absolute;left: 34px;z-index: 0;" />
								<span style="position: absolute;top: 24px;left: 38px;font-size: 14px;width: 50%;"> -->
                  <el-image :src="require('@/assets/round.png')" fit="cover" style="width:90px; height:90px" />
                  <h4 style="position: absolute;top: 36px;font-size: 18px;width: 100%;margin: 0;">
                    {{shake}}hz
                  </h4>
                  <!-- </span> -->
                </div>
                <h4 class="sequenceTitle">震动频率</h4>
              </el-col>
              <el-col :span="8" class="parameter">
                <div style="position: relative">
                  <!-- <canvas canvas-id="myDoor" id="myDoor"
									style="width: 100%;position: relative;z-index: 10; top:1px" />
								<el-progress v-if="dioData.DeviceData" type="circle" :percentage="0" :width="74"
									:show-text="false" style="position: absolute;left: 34px;z-index: 0;" /> -->
                  <el-image :src="require('@/assets/round.png')" fit="cover" style="width:90px; height:90px" />

                  <div style="position: absolute;top: 22px;width: 100%;">
                    <el-image fit="contain"
                      :src="dioData.DeviceData && dioData.DeviceData.doorOpenStatus === 1 ? require(`@/assets/monitor/open.png`) : require(`@/assets/monitor/close.png`)"
                      style="width:45px;height:45px;" />
                  </div>
                </div>
                <h4 class="sequenceTitle">门状态</h4>
              </el-col>

            </el-row>
          </div>
        </div>
        <!-- <el-progress type="circle" stroke-linecap="butt" :percentage="100" stroke-width="8"></el-progress>
        <el-progress type="circle" :percentage="50" stroke-width="8" status="exception" define-back-color="#20a0ff"></el-progress> -->

      </div>
    </div>
    <div v-else>
      <div class="lift-sequence" style="width: 100%;">
        <div class="lift-sequenceTop" style="width: 100%;justify-content: flex-start;">
          <h3 style="margin: 0;">电梯名称: {{dioData.name}} </h3>
          <div>
            <el-image v-if="dioData.terminalData && dioData.terminalData.isOnline !== ''" :fit="'cover'"
              :src="dioData.terminalData && dioData.terminalData.isOnline ?require('@/assets/monitor/hezi1.png') : require('@/assets/monitor/hezi0.png')"
              style="width:25px;height:25px" />
          </div>
        </div>
      </div>
    </div>

    <div class="dtBox2" v-if="eleInfo.deviceCode" style="margin-top: 20px;">
      <div style="display:flex;justify-content: space-between;align-items: center;">
        <h3 style="color:#fff">AI智能终端（{{eleInfo.deviceCode}}）</h3>
        <el-button type="text" @click="query">查询流量</el-button>
      </div>
      <el-descriptions class="margin-top" :column="4" border>
        <el-descriptions-item label="供电模式">
          {{dioData.DeviceData | showTitle(dioData.DeviceData.charging) | showType('charging')}}
        </el-descriptions-item>
        <el-descriptions-item label="信号强度">
          <el-image
            v-if="dioData.DeviceData && dioData.DeviceData.netStrenth && dioData.DeviceData.netStrenth.indexOf('L') > -1"
            fit="contain" :src="require(`@/assets/monitor/${dioData.DeviceData.netStrenth}.png`)"
            style="width:25px;height:25px" />
          <span v-else>-</span>
        </el-descriptions-item>

        <el-descriptions-item label="IP地址">
          {{dioData.DeviceData | showTitle(dioData.DeviceData.ethIp)}}
        </el-descriptions-item>
        <el-descriptions-item label="SIM卡号">
          {{dioData.DeviceData | showTitle(dioData.DeviceData.SIM)}}
        </el-descriptions-item>
        <el-descriptions-item label="心跳时间">
          {{ dioData.DeviceData ? (dioData.DeviceData.reportTime ? dioData.DeviceData.reportTime : '-') : '' }}
        </el-descriptions-item>
        <el-descriptions-item label="4G模块ID">
          {{dioData.DeviceData.imei4g}}
        </el-descriptions-item>
        <el-descriptions-item label="SD卡剩余容量">
          {{ dioData.DeviceData.isSdMounted == 1 ? `${dioData.DeviceData.sdAvailableSize}M` : 'SD卡已损坏'}}
        </el-descriptions-item>
        <el-descriptions-item label="本月流量">
          {{dioData.DeviceData.monthSimNum >= 0 ? `${dioData.DeviceData.monthSimNum} M` : '获取数据失败'}}
        </el-descriptions-item>
        <el-descriptions-item label="服务屏类型">
          {{ dioData.DeviceData.hdmiPType == 1 ? '双屏' : (dioData.DeviceData.hdmiPType == 0 ? '单屏' : '无' )}}
        </el-descriptions-item>
        <!-- <el-descriptions-item label="是否在开锁区域">
					{{dioData.DeviceData && dioData.DeviceData.liftSpeed == 0 ? '开锁区域' : '非开锁区域'}}
				</el-descriptions-item>
				<el-descriptions-item label="轿门是否关门到位">
					{{dioData.DeviceData && dioData.DeviceData.doorOpenStatus === 1 ? '否' : '是'}}
				</el-descriptions-item>
				<el-descriptions-item label="电梯状态">
					{{dioData.DeviceData && dioData.DeviceData.doorOpenStatus === 1 ? '否' : '是'}}
				</el-descriptions-item> -->
        <el-descriptions-item label=" " labelStyle="background: #04375e00 !important;border-right: none;"
          contentStyle="border-left: none;">
        </el-descriptions-item>
        <!-- <el-descriptions-item label="上平层">动作</el-descriptions-item> -->
        <!-- <el-descriptions-item label="下平层">动作</el-descriptions-item> -->
        <!-- <el-descriptions-item label="载重状态">
          {{dioData.DeviceData.heads >= 13 ? '超载' :
            dioData.DeviceData.heads == 12 ? '满载' :
              dioData.DeviceData.heads > 0 ? '半载' : '空载'
          }}
        </el-descriptions-item> -->
				<!-- !测试专用 -->
			</el-descriptions>
		</div>
		<div class="dtBox3" v-if="eleInfo.terminalId">
			<h3 style="color:#fff">电梯数字终端（{{eleInfo.terminalId}}）</h3>
			<el-descriptions class="margin-top" :column="5" border>
				<el-descriptions-item label="抱闸线圈" v-if="sosSwitch.brakeCoilSosSwitch">
					{{ getStatus(dioData.terminalData , dioData.terminalData.sos_103) }}
				</el-descriptions-item>
				<el-descriptions-item label="门锁回路" v-if="sosSwitch.doorLockSosSwitch">
					{{ getStatus(dioData.terminalData , dioData.terminalData.sos_102) }}
				</el-descriptions-item>
				<el-descriptions-item label="安全回路" v-if="sosSwitch.safetyCircuitSosSwitch">
					<template v-if="dioData.terminalData.sos_104">
						{{ getStatus(dioData.terminalData , dioData.terminalData.sos_104) }}
					</template>
					<template v-else>
						{{ getStatus(dioData.terminalData , dioData.terminalData.sos_110) }}
					</template>
				</el-descriptions-item>
				<el-descriptions-item label="厅门回路" v-if="sosSwitch.hallDoorCircuitSosSwitch">
					{{ getStatus(dioData.terminalData , dioData.terminalData.sos_101) }}
				</el-descriptions-item>
				<el-descriptions-item label="关门极限" v-if="sosSwitch.closeTheDoorLimitSosSwitch">
					{{ getStatus(dioData.terminalData , dioData.terminalData.sos_108) }}
				</el-descriptions-item>
				<el-descriptions-item label="开门极限" v-if="sosSwitch.openTheDoorLimitSosSwitch">
					{{ getStatus(dioData.terminalData , dioData.terminalData.sos_107) }}
				</el-descriptions-item>
				<el-descriptions-item label="意外移动" v-if="sosSwitch.surpriseMoveSosSwitch">
					{{ getStatus(dioData.terminalData , dioData.terminalData.sos_109) }}
				</el-descriptions-item>
				<el-descriptions-item label="蹲底" v-if="sosSwitch.crouchBottomSosSwitch">
					{{ getStatus(dioData.terminalData , dioData.terminalData.sos_106) }}
				</el-descriptions-item>
				<el-descriptions-item label="冲顶" v-if="sosSwitch.diveHeaderSosSwitch">
					{{ getStatus(dioData.terminalData , dioData.terminalData.sos_105) }}
				</el-descriptions-item>
				<el-descriptions-item label="上行减速" v-if="sosSwitch.upsideToSlowDownSosSwitch">
					{{ getStatus(dioData.terminalData , dioData.terminalData.sos_113) }}
				</el-descriptions-item>
				<el-descriptions-item label="下行减速" v-if="sosSwitch.downwardDecelerationSosSwitch">
					{{ getStatus(dioData.terminalData , dioData.terminalData.sos_114) }}
				</el-descriptions-item>
				<el-descriptions-item label="锁梯" v-if="sosSwitch.lockTheLadderSosSwitch">
					{{ getStatus(dioData.terminalData , dioData.terminalData.sos_112) }}
				</el-descriptions-item>
				<el-descriptions-item label="检修" v-if="sosSwitch.maintenanceSosSwitch">
					{{ getStatus(dioData.terminalData , dioData.terminalData.sos_111) }}
				</el-descriptions-item>
			<!-- R3+ -->
			<!-- <el-descriptions v-if="eleInfo.terminalType !== 2" class="margin-top" :column="5" border>
				<el-descriptions-item label="检修" v-if="sosSwitch.maintenanceSosSwitch">
					{{ dioData.terminalData| getStatus("jx") }}</el-descriptions-item>
				<el-descriptions-item label="上限位" v-if="sosSwitch.limitASosSwitch">
					{{dioData.terminalData | getStatus("sx")}}</el-descriptions-item>
				<el-descriptions-item label="下限位" v-if="sosSwitch.lowerLimitPositionSosSwitch">
					{{dioData.terminalData | getStatus("xx")}}</el-descriptions-item>
				<el-descriptions-item label="锁梯" v-if="sosSwitch.lockTheLadderSosSwitch">
					{{ dioData.terminalData| getStatus("st") }}</el-descriptions-item>


        <el-descriptions-item label="安全回路" v-if="sosSwitch.safetyCircuitSosSwitch">
          {{dioData.terminalData | getStatus("aq")}}</el-descriptions-item>
        <el-descriptions-item label="轿门回路" v-if="sosSwitch.doorLookLoopSosSwitch">
          {{dioData.terminalData | getStatus("jm")}}</el-descriptions-item>
        <el-descriptions-item label="抱闸线圈" v-if="sosSwitch.brakeCoilSosSwitch">
          {{dioData.terminalData | getStatus("bz")}}</el-descriptions-item>
        <el-descriptions-item label="厅门回路" v-if="sosSwitch.hallDoorCircuitSosSwitch">
          {{dioData.terminalData | getStatus("tm")}}</el-descriptions-item>

				<el-descriptions-item :label="eleInfo.terminalSosName"
					v-if="sosSwitch.customizeSosSwitch && eleInfo.terminalSosName">
					{{dioData.terminalData | getStatus("yl1")}}</el-descriptions-item> -->
				<!-- 测试专用 -->
				<!-- <el-descriptions-item label="服务模式">
						{{ dioData.terminalData.isOnline ? (
							dioData.terminalData.st  == 0 ? (
							dioData.terminalData.aq == 0 ? (
								dioData.status == 1 ? '救援中' : (
								dioData.status == 2 ? '故障中' : (
									dioData.status == 3 ? '检修中' : (
									dioData.status == 4 ? '维保中' : '运行中'
									)
								)
								)
							) : '故障中'
							)
							:'锁梯')
						: '-'
						}}
					</el-descriptions-item> -->
				<!-- !测试专用 -->
				<!-- <el-descriptions-item label="服务模式">{{ dioData.terminalData.isOnline ?
					dioData.status == 1 ? '救援中' : (
						dioData.status == 2 ? '故障中' : (
							dioData.status == 3 ? '检修中' : (
								dioData.status == 4 ? '维保中' : '运行中'
							)
						)
					)
					: '-' }}
        </el-descriptions-item>

				<el-descriptions-item label="信号强度">
					<el-image v-if="dioData.terminalData && dioData.terminalData.isOnline" fit="contain"
						:src="require(`@/assets/monitor/L${signal}.png`)" style="width:25px;height:25px" />
					<span v-else>-</span>
				</el-descriptions-item>
				<el-descriptions-item label="心跳时间">
					{{ dioData.terminalData && dioData.terminalData.isOnline  ? dioData.terminalData.terminal_time : dioData.terminalTime }}
				</el-descriptions-item>
				<el-descriptions-item label="版本">
					{{ dioData.terminalData && dioData.terminalData.isOnline  ? dioData.terminalData.terminalVersion : dioData.terminalVersion }}
				</el-descriptions-item>
				<el-descriptions-item label="ICCID">
					{{ dioData.terminalData && dioData.terminalData.isOnline  ? dioData.terminalData.terminalIcd : dioData.terminalIcd }}
				</el-descriptions-item>
				<el-descriptions-item label="机房温度">
					{{ dioData.terminalData && dioData.terminalData.isOnline  ? `${dioData.terminalData.temp}℃` : '-' }}
				</el-descriptions-item>
			</el-descriptions> -->
			<!-- c2+ -->
			<!-- <el-descriptions v-if="eleInfo.terminalType === 2" class="margin-top" :column="5" border>

        <el-descriptions-item label="安全回路" v-if="sosSwitch.safetyCircuitSosSwitch">
          {{dioData.terminalData | getStatus("aq")}}</el-descriptions-item>
        <el-descriptions-item label="轿门回路" v-if="sosSwitch.doorLookLoopSosSwitch">
          {{dioData.terminalData | getStatus("jm")}}</el-descriptions-item>
        <el-descriptions-item label="抱闸回路" v-if="sosSwitch.brakeCoilSosSwitch">
          {{dioData.terminalData | getStatus("bz")}}</el-descriptions-item>
        <el-descriptions-item label="厅门回路" v-if="sosSwitch.hallDoorCircuitSosSwitch">
          {{dioData.terminalData | getStatus("tm")}}</el-descriptions-item>
        <el-descriptions-item label="意外移动">{{
					dioData.terminalData.aq ||
						dioData.terminalData.sp ||
						dioData.terminalData.xp
						? dioData.terminalData.aq == 1 &&
							(dioData.terminalData.sp == 1 && dioData.terminalData.xp == 1)
							? "异常"
							: "正常"
						: "未知"
					}}
        </el-descriptions-item>

        <el-descriptions-item label="上限位" v-if="sosSwitch.limitASosSwitch">
          {{dioData.terminalData | getStatus("sx")}}</el-descriptions-item>
        <el-descriptions-item label="下限位" v-if="sosSwitch.lowerLimitPositionSosSwitch">
          {{dioData.terminalData | getStatus("xx")}}</el-descriptions-item>
        <el-descriptions-item label="开门限位" v-if="sosSwitch.openTheDoorLimitSosSwitch">
          {{dioData.terminalData | getStatus("km")}}</el-descriptions-item>
        <el-descriptions-item label="关门限位" v-if="sosSwitch.closeTheDoorLimitSosSwitch">
          {{dioData.terminalData | getStatus("gm")}}</el-descriptions-item>
        <el-descriptions-item label="服务模式">{{ dioData.terminalData ? getmodel(dioData.terminalData) : '-' }}
        </el-descriptions-item>

				<el-descriptions-item label="上平层" v-if="sosSwitch.onTheBedSosSwitch">
					{{dioData.terminalData | getStatus("sp")}}</el-descriptions-item>
				<el-descriptions-item label="下平层" v-if="sosSwitch.underTheBedSosSwitch">
					{{dioData.terminalData | getStatus("xp")}}</el-descriptions-item>
				<el-descriptions-item label="上行减速" v-if="sosSwitch.upsideToSlowDownSosSwitch">
					{{dioData.terminalData | getStatus("sxjs")}}</el-descriptions-item>
				<el-descriptions-item label="下行减速" v-if="sosSwitch.downwardDecelerationSosSwitch">
					{{dioData.terminalData | getStatus("xxjs")}}</el-descriptions-item>
				<el-descriptions-item label="信号强度">
					<el-image v-if="dioData.terminalData && dioData.terminalData.isOnline" fit="contain"
						:src="require(`@/assets/monitor/L${signal}.png`)" style="width:25px;height:25px" />
					<span v-else>-</span>
				</el-descriptions-item> -->

        <el-descriptions-item label="心跳时间">
          {{ dioData.terminalData && dioData.terminalData.isOnline  ? dioData.terminalData.terminal_time : dioData.terminalTime }}
        </el-descriptions-item>
        <el-descriptions-item label="版本">
          {{ dioData.terminalData && dioData.terminalData.isOnline  ? dioData.terminalData.terminalVersion : dioData.terminalVersion }}
        </el-descriptions-item>
        <el-descriptions-item label="ICCID">
          {{ dioData.terminalData && dioData.terminalData.isOnline  ? dioData.terminalData.terminalIcd : dioData.terminalIcd }}
        </el-descriptions-item>
        <el-descriptions-item label="机房温度">
          {{ dioData.terminalData && dioData.terminalData.isOnline  ? `${dioData.terminalData.temp}℃` : '-' }}
        </el-descriptions-item>
      </el-descriptions>
    </div>

    <div class="dtBox4" style="margin-bottom:15px">
      <h3 style="color:#fff">电梯数据</h3>
      <el-descriptions class="margin-top" :column="3" border>
        <el-descriptions-item label="维保单位"> {{dioData | showTitle(dioData.maintainUnitName)}}
        </el-descriptions-item>
        <el-descriptions-item label="使用单位">{{dioData | showTitle(dioData.propertyUnitName)}}
        </el-descriptions-item>
        <el-descriptions-item label="制造单位">{{dioData | showTitle(dioData.manufacturingUnitName)}}
        </el-descriptions-item>
        <el-descriptions-item label="电梯注册代码">{{dioData | showTitle(dioData.code)}}</el-descriptions-item>
        <el-descriptions-item label="电梯品牌">{{dioData | showTitle(dioData.brandName)}}</el-descriptions-item>
        <el-descriptions-item label="物联网设备系统版本">
          <el-tooltip v-if="dioData.caVer" effect="dark"
            :content="`${dioData.caVer ? dioData.caVer :'-'} / ${dioData.appVer ? dioData.appVer :'-'}` "
            placement="top">
            <div class="titlTips">{{dioData | showTitle(dioData.caVer)}} /
              {{dioData | showTitle(dioData.appVer)}}
            </div>
          </el-tooltip>
          <div v-else>{{dioData | showTitle(dioData.caVer)}} / {{dioData | showTitle(dioData.appVer)}}</div>
        </el-descriptions-item>
        <el-descriptions-item label="安装位置">
          <el-tooltip effect="dark" :content="dioData.address ? dioData.address :'-'" placement="top">
            <div class="titlTips">
              {{dioData | showTitle(dioData.address)}}
            </div>
          </el-tooltip>
        </el-descriptions-item>
      </el-descriptions>
      </el-descriptions>
    </div>

    <!-- 图表 -->
    <el-dialog title="流量统计" v-if="visible" :visible.sync="visible" append-to-body :close-on-click-modal="false"
      width="960px" top="8vh" @close="dialogClose">
      <div style="height: 60vh;">
        <div style="display: flex;justify-content: space-between;align-items: center;">
          <el-form :inline="true" ref="searchForm" :model="searchForm" class="demo-form-inline videoSearch">
            <el-form-item>
              <span slot="label">
                <span class="lab-span">统计日期</span>
              </span>
              <el-date-picker v-model="searchForm.startTime" type="month" style="width:400px;margin-right:15px" />
            </el-form-item>
            <el-form-item>
              <el-button type="primary" class="searchBtn" @click="searchList">查看</el-button>
            </el-form-item>
          </el-form>
          <h3 style="margin:0 0 22px">{{year}}年{{month}}月累计流量：{{simNum}}MB</h3>
        </div>
        <el-table ref="dataList" :data="dataList" height="50vh" border stripe fit>
          <el-table-column label="序号" type="index" width="40px" align="center" />
          <el-table-column prop="deviceCode" label="设备号" align="center" />
          <el-table-column prop="sim" label="SIM卡号" align="center" />
          <el-table-column prop="simDataNum" label="当日使用流量(M)" align="center" />
          <el-table-column label="统计时间" align="center">
            <template slot-scope="scope">
              {{ getTime(scope.row.startTime) }}
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div slot="footer" class="dialog-footer" style="text-align: center">
        <Pagination v-if="total > 0" :total="total" :page.sync="formInline.current" :limit.sync="formInline.size"
          @pagination="turnPage" />
      </div>
    </el-dialog>
  </div>
</template>

<script>
  // require('../../../../../public/static/gauge.min.js')
  import acContainer from './acContainer/index.vue' // 电梯监控 eleMonitoring
  import Pagination from '@/components/Pagination'
  import FloorPage from './floorPage.vue'//学习楼层数据


  // let getDeTime
export default {
    props: ['deviceCode', 'elevatorId', 'eleInfo'],
    components: {
      acContainer,
      Pagination,
      FloorPage
    },
	computed: {
		uploadStyle() {
			return (405 / this.lowInfo.length) + 'px';
		},
		urlStyle() {
			return this.imageBg;
		},
	},
	data() {
		return {
			adminInfo: JSON.parse(sessionStorage.getItem('adminInfo')),
			dioData: {
				DeviceData: {
					isOnline: true,
				},
				terminalData: {
					isOnline: true,
				},
			},
			sosSwitch: {
				safetyCircuitSosSwitch: true, // 安全回路
				maintenanceSosSwitch: true, // 检修
				lockTheLadderSosSwitch: true, // 锁梯
				doorLookLoopSosSwitch: true, // 轿门回路
				hallDoorCircuitSosSwitch: true, // 厅门回路
				brakeCoilSosSwitch: true, // 抱闸线圈
				surpriseMoveSosSwitch: true, // 意外移动
				limitASosSwitch: true, // 上限位
				lowerLimitPositionSosSwitch: true, // 下限位
			},
			visible: false,
			// 表格参数
			searchForm: {
				startTime: '',
			},
			dataList: [],
			formInline: {
				current: 1,
				size: 10,
			},
			initTime: [],
			total: 0,

			dMileage: null, // 运行里程
			dFlow: null, // 本月流量
			dDoor: null, // 开关门
			dMode: null, // 模式
			dMove: null, // 移动
			dPeople: null, // 有无人
			dSpeed: null, // 运行速度
			dioDatadMileage: null, // 初始运行里程
			dioDatadFlow: null, // 初始流量
			nowlow: null, // 初始当前楼层
			endlow: 0, // 初始当前楼层
			initLow: 0,
			url: null, //图片
			lowBottom: null, //初始底层
			lowTop: null, // 初始顶层
			lowInfo: null, // 楼层信息
			lowNow: null, // 当前楼层名
			lowIsDatail: false, // 初始总楼层
			imageBg: require('@/assets/round.png'),
			signal: 0, // 盒子信号

			month: '',
			year: '',
			simNum: '',

			shake: 0, // 震动频率

			getDeTime: null,
			timeOutType: null,
			statusTitle: [], // 盒子报警状态
			floorVisible: false,
			showDio: true
		}
	},
	filters: {
		// getStatus(data, target) {
		// 	let str = '-'
		// 	if (data.isOnline) {
		// 		// if (data.terminalType !== 2) {
		// 		// 	switch (target) {
		// 		// 		case 'aq':
		// 		// 		case 'tm':
		// 		// 		case 'jm':
		// 		// 		case 'st':
		// 		// 		case 'jx':
		// 		// 		case 'xx':
		// 		// 		case 'sx':
		// 		// 		case 'yl1':
		// 		// 			str = data[target] ? (data[target] == 0 ? '闭 合' : '动 作') : '-'
		// 		// 			break
		// 		// 		case 'bz':
		// 		// 			str = data[target] ? (data[target] == 0 ? '动 作' : '闭 合') : '-'
		// 		// 			break

		// 		// 		default:
		// 		// 			str = '无'
		// 		// 			break
		// 		// 	}
		// 		// } else {
		// 		// 	switch (target) {
		// 		// 		case 'aq':
		// 		// 		case 'tm':
		// 		// 		case 'jm':
		// 		// 		case 'bz':
		// 		// 			str = data == 0 ? '闭合' : '断开'
		// 		// 			break
		// 		// 		case 'xx':
		// 		// 		case 'sx':
		// 		// 			str = data == 0 ? '正常' : '动作'
		// 		// 			break
		// 		// 		case 'km':
		// 		// 		case 'gm':
		// 		// 		case 'sxjs':
		// 		// 		case 'xxjs':
		// 		// 		case 'sp':
		// 		// 		case 'xp':
		// 		// 			str = data == 0 ? '待机' : '动作'
		// 		// 			break

		// 		// 		default:
		// 		// 			str = '无'
		// 		// 			break
		// 		// 	}
		// 		// }
		// 	}
		// 	return str
		// },

		showTitle(data, target) {
			let url = ''
			if (data) {
			url = '-'
			if (target || target == 0) {
				url = target
			}
			}
			return url
		},

		showType(data, target) {
			let url = '-'
			if (data !== '-') {
				switch (target) {
					case 'SDMB':
						url = data ? `${data}(M)` : 'SD卡已损坏'
						break
					case 'MB':
						url = `${data}(M)`
						break
					case 'm/s':
						url = `${data} m/s`
						break
					case 'm':
						url = `${data} m`
						break
					case 'number':
						url = `${data} 次`
						break
					case 'heads':
						url = data > 0 ? '有人' : '无人'
						break
					case 'liftSpeed':
						url =
							Number(data) > 0 ? '上行' : Number(data) === 0 ? '静止' : '下行'
						break
					case 'charging':
						url = data > 0 ? '电池供电' : data == 0 ? 'DC供电' : '-'
						break
					default:
						url = '无'
						break
				}
			}
			return url
		},
	},
	created() {
		console.log('deviceCode', this.elevatorId)
		this.timeOutType = setTimeout(() => {
			if (this.showDio) {
				this.getFacility()
				this.getDeTime = setInterval(() => {
					console.log('dioData.name', this.dioData.name);
					this.getFacility()
				}, 2000)
			}
		}, 1000)
	},
	mounted() {
		this.searchForm.startTime = new Date(
			new Date(new Date().toLocaleDateString()).getTime()
		)
		this.getNotice('公告类型', ['DEVICE_OPER_STATUS'])
	},
	beforeDestroy() {
		// getDeTime = null
		this.showDio = false
		clearTimeout(this.timeOutType)
		clearInterval(this.getDeTime)
	},
	destroyed() {
		console.log('电梯监控详情销毁')
		clearTimeout(this.timeOutType)
		clearInterval(this.getDeTime)
	},
	methods: {
		checkInfo(){
			this.floorVisible = true
			console.log(this.dioData,'dioData')
		},
		closeInfo(){
			this.floorVisible = false
		},
		getStatus(data, target) {
			let str = '-'
			if (data.isOnline) {
				if (this.statusTitle.find(item => item.value == target)) {
						str = this.statusTitle.find(item => item.value == target).label
				}
			}
			return str
		},
		// 服务模式
		changeType(val){
			let name = '无'
			if(val && val== 0){
			name = '单屏'
			}else if(val && val== 1){
			name = '双屏'
			}
			return name
		},
		getmodel(data) {
			let str = '-'
			console.log(data)
			console.log(this.sos_switch_status)
			if (data.st && data.jx) {
				if (
					this.sos_switch_status.maintenance_sos_switch &&
					!this.sos_switch_status.lock_the_ladder_sos_switch
				) {
					if (data.jx == 1) {
						str = '检修'
					} else {
						str = '运行中'
					}
				}
				if (
					!this.sos_switch_status.maintenance_sos_switch &&
					this.sos_switch_status.lock_the_ladder_sos_switch
				) {
					if (data.st == 1) {
						str = '锁梯'
					} else {
						str = '运行中'
					}
				}
				if (
					this.sos_switch_status.maintenance_sos_switch &&
					this.sos_switch_status.lock_the_ladder_sos_switch
				) {
					if (data.st == 1) {
						if (data.jx == 1) {
							str = '锁梯'
						} else {
							str = '锁梯'
						}
					} else {
						if (data.jx == 1) {
							str = '检修中'
						} else {
							str = '运行中'
						}
					}
				}
			}
			return str
		},

		// 获取数据
		getFacility() {
			if (this.socketApi.isheart) {
				let agentData = {
					act: 'ma_get_ele_detail',
					id: this.elevatorId,
				}
				this.socketApi.sendSock(agentData, (e) => {
					if (e.cmd === 'ma_get_ele_detail' && e.status) {
						if (e.data) {
							e.data=e.data.replace(/:s*([0-9]{15,})s*(,?)/g, ': "$1" $2')
							this.dioData = JSON.parse(e.data)
							this.floorId = this.dioData.floorId
							// this.$set(this, 'dioData', JSON.parse(e.data))
							this.shake = this.dioData.DeviceData && this.dioData.DeviceData.isOnline && this.dioData.DeviceData.liftSpeed && this.dioData.DeviceData
								.liftSpeed != 0 ? Math.random().toFixed(2) : 0
							this.sosSwitch = this.dioData.sosSwitchstatus && this.dioData.sosSwitchstatus !=
								'' ? JSON.parse(this.dioData.sosSwitchstatus) : {}
							// 楼层信息
							this.lowInfo = this.dioData.DeviceData && this.dioData.DeviceData.textFloorHeight !== '' ? JSON.parse(
									this.dioData.DeviceData.textFloorHeight).data.reverse() : []
							// 楼层海拔
							let lowInfoHeightMap = this.dioData.DeviceData && this.dioData.DeviceData.textHeightMap && this.dioData.DeviceData.textHeightMap !== '' ? JSON.parse(
									this.dioData.DeviceData.textHeightMap).data.reverse(): []
							// 楼层别名
							let lowInfoFloorAlias = this.dioData.DeviceData && this.dioData.DeviceData.textFloorAlias && this.dioData.DeviceData.textFloorAlias !== '' ? JSON.parse(
									this.dioData.DeviceData.textFloorAlias).data.reverse() : []
							// 楼层信
							if (this.lowInfo && this.lowInfo.length > 0) {
								this.lowInfo.map((item,index) => {
									item.heightMap = lowInfoHeightMap.length > 0 && lowInfoHeightMap[index] ? lowInfoHeightMap[index].height : ''
									item.name = lowInfoFloorAlias.length > 0 && lowInfoFloorAlias[index] ? lowInfoFloorAlias[index].name : ''
								})
							}
							// 当前楼层
							this.lowNow = this.dioData.DeviceData && this.dioData.DeviceData.floor ? this.dioData.DeviceData.floor : '-'
							if ( this.dioData.DeviceData && this.dioData.DeviceData.isOnline) {
									let lowInfo = this.dioData.DeviceData && this.dioData.DeviceData.textFloorHeight !== '' ? JSON.parse(
										this.dioData.DeviceData.textFloorHeight).data : []
									this.lowBottom = lowInfo.length > 0 ? lowInfo[0].floor : 0
									this.lowTop = lowInfoFloorAlias.length > 0 ? (lowInfoFloorAlias[0].name != '' ? lowInfoFloorAlias[0].name : lowInfoFloorAlias[0].floor) : (lowInfo.length > 0 ? lowInfo[lowInfo.length - 1].floor : 0)
								}
							if (this.dioData.DeviceData) {
								if (
									this.dioDatadMileage !== this.dioData.DeviceData.liftDistance
								) {
									this.dioDatadMileage = this.dioData.DeviceData.liftDistance
								}
							}
							if (this.dioData.terminalData && this.dioData.terminalData.signal) {
								const num = this.dioData.terminalData.signal.split('dbm')
								if (Math.abs(num[0]) < 88) {
									this.signal = 4
								} else if (Math.abs(num[0]) < 98) {
									this.signal = 3
								} else if (Math.abs(num[0]) < 108) {
									this.signal = 2
								} else if (Math.abs(num[0]) < 118) {
									this.signal = 1
								} else {
									this.signal = 0
								}
							}
						}
					}
				})
			}
		},
		// 切换楼层信息
		showDetail(){
			if (this.lowInfo && this.lowInfo.length > 0) {
				this.lowIsDatail = !this.lowIsDatail
			}
		},
		// 转换时间
		getTime(data) {
			if (data) {
				var date = new Date(data)
				var YY = date.getFullYear() + '-'
				var MM =
					(date.getMonth() + 1 < 10 ?
						'0' + (date.getMonth() + 1) :
						date.getMonth() + 1) + '-'
				var DD = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
				var hh =
					(date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':'
				var mm =
					(date.getMinutes() < 10 ?
						'0' + date.getMinutes() :
						date.getMinutes()) + ':'
				var ss =
					date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
				return YY + MM + DD + ' ' + hh + mm + ss
			}
			return '-'
		},
		// 服务模式
		getmodel(data) {
			let str = '-'
			if (data.st && data.jx) {
				if (data.st === 1) {
					str = '锁梯'
				} else {
					if (data.jx === 1) {
						str = '检修中'
					} else {
						str = '运行中'
					}
				}
			}
			return str
		},
		// 打开流量弹框
		query() {
			console.log('查询流量')
			this.visible = true
			this.searchList()
		},
		// 翻页
		turnPage(data) {
			this.formInline.current = data.page
			this.formInline.size = data.limit
			this.searchList()
		},
		// 搜索流量
		searchList() {
			console.log(this.searchForm.startTime, 'this.searchForm.startTime')
			if (!this.searchForm.startTime) {
				this.$message.error('请选择查询日期')
				return
			}
			this.year = new Date(this.searchForm.startTime).getFullYear()
			this.month = new Date(this.searchForm.startTime).getMonth() + 1
			const data = JSON.parse(JSON.stringify(this.formInline))
			data.eleId = this.elevatorId
			data.startTime = this.getTime(this.searchForm.startTime)
			this.$http
				.post(
					`/api/ele/web/simData/getSimDataLogPage?current=${this.formInline.current}&size=${this.formInline.size}`,
					data
				)
				.then((res) => {
					console.log('获取视频列表', res.data)
					if (res.data.success) {
						const {
							data: {
								data
							},
						} = res
						this.simNum = data.numTotal
						this.dataList = data.record.records
						this.total = data.record.total
			this.url = data.record.firstFrameUrl
					} else {
						this.$message.error(res.data.msg)
					}
				})
		},
		// 关闭流量弹框
		dialogClose() {
			console.log('关闭流量弹框')
			this.searchForm.value = new Date(
				new Date(new Date().toLocaleDateString()).getTime() -
				7 * 24 * 3600 * 1000
			)
			this.visible = false
		},
		
		// 获取字典
		getNotice (type, code) {
		this.$http
			.post(`/api/system/web/dict/dictionary?codes=${code}`)
			.then((res) => {
				if (res.data.success) {
					const { data: { data } } = res
					this.statusTitle = data.DEVICE_OPER_STATUS
				} else {
					this.$message.error(res.data.msg)
				}
			})
		}
	},
}
</script>

<style lang="scss" scoped>
  .supervisoryBox {
    width: 55%;
    height: 100%;
    margin-right: 1%;
    // background-color: #9acd32;
  }

  .lift-sequence {
    width: 35%;
    height: 100%;
    display: flex;
    flex-direction: column;
    .floor-check{
      display: flex;
      justify-content: space-between;
      font-weight: bold;
      background-color: brown;
      color: white;
      width: 86%;
      height: 20px;
      padding: 5px 20px;
      margin:0px 0px 10px 14px;
    }
    .lift-sequenceTop {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 94%;
      margin: 0 auto;

      .el-image {
        margin-left: 15px;
      }
    }

    .parameter {
      text-align: center;
      color: #fff;
    }

    .sequenceTitle {
      margin: 9px 0;
    }

    // background-color: yellowgreen;
  }

  .dtBox1 {
    width: 100%;
    height: 465px;
    display: flex;

    .titlTips {
      width: 200px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  // .dtBox2 {
  //   /* width: 100%;
  // 		height: 130px;
  // 		background-color: yellow; */
  // }

  // .dtBox3 {
  //   /* width: 100%;
  // 		height: 260px;
  // 		background-color: red; */
  // }

  // .dtBox4 {
  //   /* width: 100%;
  // 		height: 130px;
  // 		background-color: yellow; */
  // }
  .wid5 {
    width: 7%;
  }

  .wid15 {
    width: 15%;
  }

  .wid30 {
    width: 30%;
  }

  .wid40 {
    width: 40%;
  }

  .lowClose {
    padding: 5px;
    text-align: right;

    button {
      padding: 0;
    }
  }

  .lowInfo {
    display: flex;
    justify-content: end;
    align-items: center;
    margin-left: 15px;
    width: 70px;

    .lowBg {
      width: 20px;
      height: 100%;
      border: 1px solid #fff;
      margin-right: 10px;
      background: #1c5b8f
    }

    .newLowBg {
      background: #e6a23c;
    }
  }

  .lowInfoBox {
    background: #227b97;
    border-radius: 5px;
    font-size: 12px;

    .lowInfo {
      align-items: flex-start;
      margin-left: 0;
      width: 100%;

      .infoBg {
        width: 80px;
        margin-left: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  /deep/.el-descriptions-item__label.is-bordered-label {
    background: #1282a5 !important;
    text-align: center;
    width: 140px;
    font-size: 14px !important;
    text-align: center !important;
  }

  /deep/.el-descriptions-item__content {
    width: 210px;
  }

  /deep/.el-descriptions .is-bordered .el-descriptions-item__cell {
    padding: 12px 6px;
  }
</style>
