<template>
  <div>
    <el-row>
      <el-col :span="13">
        <div class="videoList">
          <el-form
            :inline="true"
            ref="searchVideoForm"
            :model="searchVideoForm"
            class="demo-form-inline videoSearch"
          >
            <el-form-item>
              <span slot="label">
                <span class="lab-span">日期</span>
              </span>
              
              <el-date-picker
                v-model="searchVideoForm.dateTime"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                style="width:300px;margin-right:15px"
              />
            </el-form-item>
            <el-form-item>
              <el-button
                type="primary"
                class="searchBtn"
                @click="searchVideoList"
              >搜索</el-button>
            </el-form-item>
          </el-form>
          <h4 style="margin: 0;color: #04d7c7;">视频列表</h4>
          <ul
            ref="videoList"
            class="videoListBox scrollElement"
          >
            <li
              class="videoListRow"
              v-for="(item, index) in videoList"
              :key="index"
              :class="index === videoIndex ? 'videoActive' : ''"
              @click="changeVideo(item, index)"
            >
              <div>{{item.url.split('?')[1].split('&')[0].split('=')[1]}}</div>
              <!-- <div>{{item.url.split('/')[item.url.split('/').length - 1]}}</div> -->
              <div>
                <el-button
                  size="small"
                  type="text"
                  style="color:#fff"
                  @click="checkVideo(item, index)"
                >查看</el-button>
                <el-button
                  size="small"
                  type="text"
                  style="color:#fff"
                  @click="downloadVideo(item)"
                >下载</el-button>
              </div>
            </li>
          </ul>
        </div>
        <Pagination
          :total="videoListTotal"
          :page.sync="videoPage.current"
          :limit.sync="videoPage.size"
          @pagination="videoRulePage"
        />
      </el-col>
      <el-col :span="11">
        <div
          style="height: 60vh;padding: 0 20px 0 35px;"
          class="scrollElement"
        >
          <video
            :src="videoList.length > 0 ? videoList[videoIndex].url : ''"
            style="width: 100%;height: 85%;"
            controls
            autoplay
          />
          <div class="videoBottom">
            <el-button
              size="small"
              :disabled="videoIndex === 0"
              type="primary"
              @click="previousVideo()"
            >上一条</el-button>
            <el-button
              size="small"
              :disabled="videoList.length === 0 || videoIndex === videoList.length - 1"
              type="primary"
              @click="nextVideo()"
            >下一条</el-button>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import Pagination from '../../../../components/Pagination'
import { formatDate } from "@/util";
let current_time = new Date()
let current_time1 = new Date();
    current_time1.setMonth(current_time1.getMonth() + 1);
let starTime =  new Date(formatDate(current_time.setDate(1), "yyyy-MM-dd") + " 00:00:00");
let endTime = new Date(formatDate(current_time1.setDate(0), "yyyy-MM-dd") + " 00:00:00");
export default {
  components: {
    Pagination,
  },
  data() {
    return {
      // 表格参数
      searchForm: {
        current: 1,
        size: 10,
      },
      tableData: [],
      // 分页参数
      maintenRuleTotal: 0,
      searchVideoForm: {
        dateTime: [starTime, endTime]
      },
      videoPage: {
        current: 1,
        size: 10,
      },
      videoList: [],
      videoListTotal: 0,
      videoUrl: '', // 视频地址
      videoIndex: 0, // 视频下表
    }
  },
  filters: {},

  props: {
    elevatorId: {
      type: String,
      default: '',
    },
  },
  methods: {
    // 分页
    videoRulePage(data) {
      this.videoPage.current = data.page
      this.videoPage.size = data.limit
      this.getVideo()
    },
    // 搜索视频列表
    searchVideoList() {
      this.getVideo()
    },
    // 下载视频
    downloadVideo(item) {
      window.open(item.url, '_blank')
    },
    // 切换视频
    changeVideo(item, index) {
      this.videoIndex = index
    },
    // 查看视频
    checkVideo(item, index) {
      // this.tit = item.url.split('/')[item.url.split('/').length - 1]
    },
    // 上一条
    previousVideo() {
      this.videoIndex--
      this.$refs.videoList.scrollTop -= 47
    },
    // 下一条
    nextVideo() {
      this.videoIndex++
      this.$refs.videoList.scrollTop += 47
    },

    // 获取视频列表
    getVideo() {
      console.log('getVideo');
      // let data = {}
      // const date = this.searchVideoForm.value
      // const year = date.getFullYear()
      // const month =
      //   date.getMonth() > 9 ? date.getMonth() + 1 : `0${date.getMonth() + 1}`
      // const day = date.getDate() > 9 ? date.getDate() : `0${date.getDate()}`
      // data = {
      //   year,
      //   month,
      //   day,
      //   elevatorId: this.elevatorId,
      //   type: 1,
      //   status: 1,
      // }
      const data = {
        elevatorId: this.elevatorId,
        type: 1,
        status: 1
      }
      data.startTime = this.searchVideoForm.dateTime[0].getTime()
      data.endTime = this.searchVideoForm.dateTime[1].getTime() + 24 * 3600 * 1000 - 1000
      if (new Date(this.searchVideoForm.dateTime[0]).getMonth() != new Date(this.searchVideoForm.dateTime[1]).getMonth()) {
        this.$message.error('查询开始时间与结束时间仅限同一个月')
        return
      }
      this.$http
        .post(
          `/api/ele/web/eleLog/getElevatorLog?current=${this.videoPage.current}&size=${this.videoPage.size}`,
          data
        )
        .then((res) => {
          console.log('获取视频列表', res.data)
          if (res.data.success) {
            const {
              data: { data },
            } = res
            this.videoList = [...data.records] ?? []
            this.videoListTotal = data.total ?? 0
          } else {
            this.videoList = []
            this.$message.error(res.data.msg)
          }
        })
    },
  },
  created() {},
  mounted() {
    this.$nextTick(() => {
      this.searchVideoForm.value = new Date()
      // this.getVideo()
    })
  },
}
</script>
<style lang="scss" scoped>
.nullDate {
  width: 100%;
  height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.videoList {
  padding: 20px 10px 20px 15px;
  margin-left: 15px;
  border: 1px solid #ccc;
  border-radius: 10px;
  height: 480px;
  .videoSearch {
    display: flex;
    justify-content: space-between;
  }
  .videoListBox {
    padding: 0;
    height: 80%;
    max-height: 80%;
    overflow-y: scroll;
    .videoListRow {
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 10px;
      padding: 6px 13px;
      border-bottom: 1px solid #fff;
    }
    .videoActive {
      background: #1282a5;
    }
  }
}
.videoBottom {
  margin-top: 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
