<template>
  <div class="tabBox">
    <div v-if="runLogList.length > 0">
      <el-table
        ref="multipleTable"
        :data="runLogList"
        highlight-current-row
        max-height="500px"
      >
        <el-table-column
          label="序号"
          type="index"
          width="60px"
          align="center"
        >
          <template slot-scope="scope">
            <span>{{
              (logQuery.current - 1) *
                logQuery.size +
              scope.$index +
              1
            }}</span>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="url"
          label="文件名称"
          min-width="150px"
        >
          <template slot-scope="scope">
            {{scope.row.url ? scope.row.url.split('/')[scope.row.url.split('/').length - 1] : ''}}
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="createTime"
          label="文件时间"
          min-width="150px"
        />

        <el-table-column
          fixed="right"
          align="center"
          prop="address"
          label="操作"
          width="300px"
        >
          <template slot-scope="scope">
            <el-button
              @click="check(scope.row)"
              type="primary"
              size="small"
            >查看
            </el-button>
            <el-button
              @click="downloadMode(scope.row.url)"
              type="warning"
              size="small"
            >下载
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <Pagination
        :total="runLogtotal"
        :page.sync="logQuery.current"
        :limit.sync="logQuery.size"
        @pagination="getRunLoPage"
      />
    </div>

    <div
      class="nullDate"
      v-else
    >
      <img
        src="@/assets/nullDate.png"
        style="width: 260px;"
      />
    </div>
    <!-- 图表 -->
    <el-dialog
      title="图表"
      :visible.sync="visible"
      append-to-body
      :close-on-click-modal="false"
      width="960px"
      top="8vh"
      @close="infoElevatorDialogClose"
    >
      <div style="height: 60vh;">
        <JsonLineChart
          v-if="visible"
          :sosLineData="txtJson"
        />
      </div>
    </el-dialog>
  </div>
</template>

<script>
import JsonLineChart from './jsonLineChart'
import Pagination from '@/components/Pagination'
import { getTypeOptions } from '../../../../components/useEscape';
export default {
  components: { JsonLineChart, Pagination },

  props: {
    deviceCode: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      visible: false,
      loading: true,
      runLogSearch: {
        name: '',
      },
      runLogList: [],
      logQuery: {
        current: 1,
        size: 10,
      },
      txtJson: {
        x: [],
        y: [],
        z: [],
        aSpeed: [],
        oldV: [],
        upstatus: [],
        outSpeed: [],
        downstatus: [],
        floor: [],
        vNow: [],
        sumaSpeed: [],
        heightChange: [],
        time: [],
      }, // 日志图表数据
    }
  },
  methods: {
    getRunLoPage(data) {
      this.logQuery.current = data.page
      this.logQuery.size = data.limit
      this.getRunLogList()
    },
    getRunLogList() {
      const params = {
        deviceId: this.deviceCode,
      }
      this.loading = true
      this.$http
        .post(
          `/api/ele/web/deviceFile/getDeviceFileLog?current=${this.logQuery.current}&size=${this.logQuery.size}`,
          params
        )
        .then((res) => {
          if (res.data.success) {
            const {
              data: { data },
            } = res
            this.loading = false
            this.runLogList = data.records ?? []
            this.runLogtotal = data.total ?? 0
            this.$nextTick(() => {
              if (this.runLogtotal > 0 && this.$refs.multipleTable) {
                this.$refs.multipleTable.bodyWrapper.scrollTop = 0;
              }
            })
          }
        })
    },
    // 查看
    check(data) {
      this.visible = true
      this.loading = true
      // 初始化
      this.txtJson = this.$options.data().txtJson
      this.$http
        .post('/api/ele/web/deviceFile/getDeviceJsonLog', { id: data.id })
        .then((res) => {
          console.log(res, 'res')
          if (res.data.code === 200) {
            res.data.list.map((item) => {
              for (const key in item) {
                if (this.txtJson.hasOwnProperty(key)) {
                  this.txtJson[key].push(
                    key === 'time'
                      ? this.parseTime(Number(item[key]))
                      : Number(item[key])
                  )
                }
              }
            })
          }
        })
      this.loading = false
    },
    parseTime(time) {
      if (!time) return // 空值不运行
      const date = new Date(time)
      const format = '{y}-{m}-{d} {h}:{i}:{s}'
      const formatObj = {
        y: date.getFullYear(),
        m: date.getMonth() + 1,
        d: date.getDate(),
        h: date.getHours(),
        i: date.getMinutes(),
        s: date.getSeconds(),
      }
      const timeStr = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
        let value = formatObj[key]
        if (result.length > 0 && value < 10) {
          value = '0' + value
        }
        return value || 0
      })
      return timeStr
    },
    // 下载
    downloadMode(url) {
      console.log(url,'url');
      const arr = url.split('.com/')
      const urlArr = url.split('.')
      console.log(arr, 'arr');
      console.log(urlArr[urlArr.length - 1],'urlArr[urlArr.length - 1]');

      this.$http.get(`/api/file/web/oss/downloadFile?bucketName=zx-dev-video&objectName=${arr[1]}`).then((res) => {
        console.log(res, 'res');
        let blob = new Blob([res.data], { type: getTypeOptions(urlArr[urlArr.length - 1])});
        let url = URL.createObjectURL(blob);
        const link = document.createElement('a'); //创建a标签
        link.href = url;
        link.download = `运行日志.${urlArr[urlArr.length - 1]}`; //重命名文件
        link.click();
        URL.revokeObjectURL(url);
        console.log("下载文件" + res);
      })
    },

    getFileName(url) {
      var num = url.lastIndexOf('/') + 1
      var fileName = url.substring(num)
      //把参数和文件名分割开
      fileName = decodeURI(fileName.split('?')[0])
      return fileName
    },
    // downloadUrlFile(url) {
    //   url = url.replace(/\\/g, '/')
    //   const xhr = new XMLHttpRequest()
    //   xhr.open('GET', url, true)
    //   xhr.responseType = 'blob'
    //   //xhr.setRequestHeader('Authorization', 'Basic a2VybWl0Omtlcm1pdA==');
    //   xhr.onload = () => {
    //     if (xhr.status === 200) {
    //       // 获取文件blob数据并保存
    //       var fileName = this.getFileName(url)
    //       this.saveAs(xhr.response, fileName)
    //     }
    //   }

    //   xhr.send()
    // },
    saveAs(data, name) {
      var urlObject = window.URL || window.webkitURL || window
      var export_blob = new Blob([data])
      var save_link = document.createElementNS(
        'http://www.w3.org/1999/xhtml',
        'a'
      )
      save_link.href = urlObject.createObjectURL(export_blob)
      save_link.download = name
      save_link.click()
    },

    infoElevatorDialogClose() {
      this.visible = false
    },

    runLogDialogClose() {
      this.$refs.multipleTable.setCurrentRow()
      this.$refs.multipleTable.clearSelection()
    },
  },
}
</script>
<style lang="scss" scoped>
.tabBox {
  .nullDate {
    width: 100%;
    height: 55vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
