import axios from "axios";
import router from "@/router";
import Base from "@/Base64";
import Cookie from 'js-cookie'
import ElementUI from 'element-ui';
import * as socketApi from '@/util/websocket.js'
// 安全帽
import * as socketCapsApi from '@/util/websocketCaps.js'

// 2.请求拦截
// 添加请求拦截器----给请求头添加token
axios.interceptors.request.use((config) => {
	// 在发送请求之前做些什么
	// 给请求头添加token
	// config.headers["AdminToken"]=Cookie.get("AdminToken")
	// config.headers["AdminToken"]=sessionStorage.getItem('AdminToken')
	return config;
}, (error) => {
	// 对请求错误做些什么
	return Promise.reject(error);
});


// 3.响应拦截
// 添加响应拦截器   ----刷新令牌（无限刷新，无痛刷新）--token
axios.interceptors.response.use(function(response) {
	// 对响应数据做点什么
	// 1.根据返回的状态吗来啊判断是否需要令牌刷新  --10001,10006
	// 令牌刷新
	let iSstate = response.data.code;
	if (iSstate == "401") {
		// 1.是否记住密码
		//   1.1 是---需要令牌刷新
		//      1.1.1 （1.拿到账户和密码登录，重新把登录的token赋值到请求头上）
		//   1.1 否---跳转到登录页面
		// if (localStorage.getItem("userName") != null) {
		// return getToken(response)
		// }else{
		socketApi.stopHeartBeat()
		// 安全帽
		socketCapsApi.stopCapsHeartBeat()
		sessionStorage.clear()
		router.replace("/login")
		// }

	} else if (iSstate == "403") {
		ElementUI.Message({
			message: '管理员权限不足 异常代码：403',
			type: 'error'
		});

	}

	return response;

}, function(error) {
	// 对响应错误做点什么
	return Promise.reject(error);
});
