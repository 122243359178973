<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
let inTimeObj;
let one = true;
export default {
  name: "App",
  created() {
    if (window.location.hostname == "zxtlw.zyzntech.com") {
      this.$store.state.isZx = true;
    }

    // const token1 = window.location.href.split('?')[1].split('=')[1]
    // sessionStorage.setItem('AdminToken', token1)
    // console.log(token, 'state123123123123213')

    let token = sessionStorage.getItem("AdminToken");
    console.log("重连时候的token值：", token);
    if (token != undefined) {
      this.recon(token);
    }
    const that = this;
    // 长连接重连
    let onlineFun = function () {
      console.log("网络恢复了");
      token = sessionStorage.getItem("AdminToken");
      that.recon(token);
    };
    let offlineFun = function () {
      that.socketApi.stopHeartBeat();
      // 安全帽
      that.socketCapsApi.stopCapsHeartBeat();
      sessionStorage.setItem("me_id", "{ismesh:false}");
    };
    window.removeEventListener("online", onlineFun, false);
    window.removeEventListener("offline", offlineFun, false);
    window.addEventListener("online", onlineFun, false);
    window.addEventListener("offline", offlineFun, false);

    clearInterval(inTimeObj);
    // 非网络情况下导致长连接死掉的重连
    inTimeObj = setInterval(() => {
      token = sessionStorage.getItem("AdminToken");
      console.log("非网络情况下 我重连了giaogiao");
      // console.log('token123', token)
      if (
        (sessionStorage.getItem("me_id") == undefined ||
          sessionStorage.getItem("cap_id") == undefined) &&
        token != undefined &&
        this.socketApi.websock.readyState != "1" &&
        this.socketApi.websock.readyState != "0" &&
        one &&
        this.$route.path != "/login"
      ) {
        console.log("非网络情况下 我重连了giaoligiao");
        one = false;
        that.recon(token);
      }
    }, 30000);
  },
  mounted() {
    let obj = sessionStorage.getItem("me_id");
    let loadFlashTimer = setInterval(() => {
      obj = sessionStorage.getItem("me_id");
      if (this.$route.path != "/login" && this.$route.path != "/loginWait") {
        if (
          !this.$store.state.userAgentStatus &&
          obj != null &&
          obj != undefined
        ) {
          clearInterval(loadFlashTimer);
          let objInfo = JSON.parse(obj).sip_info;
          console.log("app create ua begin:", objInfo);
          let localPhone = objInfo.sip_id;
          let me_uri_joint = localPhone + "@" + objInfo.sip_host;
          let ws_uri = objInfo.wss_url;
          let pwd = objInfo.sip_pwd;
          // 进行号码登录
          // 配置参数
          let config = {
            uri: me_uri_joint,
            transportOptions: {
              wsServers: [ws_uri],
              connectionTimeout: 30,
            },
            authorizationUser: localPhone,
            password: pwd,
            sessionDescriptionHandlerFactoryOptions: {
              peerConnectionOptions: {
                rtcConfiguration: {
                  iceServers: [
                    {
                      urls: objInfo.stun_host,
                    },
                    {
                      urls: objInfo.turn_host,
                      username: objInfo.turn_user,
                      credential: objInfo.turn_pwd,
                    },
                  ],
                },
              },
            },
          };
          //创建user agent
          this.$store.state.userAgent = new SIP.UA(config);
          //注册成功
          this.$store.state.userAgent.on("registered", () => {
            console.log("registered ok");
            this.$store.state.userAgentStatus = true;
          });
          console.log("this.$store", this.$store.state.userAgent);
          //注册失败
          this.$store.state.userAgent.on(
            "registrationFailed",
            (response, cause) => {
              console.log("registrationFailed, ", response, cause);
              // this.$message({
              //   type: 'error',
              //   message: this.$t('common.registerFail'),
              // })
              this.$store.state.userAgentStatus = false;
            }
          );
        }
      }
    }, 1000);
  },
  methods: {
    recon(token) {
      console.log("token", token);
      if (!this.socketApi.isheart) {
        if (token == "" || token == undefined) {
          clearInterval(sosTime);
          this.socketApi.stopHeartBeat();
          this.$message({
            type: "error",
            message: "token检测错误!",
          });
          sessionStorage.clear();
          this.$router.push("/login");
          this.axios.post("/api/system/web/user/loginOut").then((res) => {});
        }

        let agentData = {
          act: "ma_login",
          token: token,
        };
        this.socketApi.initWebSocket();
        let ontime = setInterval(() => {
          if (this.socketApi.websock.readyState != "0") {
            if (
              !this.socketApi.isheart &&
              this.socketApi.websock.readyState != "1"
            ) {
              this.socketApi.initWebSocket();
              return;
            }
            clearInterval(ontime);
            this.socketApi.sendSock(agentData, (e) => {
              if (e.cmd == "ma_login") {
                one = true;
                sessionStorage.setItem("me_id", e.data);
              }
              console.log("sessionStorage ", sessionStorage.getItem("me_id"));
            });
          }
        }, 1000);
      }
      // 安全帽
      if (!this.socketCapsApi.isheart) {
        if (token == "" || token == undefined) {
          clearInterval(sosTime);
          this.socketCapsApi.stopHeartBeat();
          this.$message({
            type: "error",
            message: "token检测错误!",
          });
          sessionStorage.clear();
          // this.$router.push('/login')
          // this.axios.post('/api/system/web/user/loginOut').then((res) => {})
        }
        let agentData = {
          act: "ma_login",
          token: token,
        };
        this.socketCapsApi.initCapsSocket();
        let ontime_cpas = setInterval(() => {
          console.log(
            2233,
            "我是安全帽",
            this.socketCapsApi.websock.readyState,
            this.socketCapsApi.websock.readyState
          );
          if (this.socketCapsApi.websock.readyState != "0") {
            if (
              !this.socketCapsApi.isheart &&
              this.socketCapsApi.websock.readyState != "1"
            ) {
              this.socketCapsApi.initCapsSocket();
              return;
            }
            clearInterval(ontime_cpas);
            this.socketCapsApi.sendCapsSock(agentData, (e) => {
              if (e.cmd == "ma_login") {
                one = true;
                sessionStorage.setItem("cap_id", e.data);
              }
            });
          }
        }, 1000);
      }
    },
  },
};
</script>

<style lang="less">
.el-tabs__item {
  color: white !important;
}

.el-timeline-item__content {
  color: #00f6ff !important;
  text-align: left;
}

.el-card__body {
  height: 100%;
  color: white !important;
  background-color: #1282a5 !important;
  padding: 10px !important;
  text-align: left !important;
}

.el-timeline-item__timestamp {
  color: wheat !important;
  text-align: left !important;
}

.el-descriptions-item__label.is-bordered-label {
  font-size: 16px !important;
  font-weight: bold;
}

.el-descriptions__body,
.el-descriptions-item__label.is-bordered-label {
  color: white !important;
  background-color: transparent !important;
}

.el-textarea__inner {
  color: white !important;
  background-color: #1282a5 !important;
}

.el-date-table td.in-range div,
.el-date-table td.in-range div:hover,
.el-date-table.is-week-mode .el-date-table__row.current div,
.el-date-table.is-week-mode .el-date-table__row:hover div {
  background-color: #336ea5 !important;
}

.el-picker-panel__footer,
.el-picker-panel,
.el-date-table th {
  color: white !important;
  background: #1282a5 !important;
}

.el-button--text {
  color: #00f6ff !important;
}

.el-dialog__footer {
  z-index: 99;
  position: inherit;
}

.einp {
  width: 90% !important;
}

.el-range-separator {
  color: white !important;
}

.el-range-editor .el-range-input {
  color: white !important;
  background: transparent !important;
  width: 43% !important;
}

//滚动条的宽度
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: #e4e4e4;
}

//滚动条的滑块
::-webkit-scrollbar-thumb {
  background-color: #00f6ff;
  border-radius: 6px;
}

.el-tree,
.el-tree-node__content:hover,
.el-upload-list__item:hover,
.el-tree-node:focus > .el-tree-node__content {
  color: white !important;
  background: transparent !important;
}

.el-checkbox__input.is-disabled.is-checked .el-checkbox__inner {
  background-color: #409eff !important;
}

.el-tree--highlight-current .el-tree-node.is-current > .el-tree-node__content {
  background: #02608e !important;
}

.el-form-item__label {
  color: #00f6ff !important;
}

.el-dialog__body {
  padding: 0 !important;
  margin: 0 5% 20px 4%;
  color: #00f6ff !important;
}

.el-dialog__footer {
  padding: 0 10% 6% !important;
}

.el-dialog__header {
  display: flex;
  align-items: center;
  justify-content: center;
}

.el-dialog__header span {
  color: #00f6ff !important;
}

.el-dialog__headerbtn .el-dialog__close {
  color: #04e3ef !important;
}

.el-dialog__headerbtn {
  font-size: 25px !important;
  top: 2% !important;
  right: 5% !important;
}

.el-dialog {
  box-shadow: initial !important;
  background: transparent !important;
  background-size: 100% 100% !important;
  background-image: url("./assets/diobg.png") !important;
}

.lab-span {
  color: #04e3ef;
}

.el-input__inner {
  color: white !important;
  border: 1px solid #04e3ef !important;
  background-color: #1282a5 !important;
}

.el-table,
.el-table__expanded-cell {
  background-color: transparent !important;
}

.el-table {
  opacity: 1;
}

/* 表格内背景颜色 */
.el-table th,
.el-table tr,
.el-table td {
  color: white;
  background-color: #1e2d4f !important;
}

.el-table th {
  color: white;
  background-color: #1282a5 !important;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  color: #2c3e50;
}

body {
  margin: 0;
  padding: 0;
  background-color: #1f2f52;
  background-image: url("./assets/bbg.png");
  background-size: 100% 100%;
}

.scrollElement::-webkit-scrollbar,
body::-webkit-scrollbar {
  width: 4px;
  height: 6px;
  background-color: transparent;
}

.scrollElement::-webkit-scrollbar-track,
body::-webkit-scrollbar-track {
  border-radius: 5px;
  background-color: transparent;
  -webkit-box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.3);
}

.scrollElement::-webkit-scrollbar-thumb,
body::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #06eef7;
  -webkit-box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.3);
}

// 搜索日历
.el-picker-panel__icon-btn,
.el-date-picker__header-label,
.el-year-table td .cell,
.el-month-table td .cell {
  color: #fff !important;
}

.el-year-table td .cell:hover,
.el-year-table td.current:not(.disabled) .cell {
  color: #409eff !important;
}

.amap-sug-result {
  z-index: 5000 !important;
}
</style>
<style>
/* 表格内背景颜色 */
.el-table th,
.el-table tr,
.el-table td {
  color: white;
  background-color: #1e2d4f66 !important;
}

/* 表格内背景颜色 */
.el-table th,
.el-table tr,
.el-table td {
  color: white;
  background-color: #1e2d4f66 !important;
}

.el-table th {
  color: white;
  background-color: #1282a5e6 !important;
}

.el-table__fixed-right {
  background: #1e2d4f !important;
}

.el-table__fixed {
  background: #1e2d4f !important;
}

.hover-row td {
  background-color: #1282a563 !important;
}

.el-month-table .disabled .cell {
  color: #c0c4cc !important;
}
</style>
