<template>
  <el-dialog
    :title="title"
    :visible.sync="visible"
    append-to-body
    width="600px"
    top="20vh"
    center
    :close-on-press-escape="false"
    :close-on-click-modal="false"
  >
    <div
      class="searchBox scrollElement"
      style="height: 15vh; margin-top: 20px"
    >
      <el-form
        ref="forgetPwdForm"
        :model="forgetPwdForm"
        :rules="forgetPwdFormRules"
        label-width="180px"
        label-position="right"
      >
        <el-row>
          <el-col :span="24">
            <el-form-item
              label="输入手机号码:"
              prop="phone"
            >
              <el-input
                class="wid75"
                disabled
                v-model="showPhone"
                placeholder="请输入"
              />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item
              label="输入短信验证码:"
              prop="phoneCode"
            >
              <el-input
                class="wid75"
                v-model="forgetPwdForm.phoneCode"
                placeholder="请输入"
              >
                <!-- <el-button
                  slot="append"
                  type="primary"
                  :disabled="timeDisabled"
                  @click="getCode()"
                >{{
                    timeDisabled ? `重新获取${time}s` : '获取验证码'
                  }}</el-button> -->
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div
      slot="footer"
      class="dialog-footer"
      style="text-align: center"
    >
      <el-button v-show="!loading" type="primary" @click="updatePwd" >登 录 </el-button>
      <el-button v-show="loading" style="font-size: 18px;" type="primary" >登 录 中...</el-button>
    </div>
    <!-- </div> -->
  </el-dialog>
</template>

<script>
import base from '../../Base64.js'
export default {
  data() {
    var validatePCode = (rule, value, callback) => {
      if (!value) {
        callback(new Error('手机验证码不能为空'))
      }
      callback()
    }
    return {
      loading: true,
      visible: false,
      title: '验证手机', // 弹窗标题
      time: 90,
      timeDisabled: false,
      forgetPwdForm: {}, // 表单信息
      forgetPwdFormRules: {
        // 表单规则
        phoneCode: [{ validator: validatePCode, trigger: 'blur' }]
      },
      addBrandCallback: null, // 弹窗回调
      loginInfo: {},
      phone: '',
      showPhone: ''
    }
  },

  methods: {
    // 新增/修改，父字典id，父字典信息，字典信息，回调
    init(data, phone, callback) {
      this.phone = phone
      this.showPhone = this.startNumber(phone, 3, 7)
      this.loginInfo = data
      this.addBrandCallback = callback
      this.visible = true
      this.loading = false
      this.$nextTick(() => {
        this.forgetPwdForm = {
          // 字典表单信息
          // phone: '',
          phoneCode: '',
        }
      })
    },
    /*
    * value传入的参数
    * start 替换的开始位置
    * end 结束位置
    */ 
    startNumber(value, start, end) {
      return value.split('').fill('*', start, end).join('')
    },

    updatePwd() {
      this.$refs.forgetPwdForm.validate(async (valid) => {
        if (valid) {
          const data = {...this.forgetPwdForm, ...this.loginInfo}
          data.phone = this.phone
          this.loading = true
          this.axios.post('/api/system/web/user/doLogin', data).then((res) => {
						if (res.data.success) {
							let agentData = {
								act: 'ma_login',
								token: res.data.data.token.token,
							}
							this.socketApi.initWebSocket()
							this.socketApi.sendSock(agentData, (e) => {
								if (e.cmd == 'ma_login' && e.status) {
									console.log('ma_login', e)
									//保存密码并加密
									if (this.isRemember) {
										localStorage.setItem('userName', base.encode(this.userName))
										localStorage.setItem('password', base.encode(this.password))
									} else {
										localStorage.clear()
									}
									sessionStorage.setItem(
										'adminInfo',
										JSON.stringify(res.data.data)
									)
									sessionStorage.setItem('AdminToken', res.data.data.token.token)
									sessionStorage.setItem('me_id', e.data)
									sessionStorage.setItem('sosConfig', JSON.stringify(res.data.data
										.sosConfig))
									this.$router.push('/dashboard')
									this.$message.success('登录成功')
								} else {
									this.isLogin = false
									this.$message.error(e.msg)
								}
							})
							// 安全帽
							this.socketCapsApi.initCapsSocket()
							this.socketCapsApi.sendCapsSock(agentData, (e) => {
								if (e.cmd == 'ma_login' && e.status) {
									console.log('e', e)
								} else {
									this.isLogin = false
									this.$message.error(e.msg)
								}
							})
              setTimeout(() => {
                this.loading = false
              }, 2000)
						} else {
							// this.isLogin = false
							this.$message.error(res.data.msg)
              setTimeout(() => {
                this.loading = false
              }, 1500)
						}
					})
        }
      })
    },

    closeDialog() {
      console.log('closeDialog')
      this.$refs.forgetPwdForm.resetFields()
      this.visible = false
    }
  }
}
</script>
<style lang="scss" scoped>
.wid75 {
  width: 75%;
}
.wid54 {
  width: 54%;
}
.wid25 {
  width: 25%;
}
.inputFL {
  float: left;
}
</style>
