<template>
  <el-dialog
    :title="title"
    :visible.sync="visible"
    :close-on-click-modal="false"
    append-to-body
    width="950px"
    top="20vh"
    center
    @close="closeDialog"
  >
    <div
      class="searchBox scrollElement"
      style="height: 40vh"
    >
      <el-form
        ref="forgetPwdForm"
        :model="forgetPwdForm"
        :rules="forgetPwdFormRules"
        label-width="200px"
        label-position="right"
      >
        <el-row>
          <el-col :span="24">
            <el-form-item
              label="输入用户名:"
              prop="userName"
            >
              <el-input
                class="wid75"
                v-model="forgetPwdForm.userName"
                placeholder="请输入"
              />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item
              label="输入手机号码:"
              prop="phone"
            >
              <el-input
                class="wid75"
                v-model="forgetPwdForm.phone"
                placeholder="请输入"
              />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item
              label="输入短信验证码:"
              prop="phoneCode"
            >
              <el-input
                class="wid75"
                v-model="forgetPwdForm.phoneCode"
                placeholder="请输入"
              >
                <el-button
                  slot="append"
                  type="primary"
                  :disabled="timeDisabled"
                  @click="getCode()"
                >{{
                    timeDisabled ? `重新获取${time}s` : '获取验证码'
                  }}</el-button>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item
              label="输入新密码:"
              prop="password"
            >
              <el-input
                class="wid75"
                show-password
                v-model="forgetPwdForm.password"
                placeholder="请输入"
              />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item
              label="再次输入新密码:"
              prop="newPassword"
            >
              <el-input
                class="wid75"
                show-password
                v-model="forgetPwdForm.newPassword"
                placeholder="请输入"
              />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item
              label="图形验证码:"
              prop="verificationCode"
            >
              <!-- <el-input class="wid75" v-model="forgetPwdForm.verificationCode" placeholder="请输入" /> -->
              <el-input
                v-model="forgetPwdForm.verificationCode"
                type="text"
                class="wid54 inputFL"
                placeholder="请输入"
                required
                size="medium"
                style="margin-right: 2%"
              />
              <div
                id="authLoginDivCaptcha"
                class="wid25 inputFL"
                style="height: 36px; margin-top: 2px"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div
      slot="footer"
      class="dialog-footer"
      style="text-align: center"
    >
      <el-button
        type="primary"
        @click="updatePwd"
      >确定</el-button>
      <el-button
        type="indo"
        @click="closeDialog"
      >取消</el-button>
    </div>
    <!-- </div> -->
  </el-dialog>
</template>

<script>
require('../../../public/static/gVerify.js')
export default {
  data() {
    var validateName = (rule, value, callback) => {
      if (!value) {
        callback(new Error('用户名不能为空'))
      }
      callback()
    }
    var validatePhone = (rule, value, callback) => {
      inpPhone(rule, value, callback)
    }
    var validatePwd = (rule, value, callback) => {
      inpPwd(rule, value, callback)
    }
    var validatePCode = (rule, value, callback) => {
      if (!value) {
        callback(new Error('手机验证码不能为空'))
      }
      callback()
    }
    var validatePwdNew = (rule, value, callback) => {
      if (!value) {
        callback(new Error('新密码不能为空'))
      } else {
        if (value !== this.forgetPwdForm.password) {
          callback(new Error('再次输入的密码与新密码不同'))
        }
      }
      callback()
    }
    var validatePicCode = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请输入验证码'))
      }
      if (
        !this.authLoginCaptchaObj.validate(this.forgetPwdForm.verificationCode)
      ) {
        callback(new Error('验证码错误'))
      }
      callback()
    }
    return {
      authLoginCaptchaObj: null,
      loading: true,
      visible: false,
      title: '忘记密码', // 弹窗标题
      time: 90,
      timeDisabled: false,
      parentDic: '', // 上级字典
      forgetPwdForm: {}, // 表单信息
      forgetPwdFormRules: {
        // 表单规则
        userName: [{ validator: validateName, trigger: 'blur' }],
        phone: [{ validator: validatePhone, trigger: 'blur' }],
        phoneCode: [{ validator: validatePCode, trigger: 'blur' }],
        password: [{ validator: validatePwd, trigger: 'blur' }],
        newPassword: [{ validator: validatePwdNew, trigger: 'blur' }],
        verificationCode: [{ validator: validatePicCode, trigger: 'blur' }],
      },
      addBrandCallback: null, // 弹窗回调
    }
  },

  created() {
    // this.authLoginGetCaptcha()
  },

  methods: {
    // 新增/修改，父字典id，父字典信息，字典信息，回调
    init(callback) {
      this.addBrandCallback = callback
      this.visible = true
      this.loading = false
      this.$nextTick(() => {
        this.forgetPwdForm = {
          // 字典表单信息
          phone: '',
          phoneCode: '',
          password: '',
          newPassword: '',
          verificationCode: '',
        }
        this.authLoginGetCaptcha()
      })
    },
    // 图形验证码
    authLoginGetCaptcha() {
      this.authLoginCaptchaObj = new GVerify({
        id: 'authLoginDivCaptcha',
        type: 'number',
      })
    },
    // 获取短信验证码
    getCode() {
      if (!this.forgetPwdForm.userName) {
        this.$message.error('请输入用户名！')
        return
      }
      if (!this.forgetPwdForm.phone) {
        this.$message.error('请输入手机号！')
        return
      }
      if (!/^1[3456789]\d{6,9}$/.test(this.forgetPwdForm.phone)) {
        this.$message.error('请输入正确的手机号！')
        return
      }
      if (!/^1\d{7,10}$/.test(this.forgetPwdForm.phone)) {
        this.$message.error('请输入8-11个数字的手机号！')
        return
      }
      let interval = null
      const data = {
        userName: this.forgetPwdForm.userName,
        phone: this.forgetPwdForm.phone,
      }
      this.$http
        .post('/api/system/web/user/sendPhoneCode', data)
        .then((res) => {
          if (res.data.code === 200) {
            clearInterval(interval)
            this.timeDisabled = true
            interval = setInterval(() => {
              this.time--
              if (this.time === 0) {
                this.timeDisabled = false
                this.time = 90
                clearInterval(interval)
              }
            }, 1000)
            console.log('获取短信验证码')
            this.$message({
              message: '短信已发送，请输入验证码！',
              type: 'sussess',
            })
          } else {
            clearInterval(interval)
            this.timeDisabled = false
            this.$message({
              message: res.data.msg,
              type: 'error',
            })
          }
        })
    },

    updatePwd() {
      console.log(this.forgetPwdForm, 'updatePwd')
      this.$refs.forgetPwdForm.validate(async (valid) => {
        if (valid) {
          const data = this.forgetPwdForm
          console.log(data, 'data')
          this.$http
            .post('/api/system/web/user/updatePassword', data)
            .then((res) => {
              if (res.data.code === 200) {
                this.$message({
                  message: '修改成功！',
                  type: 'sussess',
                })
                this.$refs.forgetPwdForm.resetFields()
                this.visible = false
              } else {
                this.$message({
                  message: res.data.msg,
                  type: 'error',
                })
              }
            })
        }
      })
    },

    closeDialog() {
      console.log('closeDialog')
      this.$refs.forgetPwdForm.resetFields()
      this.visible = false
    },
  },
}
</script>
<style lang="scss" scoped>
.wid75 {
  width: 75%;
}
.wid54 {
  width: 54%;
}
.wid25 {
  width: 25%;
}
.inputFL {
  float: left;
}
</style>
